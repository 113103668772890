import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CircularProgress,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import _ from "lodash";
import { SimInformation } from "../components/SimInformation";

import axios from "axios";

import {
  Button,
  Container,
  IconButton,
  LightColors,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteIcon from "@material-ui/icons/Delete";
import { useTranslation } from "react-i18next";
import { Simcard } from "../types";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import * as yup from "yup";
import { SimDeleteModal } from "../components/modals/SimDeleteModal";
import moment from "moment";
import { DataUsage } from "../components/DataUsage";
import { USER } from "../features/User/slice";
import { RootState } from "../features/store";
import { SERVER_URI } from "../contants/Server";
import { generateAuthToken } from "../utils/Auth";
import { Notifications } from "../components/Notifications";
import { DailyUsage } from "../components/DailyUsage";
import { MonthlyUsageGraph } from "../components/MonthlyUsageGraph";
import { UpgradeSelectModal } from "../components/modals/UpgradeSelectModal";
import {
  checkCanceled,
  checkPaused,
  checkSubscribed,
  checkTerminated,
} from "../utils/Subscription";
import { ResubscribePlanModal } from "../components/modals/ResubscribePlanModal";
import ServiceEndBillingModal from "../components/modals/ServiceEndBillingModal";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(2),
    minHeight: "calc(100vh - 56px - 90px)",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5, 3, 2),
    },
    [theme.breakpoints.up("sm")]: {
      minHeight: "calc(100vh - 56px - 76px)",
    },
  },
  iccidDiv: {
    wordBreak: "break-all",
  },
  headerDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: 26,
  },
  headerLeftDiv: {
    display: "flex",
    alignItems: "flex-start",
  },
  deleteBtn: {
    padding: theme.spacing(0.5, 0),
    minWidth: 24,
    minHeight: 32,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  backIconBtn: {
    margin: theme.spacing(0.75, 1, 0.75, 0),
  },

  //rename modal css
  lengthText: {
    width: "90%",
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 2,
    [theme.breakpoints.up("sm")]: {
      width: "94%",
    },
  },
  renameContent: {
    padding: theme.spacing(1, 3, 3.125),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1, 3, 2.875),
    },
  },
  renameTitle: {
    padding: "11px 13px 10px 24px",
    [theme.breakpoints.up("sm")]: {},
  },
  loadingDiv: {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "#13131C73",
    zIndex: 999,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  flexRowDiv: {
    display: "flex",
    marginBottom: 16,
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  flexRowDivLast: {
    display: "flex",
    marginBottom: 6,
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
}));

interface ISimDetailScreenProps {
  error?: string;
  openMenu?: boolean;
  // onDeleteMember?: (member: IMembersInfo) => void;
}

const schema = yup.object().shape({
  simName: yup
    .string()
    // mantis - 12867, 공백포함 30글자로 조건 수정 (Leehj)
    // .trim()
    .required("Enter ICCID")
    .min(1, "The name must_")
    .max(30, "The name must_")
    .test("unallowed-check", "Unallowed character detected", (val) => {
      return (
        (val?.indexOf('"') ?? -1) === -1 && (val?.indexOf("\\") ?? -1) === -1
      );
    }),
});

export const SimDetailScreen = (props: ISimDetailScreenProps) => {
  const classes = useStyles(props);
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const history = useHistory();

  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openResubscribeModal, setOpenResubscribeModal] = useState(false);
  const [openResubscribePlanModal, setOpenResubscribePlanModal] =
    useState(false);
  const [openServiceEndBillingModal, setOpenServiceEndBillingModal] =
    useState(false);
  const [selectPlan, setSelectPlan] = useState<string>();

  const [simName, setSimName] = useState("");
  const [renameError, setRenameError] = useState<string>();
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [currentSim, setCurrentSim] = useState<Simcard>();

  const { email, user_token, token_type } = useSelector(
    (state: RootState) => state[USER]
  );

  const { id } = useParams<{ id: string }>();

  const getSimcard = useCallback(async () => {
    if (!(email && user_token && token_type && id)) return;
    try {
      const resp = await axios.get(
        `${SERVER_URI}/simcards/${id}?include=subscription,usages,payment_method,notifications,subscription_histories,trigger_usage`,
        {
          headers: {
            Authorization: generateAuthToken(email, user_token),
            "X-Token-Type": token_type,
          },
        }
      );
      setCurrentSim(resp.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [email, id, token_type, user_token]);

  useEffect(() => {
    getSimcard();
  }, [getSimcard]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paused = useMemo(() => {
    return !!currentSim && checkPaused(currentSim);
  }, [currentSim]);

  const terminated = useMemo(() => {
    return !!currentSim && checkTerminated(currentSim);
  }, [currentSim]);

  const subscribed = useMemo(() => {
    return !!currentSim && checkSubscribed(currentSim);
  }, [currentSim]);

  const cancelled = useMemo(() => {
    return !!currentSim && checkCanceled(currentSim);
  }, [currentSim]);

  const handleBack = useCallback(() => {
    if (history.action === "POP") {
      history.replace("/");
    } else {
      history.goBack();
    }
  }, [history]);

  const handleIccidNameChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSimName(value);
      let error: { [key: string]: string } = {};
      try {
        await schema.validate({ simName: value }, { abortEarly: false });
      } catch (err: any) {
        error = _.reduce(
          err.inner,
          (r, i) => ({ ...r, [i.path]: i.message }),
          {} as { [key: string]: string }
        );
      } finally {
        setRenameError(error["simName"]);
      }
    },
    []
  );

  const handleDeleteSim = useCallback(async () => {
    try {
      if (!email || !user_token || !token_type) return;
      setLoadingDelete(true);
      await axios.delete(`${SERVER_URI}/simcards/${id}`, {
        headers: {
          Authorization: generateAuthToken(email, user_token),
          "X-Token-Type": token_type,
        },
      });
      history.goBack();
    } catch (err) {
    } finally {
      setLoadingDelete(false);
    }
  }, [email, history, id, token_type, user_token]);

  // rename simName
  const handleRename = async (sim: Simcard) => {
    setModalLoading(true);
    if (currentSim) {
      let error: { [key: string]: string } = {};
      try {
        await schema.validate({ simName: simName }, { abortEarly: false });
        setRenameError(undefined);
        if (!email || !user_token || !token_type) return;
        await axios.put(
          `${SERVER_URI}/simcards/${sim.id}`,
          { name: simName },
          {
            headers: {
              Authorization: generateAuthToken(email, user_token),
              "X-Token-Type": token_type,
            },
          }
        );

        setOpenRenameModal(false);
        getSimcard();
      } catch (err: any) {
        error = _.reduce(
          err.inner,
          (r, i) => ({ ...r, [i.path]: i.message }),
          {} as { [key: string]: string }
        );
      } finally {
        setRenameError(error["simName"]);
        setModalLoading(false);
      }
    }
  };

  const dataUsageMarkup = useMemo(() => {
    return (
      <DataUsage
        terminated={terminated}
        //구독만료된 취소상태
        paused={paused}
        cancelled={cancelled && !subscribed}
        planName={currentSim?.subscription?.planName}
        dataUsage={currentSim?.trigger_usage?.usage ?? 0}
        estimatedDate={moment(currentSim?.trigger_usage?.createdAt)}
      />
    );
  }, [
    cancelled,
    currentSim?.subscription?.planName,
    currentSim?.trigger_usage?.createdAt,
    currentSim?.trigger_usage?.usage,
    paused,
    subscribed,
    terminated,
  ]);

  const monthlyGraphMarkup = useMemo(() => {
    const now = moment.utc();
    const currentPlan = currentSim?.subscription?.planName
      ? [
          {
            month: now,
            value: _.chain(currentSim.usages)
              .filter((usage) => {
                const date = moment.utc(usage.date);
                return (
                  moment(now).startOf("M").isSameOrBefore(date) &&
                  moment(now).endOf("M").isSameOrAfter(date)
                );
              })
              .sumBy((usage) => (usage.download + usage.upload) / 1024)
              .value(),
            planName: currentSim?.subscription?.planName ?? "1GB",
          },
        ]
      : [];
    return (
      <MonthlyUsageGraph
        currentSim={currentSim}
        onUpdated={() => getSimcard()}
        monthlyData={[
          ...currentPlan,
          ..._.chain(currentSim?.subscription_histories)
            .filter((h) => {
              const b4 = moment.utc().subtract(4, "month");
              return moment.utc(h.date).isSameOrAfter(b4, "month");
            })
            .groupBy("date")
            .map((values) => {
              return _.chain(values).sortBy("createdAt").last().value();
            })
            .map((history) => {
              const month = moment.utc(history.date);
              return {
                month: month,
                value: _.chain(currentSim?.usages)
                  .filter((usage) => {
                    const date = moment.utc(usage.date);
                    return (
                      moment(month).startOf("M").isSameOrBefore(date) &&
                      moment(month).endOf("M").isSameOrAfter(date)
                    );
                  })
                  .sumBy((usage) => (usage.download + usage.upload) / 1024)
                  .value(),
                planName: history.planName,
              };
            })
            .value(),
        ]}
      />
    );
  }, [currentSim, getSimcard]);

  return (
    <Container style={{ flex: 1 }}>
      {loading && (
        <div className={classes.loadingDiv}>
          <CircularProgress size={64} />
        </div>
      )}
      <div className={classes.root}>
        <div className={classes.headerDiv}>
          <div className={classes.headerLeftDiv}>
            <IconButton onClick={handleBack} className={classes.backIconBtn}>
              <ArrowBackIosIcon />
            </IconButton>
            <div className={classes.iccidDiv}>
              <Typography category="Default" variant="H1">
                {currentSim?.iccid}
              </Typography>
            </div>
          </div>
          <div>
            <Button
              variant="text"
              startIcon={<DeleteIcon />}
              className={classes.deleteBtn}
              onClick={() => setOpenDeleteModal(true)}
            >
              {mobile ? "" : t("Delete SIM")}
            </Button>
          </div>
        </div>
        <SimInformation
          currentSim={currentSim}
          onRenameICCID={() => {
            if (currentSim) {
              setSimName(currentSim.name ?? "");
              setOpenRenameModal(true);
            }
          }}
          onResubscribe={() => {
            if (!subscribed) {
              setOpenResubscribePlanModal(true);
            } else {
              setOpenResubscribeModal(true);
            }
          }}
          onUpdated={() => getSimcard()}
        />
        <div className={classes.flexRowDiv}>
          {dataUsageMarkup}
          <div style={{ width: 24, height: 16 }} />
          <Notifications notifications={currentSim?.notifications} />
        </div>
        <div className={classes.flexRowDivLast}>
          <DailyUsage
            plan={currentSim?.subscription?.planName}
            usages={currentSim?.usages}
            terminated={terminated}
          />
          <div style={{ width: 24, height: 16 }} />
          {monthlyGraphMarkup}
        </div>
        {/* 심이름 Rename 모달 */}
        <Modal
          open={openRenameModal}
          mobile={mobile}
          onClose={() => {
            setRenameError(undefined);
            setOpenRenameModal(false);
          }}
          onClickNegative={() => {
            setRenameError(undefined);
            setOpenRenameModal(false);
          }}
          onClickPositive={() => {
            if (currentSim) {
              handleRename(currentSim);
            }
          }}
          heading={t("Edit name")}
          close
          loading={modalLoading}
          content={
            <div>
              <Input
                name="simName"
                style={{ paddingBottom: 3, paddingTop: 8 }}
                onChange={handleIccidNameChange}
                value={simName}
                autoFocus
                error={!!renameError}
                helperText={renameError && t(renameError)}
              />
              <div style={{ display: "flex", justifyContent: "center" }}>
                <div className={classes.lengthText}>
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["2"]}
                  >
                    {simName.length}/30
                  </Typography>
                </div>
              </div>
            </div>
          }
          contentClassName={classes.renameContent}
          titleClassName={classes.renameTitle}
          LButton={t("Cancel")}
          RButton={t("Edit")}
        />
        {/* 심 삭제 모달 */}
        <SimDeleteModal
          openModal={openDeleteModal}
          onClose={() => setOpenDeleteModal(false)}
          onClickPositive={handleDeleteSim}
          subscribed={subscribed}
          cancelled={cancelled}
          terminated={terminated}
          paused={paused}
          loading={loadingDelete}
        />
        {openResubscribeModal && (
          <UpgradeSelectModal
            open={openResubscribeModal}
            onClose={() => setOpenResubscribeModal(false)}
            onClickPositive={() => {
              setOpenResubscribeModal(false);
              getSimcard();
            }}
            currentSimCard={currentSim}
            mode="resubscribe"
          />
        )}
        {/* 구독이 완전만료된 cancel상태인 경우 */}
        {openResubscribePlanModal && (
          <ResubscribePlanModal
            plan={currentSim?.subscription && currentSim.subscription.planName}
            open={openResubscribePlanModal}
            onClose={() => setOpenResubscribePlanModal(false)}
            onClickPositive={(selectPlanProps) => {
              setOpenServiceEndBillingModal(true);
              setOpenResubscribePlanModal(false);
              setSelectPlan(selectPlanProps);
            }}
            loading={loading}
          />
        )}
        {/* 구독만료 billingModal */}
        {openServiceEndBillingModal && (
          <ServiceEndBillingModal
            currentSimCard={currentSim}
            afterPlan={selectPlan ?? ""}
            open={openServiceEndBillingModal}
            onClose={() => setOpenServiceEndBillingModal(false)}
            onClickPositive={() => {
              setOpenServiceEndBillingModal(false);
              getSimcard();
            }}
            onClickNegative={() => {
              setOpenServiceEndBillingModal(false);
              setOpenResubscribePlanModal(true);
            }}
          />
        )}
        <div style={{ paddingLeft: 4 }}>
          <Typography
            category="Default"
            variant="Small"
            htmlColor={LightColors.primary["2"]}
          >
            {t("Information may be_")}
          </Typography>
        </div>
      </div>
    </Container>
  );
};
