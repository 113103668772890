import React, { useCallback, useState } from "react";
import { LightColors, Modal, Typography } from "@thingsw/pitta-design-system";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Trans, useTranslation } from "react-i18next";
import { Card, useMediaQuery } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import clsx from "clsx";
import moment from "moment";
import { planAmount } from "../../types";
import { calculateProated } from "../../utils/Subscription";

export interface ModalProps {
  // plan : 현재 플랜 , nextPlan : 변경할 플랜
  plan?: string;
  open: boolean;
  loading?: boolean;
  onClose?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: (selectPlan?: string) => void;
  date?: moment.Moment;
}
export type PlanType = "1GB" | "3GB" | "5GB";

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 2),
    maxWidth: 672,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      marginTop: theme.spacing(16.25),
      marginBottom: theme.spacing(16.25),
      padding: 0,
    },
  },
  modalBody: {
    maxWidth: 672,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },

  modalDiv: {
    width: "100%",
    [theme.breakpoints.down("sm")]: {},
  },

  firstCardGroup: {
    marginTop: 24,
    marginBottom: 24,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },

  secondCardGroup: {
    marginTop: 23,
    marginBottom: 16,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: 24,
      marginBottom: 16,
    },
  },

  cardDiv: {
    display: "flex",
    textAlign: "end",
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
    cursor: "pointer",
    justifyContent: "space-between",
    padding: "23px 24px 24px 23px",
    alignItems: "center",
    marginTop: 16,

    [theme.breakpoints.up("sm")]: {
      width: 191,
      height: 128,
      flexDirection: "column",
      justifyContent: "center",
      marginTop: 0,
      padding: 0,
      aliginItems: "normal",
      textAlign: "center",
    },
  },

  cardDivNone: {
    display: "flex",
    textAlign: "end",
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
    cursor: "pointer",
    justifyContent: "space-between",
    padding: 24,
    alignItems: "center",
    marginTop: 15,

    [theme.breakpoints.up("sm")]: {
      width: 191,
      flexDirection: "column",
      justifyContent: "center",
      marginTop: 0,
      padding: 0,
      aliginItems: "normal",
      textAlign: "center",
    },
  },

  titleDiv: {
    padding: "12px 13px 11px 14px",
    [theme.breakpoints.up("sm")]: {
      padding: "12px 24px 42px",
    },
  },

  contentDiv: {
    padding: "16px 17px 16px 15px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 32px 48px",
    },
  },

  contentTitle: {
    paddingBottom: "12px",
    [theme.breakpoints.up("sm")]: {},
  },

  currentTitle: {
    paddingBottom: "13px",
    [theme.breakpoints.up("sm")]: {
      paddingBottom: "12px",
    },
  },

  select: {
    backgroundColor: `${LightColors.primary["10"]}`,
    "&:hover": {
      backgroundColor: `${LightColors.primary["10"]}`,
    },
  },

  currentStyle: {
    backgroundColor: LightColors.primary["6"],
    cursor: "default",
  },

  planStyle: {
    backgroundColor: LightColors.primary["10"],
    cursor: "default",
  },

  priceDiv: {
    [theme.breakpoints.up("sm")]: {
      margin: "8px 0px 12px",
    },
  },

  planDiv: {
    [theme.breakpoints.up("sm")]: {
      paddingTop: 4,
    },
  },

  currentContent: {
    display: "none",
  },

  mobileContentDiv: {
    padding: 16,
  },

  summaryCardFirst: {
    marginTop: 4,
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 22px 24px 24px",
    border: `1px solid ${LightColors.primary["3"]}59`,
    boxShadow: "none",

    [theme.breakpoints.up("sm")]: {
      padding: "26px 24px 19px 23px",
      width: 241,
      boxSizing: "border-box",
      display: "revert",
      justifyContent: "normal",
    },
  },

  summaryCardSecond: {
    marginTop: 4,
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 20px 24px 22px",
    marginBottom: 32,
    boxShadow: "none",

    [theme.breakpoints.up("sm")]: {
      padding: "26px 22px 19px 23px",
      width: 241,
      boxSizing: "border-box",
      marginTop: 4,
      display: "revert",
      justifyContent: "normal",
      marginBottom: 0,
    },
  },

  summaryPrice: {
    textAlign: "end",
    marginBottom: 16,
    marginTop: 4,

    [theme.breakpoints.up("sm")]: {
      padding: "12px 0 21px",
      textAlign: "revert",
      marginBottom: 0,
      marginTop: 0,
    },
  },

  summaryDiv: {
    paddingTop: 56,

    [theme.breakpoints.up("sm")]: {
      paddingTop: 56,
    },
  },

  summaryDate: {
    display: "flex",
    justifyContent: "end",

    [theme.breakpoints.up("sm")]: {},
  },

  summaryCardDiv: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      alignItems: "center",
      padding: "0 31px 32px",
    },
  },

  actionClassName: {
    [theme.breakpoints.down("sm")]: {
      padding: "16px 14px 12px 16px",
    },
  },

  eventContentDiv: {
    padding: 16,
    overflow: "hidden",

    [theme.breakpoints.up("sm")]: {
      padding: "0 32px 58px",
      height: 188,
    },
  },

  bottomContent: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: 50,
    },
  },

  firstCardDate: {
    [theme.breakpoints.up("sm")]: {},
  },

  secondCardDate: {
    [theme.breakpoints.up("sm")]: {},
  },
}));

export const ResubscribePlanModal = (props: ModalProps) => {
  const { plan, onClickPositive, date, open, onClose, loading } = props;
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  //   const [open, setOpen] = useState(false);
  const [selectPlan, setSelectPlan] = useState<PlanType>();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const today =
    date === undefined
      ? moment().format("MMM D, YYYY")
      : moment(date).format("MMM D, YYYY");
  const month = moment(today).endOf("month").format("MMM D, YYYY");
  const nextMonth = moment(today)
    .endOf("month")
    .add(1, "days")
    .format("MMM D, YYYY");
  const bottomMonth = moment(today)
    .endOf("month")
    .add(1, "days")
    .format("MMMM D, YYYY");

  // 첫번째 카드 그룹
  const renderCardBox = useCallback(
    (planName: PlanType) => {
      const amount = calculateProated(planName);
      return (
        <Card
          className={clsx(classes.cardDiv, {
            [classes.currentStyle]: planName !== plan,
            [classes.planStyle]: planName === plan,
          })}
        >
          <Typography
            category="Default"
            variant="H2"
            htmlColor={LightColors.primary["1"]}
            className={classes.planDiv}
          >
            {t(planName)}
          </Typography>
          <div>
            <div className={classes.priceDiv}>
              <Typography
                category="Default"
                variant="H5"
                htmlColor={LightColors.primary[planName !== plan ? "2" : "7"]}
              >
                {amount} USD
                {/* {t(planPrice)} */}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary[planName !== plan ? "2" : "7"]}
              >
                {t("/mo")}
              </Typography>
            </div>
          </div>
        </Card>
      );
    },
    [
      classes.cardDiv,
      classes.currentStyle,
      classes.planDiv,
      classes.planStyle,
      classes.priceDiv,
      plan,
      t,
    ]
  );

  // 두번째 카드 그룹

  const renderPlanBox = useCallback(
    (
      planName: string,
      // planPrice: string,
      choosePlan: PlanType,
      currentType: Boolean
    ) => {
      return (
        <Card
          className={clsx(classes.cardDiv, {
            [classes.select]: selectPlan === choosePlan,
            [classes.currentStyle]: currentType === true,
            [classes.cardDivNone]: currentType === false,
          })}
          onClick={() => {
            if (currentType === false) {
              setSelectPlan(choosePlan);
            }
          }}
        >
          <Typography
            category="Default"
            variant="H2"
            htmlColor={LightColors.primary["1"]}
            className={classes.planDiv}
          >
            {t(planName)}
          </Typography>
          <div>
            <div className={classes.priceDiv}>
              <Typography
                category="Default"
                variant="H5"
                htmlColor={
                  LightColors.primary[currentType === true ? "2" : "7"]
                }
              >
                {planAmount[planName as PlanType]} USD
                {/* {t(planPrice)} */}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={
                  LightColors.primary[currentType === true ? "2" : "7"]
                }
              >
                {t("/mo")}
              </Typography>
            </div>
          </div>
        </Card>
      );
    },
    [
      classes.cardDiv,
      classes.cardDivNone,
      classes.currentStyle,
      classes.planDiv,
      classes.priceDiv,
      classes.select,
      selectPlan,
      t,
    ]
  );

  return (
    <Modal
      mobile
      titleClassName={classes.titleDiv}
      contentClassName={classes.contentDiv}
      className={classes.modalBody}
      open={open}
      loading={loading}
      onClose={onClose}
      heading={t(mobile ? "Resubscribe" : "Resubscribe plan")}
      content={
        <div className={classes.modalDiv}>
          <div className={classes.contentTitle}>
            <Typography
              category="Default"
              variant="H3"
              htmlColor={LightColors.primary["1"]}
            >
              {t("Your plan from_")}
            </Typography>
          </div>
          <div>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
              dangerouslySetInnerHTML={{
                __html: t("Applied during_", {
                  a: today,
                  b: month,
                }),
              }}
            ></Typography>
          </div>
          <div className={classes.firstCardGroup}>
            {renderCardBox("1GB")}
            {renderCardBox("3GB")}
            {renderCardBox("5GB")}
          </div>
          <div style={{ display: "flex" }}>
            <div
              style={{
                marginRight: 16,
                marginTop: 2,
                color: LightColors.secondary["17"],
              }}
            >
              <WarningIcon />
            </div>
            <div>
              <Typography
                category="Default"
                variant="Small"
                htmlColor={LightColors.primary["2"]}
              >
                <Trans
                  t={t}
                  components={{
                    a: (
                      <Typography
                        category="Default"
                        variant="SmallBold"
                        htmlColor={LightColors.secondary["17"]}
                      ></Typography>
                    ),
                  }}
                >
                  From today until_
                </Trans>
              </Typography>
            </div>
          </div>
          <div className={classes.summaryDiv}>
            <div className={classes.currentTitle}>
              <Typography
                category="Default"
                variant="H3"
                htmlColor={LightColors.primary["1"]}
              >
                {t("Choose your next plan")}
              </Typography>
            </div>
            <div>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["2"]}
                dangerouslySetInnerHTML={{
                  __html: t("Applied from_", {
                    a: `${nextMonth}`,
                  }),
                }}
              ></Typography>
            </div>
            <div className={classes.secondCardGroup}>
              {renderPlanBox("1GB", "1GB", false)}
              {renderPlanBox("3GB", "3GB", false)}
              {renderPlanBox("5GB", "5GB", false)}
            </div>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              dangerouslySetInnerHTML={{
                __html: t("Payment for this_", {
                  a: `${bottomMonth}`,
                }),
              }}
            ></Typography>
          </div>
        </div>
      }
      RButtonDisabled={selectPlan === undefined}
      RButton={t("Next")}
      onClickPositive={() => onClickPositive?.(selectPlan)}
      close
      fullSize={mobile}
      actionClassName={classes.actionClassName}
    />
  );
};
