import React from "react";

import { makeStyles, Theme } from "@material-ui/core/styles";
import { LightColors, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import { useRouteMatch } from "react-router-dom";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    minHeight: 32,
    padding: theme.spacing(0, 2),
    marginTop: 2,
  },
  checkIcon: {
    color: LightColors.primary["7"],
    fontSize: 20,
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(0.375) }
      : { marginLeft: theme.spacing(0.375) }),
  },
  typo2: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(3) }
      : { marginLeft: theme.spacing(3) }),
  },
  typy2Check: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(2.5) + 1 }
      : { marginLeft: theme.spacing(2.5) + 1 }),
  },
  checkIconTwo: {
    color: LightColors.primary["7"],
    fontSize: 20,
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(0.875) }
      : { marginLeft: theme.spacing(0.875) }),
  },
}));

export const MiniAddCameraHeader = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography
        category="Default"
        variant="Caption"
        htmlColor={LightColors.primary["7"]}
      >
        1. {t("Add camera")}
      </Typography>

      <CheckCircleOutlineIcon className={classes.checkIcon} />

      <Typography
        category="Default"
        variant={
          path === "/subscribed" || path === "/subscribed-webviewer"
            ? "Caption"
            : "CaptionBold"
        }
        htmlColor={LightColors.primary["7"]}
        className={clsx(classes.typo2, classes.typy2Check)}
      >
        2. {t("Connect to cloud")}
      </Typography>

      {(path === "/subscribed" || path === "/subscribed-webviewer") && (
        <CheckCircleOutlineIcon className={classes.checkIconTwo} />
      )}
    </div>
  );
};
