import React, { useMemo, useState } from "react";
import {
  Button,
  DarkColors,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "@material-ui/core/Card";
import { CardContent, useMediaQuery, Divider } from "@material-ui/core";
import { detect } from "detect-browser";
import { planAmount } from "../types";
import { WEBVIEWER_URI } from "../contants/Server";
import { RootState } from "../features/store";
import { USER } from "../features/User/slice";
import { useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { calculateProated } from "../utils/Subscription";

export interface ISubscribedProps {
  noBtn?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 14,
    [theme.breakpoints.up("sm")]: {
      marginTop: 54,
      padding: 0,
    },
  },

  card: {
    backgroundColor: `${LightColors.primary["6"]}73`,
    marginBottom: 16,
    marginTop: 28,
    boxShadow: "none",

    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(4, 0, 2),
      width: 502,
      display: "inline",
    },
  },

  cardContent: {
    padding: 0,
    display: "grid",
    "&:last-child": {
      paddingBottom: 0,
    },
    [theme.breakpoints.up("sm")]: {
      padding: 0,
    },
  },

  cardContentTop: {
    display: "flex",
    padding: 0,
    justifyContent: "space-between",
    margin: theme.spacing(2, 2, 1.875),

    [theme.breakpoints.up("sm")]: {
      margin: (props: ISubscribedProps) =>
        props.noBtn ? "26px 32px 14px 32px" : "26px 32px 11px 32px",
    },
  },

  price: {
    justifySelf: "end",
    marginTop: theme.spacing(0.5),
    textDecorationLine: "line-through",
    [theme.breakpoints.up("sm")]: {},
  },

  cardContentBottom: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(2.25, 2, 3),
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      margin: theme.spacing(2.5, 4, 3),
      "&:last-child": {
        paddingBottom: 0,
      },
    },
  },

  content: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(4),
      width: 502,
      textAlign: "revert",
      margin: 0,
    },
  },

  btnGroup: {
    display: "grid",
    width: "100%",
    marginTop: 32,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      marginTop: 0,
    },
  },

  okBtn: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
  },

  goBtn: {
    [theme.breakpoints.up("sm")]: {
      width: "214px",
    },
  },

  infoBulle: {
    position: "relative",
    width: "100%",
    backgroundColor: DarkColors.primary["0"],
    borderRadius: 4,
    boxShadow: "0px 0px 1px rgba(0, 0, 0, 0.14)",
    marginTop: 15,
    height: 37,
    textAlign: "center",
    padding: "8px 12px",

    "&:after": {
      borderTop: "0px solid transparent",
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",
      borderBottom: `16px solid ${DarkColors.primary["0"]}`,
      content: "close-quote",
      position: "absolute",
      top: "-15px",
      left: "47%",
      borderStartStartRadius: 25,
    },
    [theme.breakpoints.up("sm")]: {
      width: 266,
      height: 37,
      padding: "8px 12px",
      marginLeft: 86,
      "&:after": {
        borderTop: "0px solid transparent",
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        borderBottom: `16px solid ${DarkColors.primary["0"]}`,
        content: "close-quote",
        position: "absolute",
        top: "-15px",
        left: 121,
        borderStartStartRadius: 25,
      },
    },
  },
  cardPadding: {
    padding: 0,
  },
  fleetPrice: {
    textDecorationLine: "line-through",
    marginBottom: 4,
    color: LightColors.primary["3"],
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end",
  },
  noDecoLine: {
    textDecorationLine: "none",
    color: LightColors.primary["1"],
  },
  divider: {
    width: "90%",
  },
}));

export const SuccessSubscribed = (props: ISubscribedProps) => {
  const classes = useStyles(props);
  const { noBtn } = props;
  const theme = useTheme() as Theme;
  const history = useHistory();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation<{
    plan: string;
    // simcard: Simcard;
    nextBillingDate: string;
    activatedDate: string;
    bonusDays?: string;
  }>();

  const [loading, setLoading] = useState(false);
  const { email, user_token, token_type } = useSelector(
    (state: RootState) => state[USER]
  );

  const activatedDate = useMemo(() => {
    return moment.utc(location.state.activatedDate);
  }, [location.state.activatedDate]);

  const isFreeTrial = useMemo(() => {
    // 오늘날짜로부터 전달 1일이 마지막 free trial 적용되는 날짜
    const lastFreeTrialDay = moment.utc().subtract(1, "month").startOf("month");
    // pbr exit날짜가 free trial적용되는 날짜와 같거나 이후면, free trial 적용
    return activatedDate.isSameOrAfter(lastFreeTrialDay);
  }, [activatedDate]);

  const freeTrialDayMarkup = useMemo(() => {
    if (!isFreeTrial) return;
    if (mobile) {
      return t("Free for 1 month");
    } else {
      let text = t("Free for 1 month");
      if (location.state?.bonusDays) {
        text +=
          " + " +
          t(`bonus n days`, {
            a: location.state?.bonusDays,
          });
      }
      return text;
    }
  }, [isFreeTrial, location.state?.bonusDays, mobile, t]);

  const planAmountMarkup = useMemo(() => {
    if (!isFreeTrial) {
      return calculateProated("1GB");
    }
    return planAmount["1GB"];
  }, [isFreeTrial]);

  return (
    <div className={classes.body}>
      <Typography
        category="Default"
        variant={mobile ? "H2" : "H1"}
        style={{ whiteSpace: "nowrap" }}
      >
        {t("Successfully subscribed")}
      </Typography>
      <Card className={classes.card}>
        <CardContent className={classes.cardContentTop}>
          <CardContent className={classes.cardContent}>
            <Typography category="Default" variant="H6">
              {t("1GB data plan")}
            </Typography>
          </CardContent>
          <CardContent className={classes.cardContent}>
            <div
              className={clsx(classes.fleetPrice, {
                [classes.noDecoLine]: !isFreeTrial,
              })}
            >
              <Typography
                category="Default"
                variant={isFreeTrial ? "BodyBold" : "H6"}
              >
                {planAmountMarkup} USD
              </Typography>
              <div style={{ marginTop: 1 }}>
                <Typography category="Default" variant={"Caption"}>
                  /{t("mo")}
                </Typography>
              </div>
            </div>
            <Typography
              category="Default"
              variant={noBtn && !mobile ? "BodyBold" : "H6"}
            >
              {/* 일단 임의로 숫자 넣어둠 */}
              {freeTrialDayMarkup}
            </Typography>
          </CardContent>
        </CardContent>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Divider className={classes.divider} />
        </div>

        <CardContent className={classes.cardContentBottom}>
          <Typography category="Default" variant="Body">
            {t("Next billing date")}
          </Typography>
          <Typography category="Default" variant="BodyBold">
            {location.state?.nextBillingDate}
          </Typography>
        </CardContent>
      </Card>
      <Typography category="Default" variant="Body" className={classes.content}>
        {t("Once SIM card_")}
      </Typography>
      <div>
        <div className={classes.btnGroup}>
          <Button
            className={classes.okBtn}
            variant="contained"
            color="primary"
            loading={loading}
            onClick={() => {
              if (location.pathname.indexOf("webviewer") > -1) {
                setLoading(true);
                const browser = detect();
                if (browser?.name === "ios-webview") {
                  //@ts-ignore
                  return webkit.messageHandlers.finish.postMessage({});
                }
                //@ts-ignore
                if (
                  browser?.name === "chromium-webview" &&
                  //@ts-ignore
                  window.Sim?.finish
                ) {
                  //@ts-ignore
                  return window.Sim.finish();
                }
                window.location.href = `${WEBVIEWER_URI}/cameras?from=sim`;
              } else {
                setLoading(false);
                history.push("/");
              }
            }}
          >
            {t("OK")}
          </Button>
          {noBtn && (
            <Button
              variant="outlined"
              color="primary"
              className={classes.goBtn}
              onClick={() => {
                const browser = detect();
                if (
                  browser?.name === "ios-webview" ||
                  browser?.name === "chromium-webview"
                ) {
                  window.location.href = `/?email=${email}&user_token=${user_token}&token_type=${token_type}`;
                } else {
                  window.open(
                    `/?email=${email}&user_token=${user_token}&token_type=${token_type}`
                  );
                }
              }}
            >
              {t("Go to SIM_")}
            </Button>
          )}
        </div>
        {noBtn && (
          <div className={classes.infoBulle}>
            <Typography
              category="Default"
              variant="SmallBold"
              htmlColor={DarkColors.primary["1"]}
            >
              {t("Manage your BlackVue_")}
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};
