import {
  AmexCard,
  DinersCard,
  DiscoverCard,
  JcbCard,
  MasterCard,
  UncategorizedCard,
  UnionPayCard,
  VisaCard,
} from "@thingsw/pitta-design-system";
import _ from "lodash";
import moment from "moment";
import { Simcard, PlanType, planAmount } from "../types";

export const calculateProated = (plan: PlanType) => {
  // 금액 일할 계산
  const amount = parseFloat(planAmount[plan]);
  const days = moment
    .utc()
    .endOf("month")
    .diff(moment.utc().startOf("month"), "minutes");
  // 구독이 월말 12시에 이루어짐
  const remains = moment
    .utc()
    .endOf("month")
    .subtract(12, "hour")
    .diff(moment.utc(), "minutes");
  console.log("days", days, "remains", remains);
  // return _.floor(_.round(amount / days, 4) * remains, 2);
  return _.round((amount / days) * remains, 2);
};

export const checkPaused = (simcard: Simcard): boolean => {
  return simcard.status === "Paused";
};

export const checkTerminated = (simcard: Simcard): boolean => {
  return simcard.status === "Terminated";
};

export const checkSubscribed = (simcard: Simcard): boolean => {
  return (
    !!simcard.subscription &&
    moment().isBefore(simcard.subscription.periodEnd) &&
    simcard.subscription.status !== "past_due" &&
    simcard.subscription.status !== "canceled"
  );
};

export const checkCanceled = (simcard: Simcard): boolean => {
  return (
    !!simcard.subscription &&
    (simcard.subscription.cancelAtPeriodEnd === true ||
      simcard.subscription.status === "past_due" ||
      simcard.subscription.status === "canceled")
  );
};

export const getCardIcon = (brand: string, className?: string) => {
  let cardImg = <UncategorizedCard />;
  switch (brand) {
    case "mastercard":
      cardImg = <MasterCard className={className} />;
      break;
    case "visa":
      cardImg = <VisaCard className={className} />;
      break;
    case "amex":
      cardImg = <AmexCard className={className} />;
      break;
    case "discover":
      cardImg = <DiscoverCard className={className} />;
      break;
    case "diners":
      cardImg = <DinersCard className={className} />;
      break;
    case "jcb":
      cardImg = <JcbCard className={className} />;
      break;
    case "unionpay":
      cardImg = <UnionPayCard className={className} />;
      break;
  }
  return cardImg;
};

export const getBrandName = (brand: string) => {
  if (brand === "mastercard") {
    return "MasterCard";
  }
  if (brand === "jbc") {
    return "JBC";
  }
  if (brand === "unionpay") {
    return "UnionPay";
  }

  return _.capitalize(brand);
};
