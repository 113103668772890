import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Theme, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
  Button,
  Container,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";

import { useTranslation } from "react-i18next";

import AddIcon from "@material-ui/icons/Add";
import SearchIcon from "@material-ui/icons/Search";
import CircularProgress from "@material-ui/core/CircularProgress";
import { EmptyItems } from "../components/EmptyItems";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import { RootState } from "../features/store";
import { USER } from "../features/User/slice";
import { useSelector } from "react-redux";
import axios from "axios";
import { SERVER_URI } from "../contants/Server";
import { Simcard } from "../types";
import { generateAuthToken } from "../utils/Auth";
import { SimcardOrderType, SimTable } from "../components/SimTable";
import _ from "lodash";
import { NoResults } from "../components/NoResults";
import { UpgradeSelectModal } from "../components/modals/UpgradeSelectModal";

import { ResubscribePlanModal } from "../components/modals/ResubscribePlanModal";
import ServiceEndBillingModal from "../components/modals/ServiceEndBillingModal";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: "calc(100vh - 56px - 90px)",
    // minHeight: "calc(100vh - 56px - 63px)",
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 2),
    [theme.breakpoints.up("lg")]: {
      minHeight: "calc(100vh - 56px - 76px)",
    },
  },
  rootSimCardTable: {
    [theme.breakpoints.up("lg")]: {
      // alignSelf: "center",
    },
  },
  body: {
    padding: 0,
    height: "100%",
    [theme.breakpoints.up("md")]: {
      padding: "17px 131px 0",
    },
  },
  bodySimCardTable: {
    // padding: "17px 0 0",
  },
  btnDiv: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  addBtnDiv: {
    display: "flex",
    alignItems: "center",
  },
  addBtn: {
    marginRight: theme.spacing(3),
    padding: theme.spacing(1, 2.25),
    minWidth: 158,
  },
  hidden: {
    display: "none",
  },
  emptyItemDiv: {
    display: "flex",
    alignItems: "center",
    backgroundColor: LightColors.primary["0"],

    padding: theme.spacing(0, 2),
    minHeight: 380,
    [theme.breakpoints.up("sm")]: {
      minHeight: 506,
      alignItems: "flex-start",
      padding: 0,
    },
  },
  searchBtn: {
    transition: theme.transitions.create("width"),
    right: "window.pageXOffsetpx",
    width: "100%",
    marginRight: 0,
  },
  webSearchOpen: {
    width: 320,
  },
  searchIcon: {
    minWidth: 30,
    minHeight: 36,
  },
  openSearchBtnDiv: {
    borderRadius: 4,
  },
  mobileSearchOpen: {
    marginRight: 0,
  },
  searchInputBackStyle: {
    backgroundColor: LightColors.primary["0"],
    marginTop: -1,
  },
  loadingDiv: {
    display: "flex",
    // @ts-ignore
    // eslint-disable-next-line no-dupe-keys
    display: "-webkit-flex",
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
    width: "100%",
    height: "100%",
  },
  simListWrap: {
    overflowX: "auto",
    overflowY: "hidden",
    height: "80%",
  },
  simListWrapHeight: {
    height: "auto",
  },
}));

const SimListScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const history = useHistory();

  const { email, user_token, token_type, userId } = useSelector(
    (state: RootState) => state[USER]
  );

  const [openSearch, setOpenSearch] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [simcards, setSimcards] = useState<Simcard[]>([]);
  const [simcardList, setSimcardList] = useState<Simcard[]>([]);
  const [loading, setLoading] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [openResubscribedModal, setResubscribedModal] = useState(false);
  const [openResubscribePlanModal, setOpenResubscribePlanModal] =
    useState(false);
  const [openServiceEndBillingModal, setOpenServiceEndBillingModal] =
    useState(false);
  const [currentSimCard, setCurrentSimCard] = useState<Simcard>();
  const [selectPlan, setSelectPlan] = useState<string>();
  const [order, setOrder] = useState<SimcardOrderType | undefined>();
  const [iconValue, setIconValue] = useState(0);
  const [total, setTotal] = useState(0);

  const getSimcard = useCallback(async () => {
    setLoading(true);
    if (!(email && user_token && token_type && userId)) return;
    try {
      let orderQuery = "";
      if (order) {
        orderQuery = `&order=${order.key}:${order.order}`;
      }
      const resp = await axios.get(
        `${SERVER_URI}/simcards?include=subscription,usages,trigger_usage${orderQuery}`,
        {
          headers: {
            Authorization: generateAuthToken(email, user_token),
            "X-Token-Type": token_type,
          },
        }
      );
      setTotal(resp.data.count);
      setSimcards(resp.data.simcards);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }, [email, order, token_type, userId, user_token]);

  useEffect(() => {
    console.log("reload simcard");
    getSimcard();
  }, [getSimcard]);

  useEffect(() => {
    if (order) {
      if (order.key === "iccid") {
        if (order.order === "asc") {
          setIconValue(1);
        } else if (order.order === "desc") {
          setIconValue(2);
        }
      } else if (order.key === "planName") {
        if (order.order === "asc") {
          setIconValue(3);
        } else if (order.order === "desc") {
          setIconValue(4);
        }
      } else if (order.key === "subscription_status") {
        if (order.order === "asc") {
          setIconValue(5);
        } else if (order.order === "desc") {
          setIconValue(6);
        }
      }
    } else {
      setIconValue(0);
    }
  }, [order]);

  useEffect(() => {
    if (simcards) {
      setSimcardList(
        _.chain(simcards)
          .filter((s) =>
            s.name
              ? s.iccid.toLowerCase().indexOf(searchKey.toLowerCase()) > -1 ||
                s.name.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
              : s.iccid.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
          )
          .value()
      );
    }
  }, [searchKey, simcards]);

  // 심 디테일페이지로 이동
  const handleClick = useCallback(
    (id: number) => {
      return history.push(`/${id}`);
    },
    [history]
  );
  // const handleClick = useCallback(
  //   (iccid) => {
  //     return history.push(`/${iccid}`, {
  //       simList: simcards,
  //     });
  //   },
  //   [history, simcards]
  // );

  // upgrade Modal handle
  const handleOpenUpgradeModal = useCallback(
    (iccid) => {
      const sim = _.find(simcards, (s) => s.iccid === iccid);
      setCurrentSimCard(sim);
      setOpenUpgradeModal(true);
    },
    [simcards]
  );

  // 구독기간이 남은 재구독 모달 handle
  const handleOpenResubscribeModal = useCallback(
    (iccid) => {
      const sim = _.find(simcards, (s) => s.iccid === iccid);
      setCurrentSimCard(sim);
      setResubscribedModal(true);
    },
    [simcards]
  );

  // 구독기간 완전 끝난 재구독 모달 handle
  const handleOpenEndResubscribeModal = useCallback(
    (iccid) => {
      const sim = _.find(simcards, (s) => s.iccid === iccid);
      setCurrentSimCard(sim);
      setOpenResubscribePlanModal(true);
    },
    [simcards]
  );

  // 로딩인 경우 로딩아이콘, 심이 하나도 없는경우 EmptyItems
  const simcardListMarkup = useMemo(() => {
    if (loading) {
      return (
        <div className={classes.loadingDiv}>
          <CircularProgress size={48} thickness={6} color="primary" />
        </div>
      );
    } else {
      if (simcards.length === 0) {
        return (
          <div className={classes.emptyItemDiv}>
            <EmptyItems />
          </div>
        );
      } else if (simcards.length > 0) {
        if (simcardList.length > 0) {
          return (
            <SimTable
              t={t}
              value={iconValue}
              currentOrder={order}
              onChangeOrder={(order) => setOrder(order)}
              onUpgrade={handleOpenUpgradeModal}
              onResubscribed={handleOpenResubscribeModal}
              onEndResubscribed={handleOpenEndResubscribeModal}
              onClick={handleClick}
              simcards={simcardList}
              noResult={simcardList.length === 0}
            />
          );
        } else {
          return (
            <div>
              <SimTable
                t={t}
                simcards={simcardList}
                noResult={simcardList.length === 0}
              />
              <NoResults />
            </div>
          );
        }
      }
    }
  }, [
    classes.emptyItemDiv,
    classes.loadingDiv,
    handleClick,
    handleOpenEndResubscribeModal,
    handleOpenResubscribeModal,
    handleOpenUpgradeModal,
    iconValue,
    loading,
    order,
    simcardList,
    simcards.length,
    t,
  ]);

  return (
    <div className={clsx(classes.root)}>
      <Container className={clsx(classes.body)}>
        <Typography category="Default" variant={mobile ? "H2" : "H1"}>
          {t("SIM card list")}
        </Typography>
        <div
          className={clsx(
            classes.btnDiv,
            openSearch && classes.openSearchBtnDiv
          )}
        >
          <div
            className={clsx(classes.addBtnDiv, {
              [classes.hidden]: mobile && openSearch,
            })}
          >
            <Button
              color="primary"
              type="submit"
              startIcon={<AddIcon />}
              className={classes.addBtn}
              onClick={() => history.push("/add-sim")}
            >
              {t("Add SIM card")}
            </Button>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
              style={{ whiteSpace: "nowrap" }}
            >
              {total} {total <= 1 ? t("SIM card") : t("SIM cards")}
            </Typography>
          </div>

          <Input
            inputID="SearchSIM"
            placeholder={t("Search SIM ICCID_")}
            startIcon={<SearchIcon />}
            className={clsx(classes.searchBtn, classes.searchIcon, {
              [classes.mobileSearchOpen]: openSearch,
              [classes.webSearchOpen]: !mobile,
            })}
            outlinedInputClassName={clsx(
              openSearch && classes.searchInputBackStyle
            )}
            onChange={(e) => setSearchKey(e.target.value)}
            value={searchKey}
            search
            dense
            onOpenSearch={() => setOpenSearch(true)}
            onCloseSearch={() => {
              setSearchKey("");
              setOpenSearch(false);
            }}
          />
        </div>
        <div
          className={clsx(classes.simListWrap, {
            [classes.simListWrapHeight]: simcards.length > 0,
          })}
        >
          {simcardListMarkup}
        </div>

        {openUpgradeModal && (
          <UpgradeSelectModal
            open={openUpgradeModal}
            onClose={() => setOpenUpgradeModal(false)}
            onClickPositive={() => {
              setOpenUpgradeModal(false);
              getSimcard();
            }}
            currentSimCard={currentSimCard}
            mode="upgrade"
          />
        )}
        {/* 구독기간이 남아있는 cancel상태인 경우 */}
        {openResubscribedModal && (
          <UpgradeSelectModal
            open={openResubscribedModal}
            onClose={() => setResubscribedModal(false)}
            onClickPositive={() => {
              setResubscribedModal(false);
              getSimcard();
            }}
            currentSimCard={currentSimCard}
            mode="resubscribe"
          />
        )}

        {/* 구독이 완전만료된 cancel상태인 경우 */}
        {openResubscribePlanModal && (
          <ResubscribePlanModal
            plan={
              currentSimCard?.subscription &&
              currentSimCard.subscription.planName
            }
            open={openResubscribePlanModal}
            onClose={() => setOpenResubscribePlanModal(false)}
            onClickPositive={(selectPlanProps) => {
              setOpenServiceEndBillingModal(true);
              setOpenResubscribePlanModal(false);
              setSelectPlan(selectPlanProps);
            }}
            loading={loading}
          />
        )}
        {/* 구독만료 billingModal */}
        {openServiceEndBillingModal && (
          <ServiceEndBillingModal
            currentSimCard={currentSimCard}
            afterPlan={selectPlan ?? ""}
            open={openServiceEndBillingModal}
            onClose={() => setOpenServiceEndBillingModal(false)}
            onClickPositive={() => {
              setOpenServiceEndBillingModal(false);
              getSimcard();
            }}
            onClickNegative={() => {
              setOpenServiceEndBillingModal(false);
              setOpenResubscribePlanModal(true);
            }}
          />
        )}
      </Container>
    </div>
  );
};

export default SimListScreen;
