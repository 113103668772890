import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { ReactSVG } from "react-svg";
import { LightColors, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import _ from "lodash";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
  },
  noItemDiv: {
    [theme.breakpoints.up("sm")]: {
      marginTop: "108px",
    },
  },

  noCamText: {
    marginTop: theme.spacing(1) + 2,
    marginBottom: theme.spacing(1),
  },

  noItemIcon: {
    width: 200,
    height: 200,
  },
  textCenter: {
    textAlign: "center",
    padding: "0 16px",
  },
}));

export const EmptyItems = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <ReactSVG
        src="/images/noItem.svg"
        className={classes.noItemDiv}
        beforeInjection={(svg) => {
          svg.classList.add(..._.split(classes.noItemIcon, " "));
        }}
      />

      <>
        <Typography
          category="Default"
          variant="H6"
          className={classes.noCamText}
          htmlColor={LightColors.primary["2"]}
        >
          {t("No SIM card_")}
        </Typography>
        <Typography
          category="Default"
          variant="Body"
          className={classes.textCenter}
          htmlColor={LightColors.primary["2"]}
        >
          {t("BlackVue sim card_")}
        </Typography>
        <Typography
          category="Default"
          variant="Body"
          className={classes.textCenter}
          htmlColor={LightColors.primary["2"]}
        >
          {t("Global support is coming soon.")}
        </Typography>
      </>
    </div>
  );
};
