import React, { useMemo } from "react";
import { Modal } from "@thingsw/pitta-design-system";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

export interface Status {
  openModal: boolean;
  onClose: () => void;
  onClickPositive: () => void;
  subscribed: boolean;
  cancelled: boolean;
  paused: boolean;
  terminated: boolean;
  loading: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 2),
    maxWidth: 672,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up(956)]: {
      width: "100%",
      marginTop: theme.spacing(16.25),
      marginBottom: theme.spacing(16.25),
      padding: 0,
    },
  },
  modalBody: {
    width: 558,
    [theme.breakpoints.up("sm")]: {},
  },
  modalHedding: {
    padding: "11px 24px 0px",
    [theme.breakpoints.up("sm")]: {},
  },
  modalContent: {
    padding: "19px 24px 23px",
    [theme.breakpoints.up("sm")]: {},
  },
  modalButton: {
    padding: "0px 22px 22px",
    [theme.breakpoints.up("sm")]: {},
  },
  modalClose: {
    top: 12,
    right: 11,
    [theme.breakpoints.up("sm")]: {},
  },
}));

export const SimDeleteModal = (props: Status) => {
  const {
    openModal,
    onClose,
    onClickPositive,
    subscribed,
    cancelled,
    paused,
    terminated,
    loading,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const modalContent = useMemo(() => {
    if (terminated || paused) {
      return "Are you sure_deleteSIM";
    }

    if (subscribed) {
      if (cancelled) {
        return "SIM card cannot_2";
      }
      return "SIM card cannot_";
    }
    return "";
  }, [cancelled, paused, subscribed, terminated]);

  const rbuttonMarkup = useMemo(() => {
    if (terminated || paused) {
      return "Delete";
    }
    return "OK";
  }, [paused, terminated]);

  return (
    <Modal
      className={classes.modalBody}
      open={openModal}
      close
      closeStyle={classes.modalClose}
      onClose={onClose}
      heading={t("Delete SIM")}
      titleClassName={classes.modalHedding}
      content={t(modalContent)}
      contentClassName={classes.modalContent}
      RButton={t(rbuttonMarkup)}
      onClickPositive={() => {
        if (terminated || paused) {
          onClickPositive();
        } else {
          onClose();
        }
      }}
      onClickNegative={onClose}
      LButton={terminated || paused ? t("Cancel") : ""}
      actionClassName={classes.modalButton}
      Secondary={terminated || paused}
      mobile
      loading={loading}
    />
  );
};
