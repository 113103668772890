import React, { useState } from "react";
import {
  Container,
  LightColors,
  Simcard1G,
  Simcard3G,
  Simcard5G,
  Typography,
} from "@thingsw/pitta-design-system";

import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { planAmount } from "../types";
import clsx from "clsx";
import { ReactSVG } from "react-svg";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 2),
    flex: 1,
    minHeight: "calc(100vh - 146px)",

    [theme.breakpoints.up(843)]: {
      alignItems: "center",
      minHeight: "calc(100vh - 146px)",
      padding: theme.spacing(11.25, 3, 2),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(17.125, 3, 2),
    },
  },
  titleDiv: {
    zIndex: 1,
    marginBottom: theme.spacing(5),
    marginTop: 20,
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      marginBottom: theme.spacing(9.25),
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(8) + 6,
    },
    [theme.breakpoints.between(834, "md")]: {
      marginBottom: theme.spacing(6),
    },
  },
  backImg: {
    width: "100vw",
    zIndex: 0,
    position: "absolute",
    left: 0,
    top: 102,
    maxHeight: 370,
    [theme.breakpoints.up("sm")]: {
      top: 164,
    },
    [theme.breakpoints.up("md")]: {
      top: 133,
    },
  },

  //simCardInfo css
  cardWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up(834)]: {
      justifyContent: "center",
      marginBottom: theme.spacing(5.25),
      flexDirection: "row",
      alignItems: "end",
    },
  },
  simCardDiv: {
    zIndex: 1,
    alignItems: "center",
    overflow: "hidden",
    backgroundColor: LightColors.primary["0"],
    border: `1px solid ${LightColors.primary["5"]}`,
    borderRadius: 5,
    width: "100%",
    minHeight: 138,
    maxWidth: 343,
    paddingLeft: 12,
    paddingRight: 12,

    [theme.breakpoints.up(834)]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      // width: 200,
      // height: 610,
      paddingLeft: 0,
      paddingBottom: 8,
      width: 251,
      height: 584,
    },

    // [theme.breakpoints.between(834, "md")]: {
    //   width: 251,
    //   height: 584,
    // },
    [theme.breakpoints.up("md")]: {
      width: 292,
    },
  },

  sim3GBDiv: {
    border: `2px solid ${LightColors.primary["7"]}`,
    position: "relative",
    margin: theme.spacing(2, 0),

    [theme.breakpoints.up(834)]: {
      margin: theme.spacing(0, 3),
      height: 645,
    },
    // [theme.breakpoints.between(834, "md")]: {
    //   height: 617,
    //   margin: theme.spacing(0, 2, 0, 2.125),
    //   paddingBottom: 28,
    // },
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(0, 5.375),
    },
  },
  planInfoDiv: {
    width: 163,
    display: "flex",
    flexDirection: "column",
    marginLeft: 7,
    marginTop: 5,
    marginRight: 12,
    [theme.breakpoints.up(834)]: {
      alignItems: "center",
      margin: 0,
      width: 203,
      // marginLeft: 15,
      marginRight: 0,
    },
    [theme.breakpoints.between(834, "md")]: {
      marginTop: -4,
    },
  },
  planName: {
    marginBottom: theme.spacing(0.625),
    [theme.breakpoints.up(834)]: {
      marginBottom: theme.spacing(4),
      textAlign: "center",
    },
  },

  recommendContDiv: {
    position: "absolute",
    width: 157,
    transform: "rotate(-45deg)",
    top: -30,
    left: -11,
    right: "unset",
    transformOrigin: "center",

    [theme.breakpoints.up(834)]: {
      transform: "rotate(-45deg)",
      top: 67,
      left: -70,
      right: "unset",
      transformOrigin: "center",
    },
  },

  //사다리꼴 모양
  recommendDiv: {
    display: "flex",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: LightColors.primary["7"],
    width: 200,
    height: 24,
    left: -88,
    [theme.breakpoints.up(834)]: {
      left: 24,
      height: 32,
    },
  },

  // 하단 안내 css
  infoDiv: {
    width: "100%",
    marginTop: theme.spacing(5),
    [theme.breakpoints.up(834)]: {
      marginTop: theme.spacing(1.25),
    },
    [theme.breakpoints.up("md")]: {
      // marginTop: theme.spacing(0.25),
      marginRight: theme.spacing(5.5),
      width: 917,
    },
    [theme.breakpoints.between(834, "md")]: {
      marginTop: 0,
    },
  },
  infoTitle: {
    marginBottom: theme.spacing(2) - 3,
  },
  infoContent: {},
  simIcon: {
    height: 90,
    width: "auto",

    [theme.breakpoints.up(834)]: {
      height: 130,
    },
  },
  csTeamEmail: {
    textDecoration: "none",
    color: LightColors.primary["7"],
  },

  // sim 하단 checking
  checkDiv: {
    marginTop: theme.spacing(2.5),
    marginLeft: theme.spacing(1.125),

    [theme.breakpoints.up(834)]: {
      width: 243,
      marginTop: theme.spacing(5.25),
      marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.between(834, "md")]: {
      marginTop: theme.spacing(3.5),
      marginLeft: theme.spacing(5),
      marginBottom: theme.spacing(2),
    },
  },
  checkDiv2: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(1.125),

    [theme.breakpoints.up("sm")]: {
      width: 243,
      marginTop: theme.spacing(6.5),
      marginLeft: theme.spacing(0),
    },
    [theme.breakpoints.between(834, "md")]: {
      marginTop: theme.spacing(5),
      marginLeft: theme.spacing(5),
      marginBottom: theme.spacing(-0.5),
    },
  },
  checkFlex: {
    display: "flex",
    maxWidth: 232,
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 203,
    },
    [theme.breakpoints.down(834)]: {
      maxWidth: 295,
    },
  },
  checkFlex2: {
    display: "flex",
    maxWidth: 232,
    marginBottom: theme.spacing(2.325),
    "&:last-child": {
      marginBottom: 0,
    },

    [theme.breakpoints.down("md")]: {
      maxWidth: 203,
    },
    [theme.breakpoints.down(834)]: {
      maxWidth: 295,
      marginBottom: theme.spacing(1.825),
    },
  },
  checkFlex3: {
    display: "flex",
    maxWidth: 232,
    marginBottom: theme.spacing(2),
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: 203,
    },
    [theme.breakpoints.down(834)]: {
      maxWidth: 295,
      marginBottom: theme.spacing(1.5),
    },
  },
  checkSVG: {
    marginRight: theme.spacing(1),
  },

  // mobile sim Click
  mobileCardDiv: {
    [theme.breakpoints.down(834)]: {
      height: 346,
    },
  },

  tabDiv: {
    paddingBottom: 14,

    [theme.breakpoints.down(834)]: {
      display: "flex",
      alignItems: "center",
      paddingBottom: 0,
      paddingTop: 22,
    },
  },

  tabIconDiv: {
    marginLeft: -3,
    [theme.breakpoints.up(834)]: {
      textAlign: "center",
      paddingTop: 12,
      marginLeft: 0,
    },
    [theme.breakpoints.between(834, "md")]: {
      paddingTop: 34,
    },
  },
  expandDiv: {
    cursor: "pointer",
  },
}));

const PricingScreen = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const mobile = useMediaQuery(theme.breakpoints.down(834));
  // const mobile = useMediaQuery(theme.breakpoints.down(662));
  const [tab, setTab] = useState(0);

  console.log("??", md, mobile);
  return (
    <Container>
      <div className={classes.root}>
        <div className={classes.titleDiv}>
          <Typography
            category={md || !mobile ? "Large" : "Default"}
            variant={md ? "H3" : mobile ? "H1" : "H2"}
          >
            {t("Pricing Data")}
          </Typography>
        </div>
        <div>
          <img
            srcSet="/images/pricingBack.png 1x"
            alt="pricingBack"
            className={classes.backImg}
          />
        </div>
        <div className={classes.cardWrap}>
          {/* 심 1GB Card */}
          <div
            className={clsx(
              classes.simCardDiv,
              mobile && tab === 1 && classes.mobileCardDiv
            )}
            onClick={() => {
              if (mobile) {
                if (tab !== 1) {
                  setTab(1);
                } else {
                  setTab(0);
                }
              }
            }}
          >
            <div className={classes.tabDiv}>
              <div className={classes.tabIconDiv}>
                <Simcard1G className={classes.simIcon} />
              </div>
              <div className={classes.planInfoDiv}>
                <div className={classes.planName}>
                  <div>
                    <Typography category="Default" variant="H3">
                      1GB data
                    </Typography>
                  </div>
                  {!mobile && (
                    <div>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("For Light Cloud_")}
                      </Typography>
                    </div>
                  )}
                </div>
                <div>
                  <Typography
                    category={mobile ? "Default" : "Large"}
                    // variant={mobile ? "H6" : "H4"}
                    variant={md || !mobile ? "H3" : "H2"}
                    htmlColor={LightColors.primary["7"]}
                  >
                    {planAmount["1GB"]}{" "}
                  </Typography>

                  <Typography
                    category="Default"
                    // variant={mobile ? "Small" : "H4"}
                    variant={md || !mobile ? "H4" : "Small"}
                    htmlColor={LightColors.primary["7"]}
                    style={{ fontWeight: 400 }}
                  >
                    USD
                  </Typography>
                </div>
                <div>
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["3"]}
                  >
                    {t("Billed monthly")}
                  </Typography>
                </div>
                {!mobile && (
                  <div className={classes.checkDiv}>
                    <div className={classes.checkFlex}>
                      <ReactSVG
                        src="/images/pricingCheck.svg"
                        className={classes.checkSVG}
                      />
                      <div>
                        <Typography category="Default" variant="Body">
                          {t("90 minutes Live_")}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.checkFlex2}>
                      <ReactSVG
                        src="/images/pricingCheck.svg"
                        className={classes.checkSVG}
                      />
                      <div>
                        <Typography category="Default" variant="Body">
                          {t("or 2+ videos_")}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.checkFlex3}>
                      <ReactSVG
                        src="/images/pricingCheck.svg"
                        className={classes.checkSVG}
                      />
                      <div>
                        <Typography category="Default" variant="Body">
                          {t("1GB Free Month_")}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.checkFlex}>
                      <ReactSVG
                        src="/images/pricingCheck.svg"
                        className={classes.checkSVG}
                      />
                      <div>
                        <Typography category="Default" variant="Body">
                          {t("Cancel anytime")}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {mobile && (
                <div>{tab === 1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
              )}
            </div>
            {mobile && tab === 1 && (
              <div>
                <div className={classes.checkDiv}>
                  <div className={classes.checkFlex}>
                    <ReactSVG
                      src="/images/pricingCheck.svg"
                      className={classes.checkSVG}
                    />
                    <div>
                      <Typography category="Default" variant="Body">
                        {t("90 minutes Live_")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.checkFlex}>
                    <ReactSVG
                      src="/images/pricingCheck.svg"
                      className={classes.checkSVG}
                    />
                    <div>
                      <Typography category="Default" variant="Body">
                        {t("or 2+ videos_")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.checkFlex}>
                    <ReactSVG
                      src="/images/pricingCheck.svg"
                      className={classes.checkSVG}
                    />
                    <div>
                      <Typography category="Default" variant="Body">
                        {t("1GB Free Month_")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.checkFlex}>
                    <ReactSVG
                      src="/images/pricingCheck.svg"
                      className={classes.checkSVG}
                    />
                    <div>
                      <Typography category="Default" variant="Body">
                        {t("Cancel anytime")}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* 심 3GB Card */}
          <div
            className={clsx(
              classes.simCardDiv,
              classes.sim3GBDiv,
              mobile && tab === 2 && classes.mobileCardDiv
            )}
            onClick={() => {
              if (mobile) {
                if (tab !== 2) {
                  setTab(2);
                } else {
                  setTab(0);
                }
              }
            }}
          >
            <div className={classes.tabDiv}>
              <div className={classes.recommendContDiv}>
                <div className={classes.recommendDiv}>
                  <Typography
                    category="Default"
                    // variant={mobile ? "Caption" : "Small"}
                    variant={md || !mobile ? "Small" : "Caption"}
                    htmlColor={LightColors.primary["0"]}
                  >
                    {t("Popular")}
                  </Typography>
                </div>
              </div>
              <div className={classes.tabIconDiv}>
                <Simcard3G className={classes.simIcon} />
              </div>
              <div className={classes.planInfoDiv}>
                <div className={classes.planName}>
                  <div>
                    <Typography category="Default" variant="H3">
                      3GB data
                    </Typography>
                  </div>

                  {!mobile && (
                    <div>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("Balanced Plan")}
                      </Typography>
                    </div>
                  )}
                </div>
                <div>
                  <Typography
                    category={mobile ? "Default" : "Large"}
                    // variant={mobile ? "H6" : "H4"}
                    variant={md || !mobile ? "H3" : "H2"}
                    htmlColor={LightColors.primary["7"]}
                  >
                    {planAmount["3GB"]}{" "}
                  </Typography>

                  <Typography
                    category="Default"
                    // variant={mobile ? "Small" : "H4"}
                    variant={md || !mobile ? "H5" : "Small"}
                    htmlColor={LightColors.primary["7"]}
                    style={{ fontWeight: 400 }}
                  >
                    USD
                  </Typography>
                </div>
                <div>
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["3"]}
                  >
                    {t("Billed monthly")}
                  </Typography>
                </div>
                {!mobile && (
                  <div className={classes.checkDiv2}>
                    <div className={classes.checkFlex}>
                      <ReactSVG
                        src="/images/pricingCheck.svg"
                        className={classes.checkSVG}
                      />
                      <div>
                        <Typography category="Default" variant="Body">
                          {t("8+ hours Live_")}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.checkFlex2}>
                      <ReactSVG
                        src="/images/pricingCheck.svg"
                        className={classes.checkSVG}
                      />
                      <div>
                        <Typography category="Default" variant="Body">
                          {t("or 12+ videos_")}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.checkFlex3}>
                      <ReactSVG
                        src="/images/pricingCheck.svg"
                        className={classes.checkSVG}
                      />
                      <div>
                        <Typography category="Default" variant="Body">
                          {t("1GB Free Month_")}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.checkFlex}>
                      <ReactSVG
                        src="/images/pricingCheck.svg"
                        className={classes.checkSVG}
                      />
                      <div>
                        <Typography category="Default" variant="Body">
                          {t("Cancel anytime")}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {mobile && (
                <div>{tab === 2 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
              )}
            </div>
            {mobile && tab === 2 && (
              <div>
                <div className={classes.checkDiv}>
                  <div className={classes.checkFlex}>
                    <ReactSVG
                      src="/images/pricingCheck.svg"
                      className={classes.checkSVG}
                    />
                    <div>
                      <Typography category="Default" variant="Body">
                        {t("8+ hours Live_")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.checkFlex2}>
                    <ReactSVG
                      src="/images/pricingCheck.svg"
                      className={classes.checkSVG}
                    />
                    <div>
                      <Typography category="Default" variant="Body">
                        {t("or 12+ videos_")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.checkFlex3}>
                    <ReactSVG
                      src="/images/pricingCheck.svg"
                      className={classes.checkSVG}
                    />
                    <div>
                      <Typography category="Default" variant="Body">
                        {t("1GB Free Month_")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.checkFlex}>
                    <ReactSVG
                      src="/images/pricingCheck.svg"
                      className={classes.checkSVG}
                    />
                    <div>
                      <Typography category="Default" variant="Body">
                        {t("Cancel anytime")}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* 심 5GB Card */}
          <div
            className={clsx(
              classes.simCardDiv,
              mobile && tab === 3 && classes.mobileCardDiv
            )}
            onClick={() => {
              if (mobile) {
                if (tab !== 3) {
                  setTab(3);
                } else {
                  setTab(0);
                }
              }
            }}
          >
            <div className={classes.tabDiv}>
              <div className={classes.tabIconDiv}>
                <Simcard5G className={classes.simIcon} />
              </div>
              <div className={classes.planInfoDiv}>
                <div className={classes.planName}>
                  <div>
                    <Typography category="Default" variant="H3">
                      5GB data
                    </Typography>
                  </div>
                  {!mobile && (
                    <div>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                      >
                        {t("For Power Users")}
                      </Typography>
                    </div>
                  )}
                </div>
                <div>
                  <Typography
                    category={mobile ? "Default" : "Large"}
                    // variant={mobile ? "H6" : "H4"}
                    variant={md || !mobile ? "H3" : "H2"}
                    htmlColor={LightColors.primary["7"]}
                  >
                    {planAmount["5GB"]}{" "}
                  </Typography>

                  <Typography
                    category="Default"
                    // variant={mobile ? "Small" : "H4"}
                    variant={md || !mobile ? "H5" : "Small"}
                    htmlColor={LightColors.primary["7"]}
                    style={{ fontWeight: 400 }}
                  >
                    USD
                  </Typography>
                </div>
                <div>
                  <Typography
                    category="Default"
                    variant="Caption"
                    htmlColor={LightColors.primary["3"]}
                  >
                    {t("Billed monthly")}
                  </Typography>
                </div>
                {!mobile && (
                  <div className={classes.checkDiv}>
                    <div className={classes.checkFlex}>
                      <ReactSVG
                        src="/images/pricingCheck.svg"
                        className={classes.checkSVG}
                      />
                      <div>
                        <Typography category="Default" variant="Body">
                          {t("15+ hours Live_")}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.checkFlex2}>
                      <ReactSVG
                        src="/images/pricingCheck.svg"
                        className={classes.checkSVG}
                      />
                      <div>
                        <Typography category="Default" variant="Body">
                          {t("or 23+ videos_")}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.checkFlex3}>
                      <ReactSVG
                        src="/images/pricingCheck.svg"
                        className={classes.checkSVG}
                      />
                      <div>
                        <Typography category="Default" variant="Body">
                          {t("1GB Free Month_")}
                        </Typography>
                      </div>
                    </div>
                    <div className={classes.checkFlex}>
                      <ReactSVG
                        src="/images/pricingCheck.svg"
                        className={classes.checkSVG}
                      />
                      <div>
                        <Typography category="Default" variant="Body">
                          {t("Cancel anytime")}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {mobile && (
                <div>{tab === 3 ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
              )}
            </div>
            {mobile && tab === 3 && (
              <div>
                <div className={classes.checkDiv}>
                  <div className={classes.checkFlex}>
                    <ReactSVG
                      src="/images/pricingCheck.svg"
                      className={classes.checkSVG}
                    />
                    <div>
                      <Typography category="Default" variant="Body">
                        {t("15+ hours Live_")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.checkFlex}>
                    <ReactSVG
                      src="/images/pricingCheck.svg"
                      className={classes.checkSVG}
                    />
                    <div>
                      <Typography category="Default" variant="Body">
                        {t("or 23+ videos_")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.checkFlex}>
                    <ReactSVG
                      src="/images/pricingCheck.svg"
                      className={classes.checkSVG}
                    />
                    <div>
                      <Typography category="Default" variant="Body">
                        {t("1GB Free Month_")}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.checkFlex}>
                    <ReactSVG
                      src="/images/pricingCheck.svg"
                      className={classes.checkSVG}
                    />
                    <div>
                      <Typography category="Default" variant="Body">
                        {t("Cancel anytime")}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* 하단 안내 */}
        <div className={classes.infoDiv}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
          >
            {t("Estimates based on_")}
          </Typography>
        </div>
      </div>
    </Container>
  );
};

export default PricingScreen;
