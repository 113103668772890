import React, { useEffect, useMemo, useRef, useState } from "react";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import {
  Avatars,
  Container,
  LightColors,
  LogoFull,
  LogoOnly,
  Tooltip,
  Typography,
  UserNames,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";

import { AppBar } from "./AppBar";
import { Toolbar } from "./Toolbar";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "@material-ui/core";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import clsx from "clsx";
import { WEBVIEWER_URI } from "../contants/Server";
import { useDispatch, useSelector } from "react-redux";
import { ProfileMenu } from "./ProfileMenu";
import {
  loadSubscription,
  loadUserProfile,
  USER,
} from "../features/User/slice";
import { RootState } from "../features/store";
import { getPlanFromServiceID } from "../types";

const useStyles = makeStyles((theme: Theme) => ({
  vcenter: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  grow: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerText: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  marginR13: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1.625) }
      : { marginRight: theme.spacing(1.625) }),
  },
  marginL7: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(7) }
      : { marginRight: theme.spacing(7) }),
  },
  tootip: {
    display: "flex",
    flexDirection: "column",
  },
  typoDiv: {
    display: "flex",
    ...(theme.direction === "rtl"
      ? { marginRight: 56.81 }
      : { marginLeft: 56.81 }),
  },
  typo2: {
    display: "flex",
    alignItems: "center",
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(4) }
      : { marginLeft: theme.spacing(4) }),
  },
  typy2Check: {
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(3.5) }
      : { marginLeft: theme.spacing(3.5) }),
  },
  checkIcon: {
    color: LightColors.primary["7"],
    ...(theme.direction === "rtl" ? { marginRight: 3 } : { marginLeft: 3 }),
  },
  failedIcon: {
    ...(theme.direction === "rtl" ? { marginRight: 3 } : { marginLeft: 3 }),
  },
}));

interface AddCameraHeaderProps {
  failed?: boolean;
}

export const AddCameraHeader = ({ failed }: AddCameraHeaderProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [openAvatarTooltip, setOpenAvatarTooltip] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const location = useLocation();

  const dispatch = useDispatch();
  const profileAnchorRef = useRef<HTMLDivElement>(null);
  const [planName, setPlanName] = useState<string>();
  const { email, userProfile, subscriptionInfo, user_token, token_type } =
    useSelector((state: RootState) => state[USER]);

  useEffect(() => {
    if (subscriptionInfo) {
      setPlanName(getPlanFromServiceID(subscriptionInfo.servicePlanID));
    }
  }, [subscriptionInfo]);

  useEffect(() => {
    dispatch(loadUserProfile());
    dispatch(loadSubscription());
  }, [dispatch, email, token_type, user_token]);

  const AvatarMarkup = useMemo(() => {
    return (
      <>
        <Tooltip
          disableTouchListener={mobile}
          placement="bottom"
          open={openAvatarTooltip && !openProfileMenu}
          onOpen={() => setOpenAvatarTooltip(true)}
          onClose={() => setOpenAvatarTooltip(false)}
          title={
            <div className={classes.tootip}>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["0"]}
              >
                {userProfile?.firstName} {userProfile?.lastName}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["0"]}
              >
                {email}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["0"]}
              >
                {t(planName ?? "")}
                {planName === "Fleet plan" &&
                  userProfile?.userType &&
                  ` · ${t(UserNames[userProfile.userType])}`}
              </Typography>
            </div>
          }
        >
          <Avatars
            style={{ cursor: "pointer" }}
            ref={profileAnchorRef}
            imgSrc={userProfile?.profilePath}
            name={userProfile?.lastName}
            onClick={() => {
              setOpenProfileMenu(true);
            }}
          />
        </Tooltip>
        <ProfileMenu
          open={openProfileMenu}
          anchorEl={profileAnchorRef.current}
          placement="bottom-end"
          onClickAway={() => setOpenProfileMenu(false)}
          onCloseMenu={() => setOpenProfileMenu(false)}
        />
      </>
    );
  }, [
    classes.tootip,
    email,
    mobile,
    openAvatarTooltip,
    openProfileMenu,
    planName,
    t,
    userProfile,
  ]);

  return (
    <AppBar color="default">
      <Container>
        <Toolbar>
          {/* mobile */}
          <div
            onClick={() => {
              window.location.href = `${WEBVIEWER_URI}/cameras`;
            }}
            className={classes.vcenter}
          >
            <Hidden smUp>
              <LogoOnly width={36} height={36} />
            </Hidden>
            <Hidden xsDown>
              <LogoFull width={166} height={29} />
            </Hidden>
          </div>
          <div className={classes.grow}>
            <div>
              <Hidden xsDown>
                <div className={classes.typoDiv}>
                  <Typography
                    category="Default"
                    variant="Body"
                    htmlColor={LightColors.primary["7"]}
                  >
                    1. {t("Add camera")}
                  </Typography>
                  <CheckCircleOutlineIcon className={classes.checkIcon} />
                  <div className={clsx(classes.typo2, classes.typy2Check)}>
                    <Typography
                      category="Default"
                      variant={
                        location.pathname === "/webviewer/subscribed" ||
                        location.pathname === "/webviewer/failed"
                          ? "Body"
                          : "BodyBold"
                      }
                      htmlColor={
                        failed
                          ? LightColors.secondary["11"]
                          : LightColors.primary["7"]
                      }
                    >
                      2. {t("Connect to cloud")}
                    </Typography>
                    {location.pathname === "/webviewer/subscribed" && (
                      <CheckCircleOutlineIcon className={classes.checkIcon} />
                    )}
                    {location.pathname === "/webviewer/failed" && (
                      <div className={classes.failedIcon}>
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="10"
                            cy="10"
                            r="9.25"
                            stroke="#D81A26"
                            stroke-width="1.5"
                          />
                          <path
                            d="M14.0742 6.74609L10.8203 10L14.0742 13.2539L13.2539 14.0742L10 10.8203L6.74609 14.0742L5.92578 13.2539L9.17969 10L5.92578 6.74609L6.74609 5.92578L10 9.17969L13.2539 5.92578L14.0742 6.74609Z"
                            fill="#D81A26"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                </div>
              </Hidden>
            </div>
            <div>{AvatarMarkup}</div>
          </div>
        </Toolbar>
      </Container>
    </AppBar>
  );
};;
