import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { LightColors, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

export interface INoResultsProps {}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: 487,
    margin: theme.spacing(2, 0),
    flexGrow: 1,
  },
  content: {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.25),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(0.25),
      marginBottom: 0,
    },
  },
}));

export const NoResults = (props: INoResultsProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Typography
        category="Default"
        variant="H6"
        htmlColor={LightColors.primary["3"]}
      >
        {t("No results")}
      </Typography>
      <Typography
        category="Default"
        variant="Body"
        className={clsx(classes.content)}
        htmlColor={LightColors.primary["3"]}
      >
        {t("Try different keywords")}
      </Typography>
    </div>
  );
};
