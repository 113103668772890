import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { LightColors, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";

import { Line } from "react-chartjs-2";
import _ from "lodash";
import moment from "moment";
import { Card } from "@material-ui/core";
import { Usage } from "../types";
import { useSwipeable } from "react-swipeable";

export interface DailyProps {
  plan?: string;
  usages?: Usage[];
  terminated?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  chartSize: {
    width: "100%",
    overflowX: "scroll",

    [theme.breakpoints.up("sm")]: {},
  },

  overflow: {
    overflow: "auto",
    position: "relative",
  },

  chartBody: {
    height: 200,
  },

  swipe: {
    backgroundColor: LightColors.primary["1"],
    width: 228,
    height: 35,
    borderRadius: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%);",
    zIndex: 999,
  },

  card: {
    flex: 1,
    height: 328,
    padding: "24px 32px",
    border: `1px solid ${LightColors.primary["5"]}`,
    boxSizing: "border-box",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      minWidth: 475,
    },
  },
}));

export const DailyUsage = (props: DailyProps) => {
  const { usages, terminated } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const deltaX = useRef(0);
  const dataLength = useRef(0);
  const [swiped, setSwiped] = useState(false);
  const [shownIndex, setShownIndex] = useState(0);
  const handlers = useSwipeable({
    onSwiping: (eventData) => {
      setSwiped(true);
      if (Math.abs(deltaX.current - eventData.absX) > 100) {
        deltaX.current = eventData.absX;
        if (eventData.dir === "Left") {
          setShownIndex((s) => Math.max(s - 1, 0));
        } else if (eventData.dir === "Right") {
          setShownIndex((s) => Math.min(s + 1, dataLength.current - 7));
        }
      }
    },
    // ...config,
    trackMouse: true,
  });

  useEffect(() => {
    return () => {
      var tooltipEl = document.getElementById("chartjs-tooltip");
      if (tooltipEl) {
        tooltipEl.remove();
      }
    };
  }, []);

  const generateData = useCallback(
    (month: string[], usages: number[]) => (canvas: HTMLCanvasElement) => {
      if (terminated) {
        return {
          labels: month,
          datasets: [],
        };
      }
      const ctx = canvas.getContext("2d");
      let gradient: CanvasGradient | undefined = undefined;
      if (ctx) {
        gradient = ctx.createLinearGradient(0, 0, 0, 200);
        gradient.addColorStop(0, "rgba(0, 149, 224, 1)");
        gradient.addColorStop(1, "rgba(0, 149, 224, 0)");

        // pointStyle = ctx.create
      }
      return {
        labels: month,
        datasets: [
          {
            pointRadius: usages.length === 1 ? 3 : 0,
            data: usages,
            borderColor: LightColors.primary["7"],
            borderWidth: 1,
            backgroundColor: gradient, // Put the gradient here as a fill color
            fill: true,
            pointHitRadius: 10,
            pointHoverRadius: 0,
            pointHoverBackgroundColor: "white",
          },
        ],
      };
    },
    [terminated]
  );

  const DataChart = useMemo(() => {
    // const data = _.chain(usages)
    //   .sortBy((usage) => moment.utc(usage.date).unix())
    //   .value();
    // const first = _.first(data);
    const days = Math.max(
      moment.utc().diff(moment.utc().subtract(1, "month").add(1, "days"), "d"),
      7
    );
    // const days = Math.max(moment.utc().diff(moment.utc(first?.date), "d"), 7);
    const month1 = _.range(0, days + 1).map((d) =>
      moment.utc().subtract(d, "d")
    );
    const month = _.chain([...month1])
      .map((d) => d.format("MMM D"))
      .uniq()
      .slice(shownIndex, shownIndex + 7)
      .reverse()
      .value();
    dataLength.current = month1.length;

    const usage = _.map(month, (m) => {
      return _.chain(usages)
        .filter((item) => moment.utc(item.date).format("MMM D") === m)
        .sumBy((item) => (item.download + item.upload) / 1024)
        .round()
        .value();
    });

    let chartData = _.chain(usage).max().add(150).round(-2).value();
    chartData = Math.max(chartData, 1024);

    return (
      <div
        className={classes.overflow}
        {...handlers}
        // onMouseEnter={() => setSwiped(true)}
        // onTouchStart={() => setSwiped(true)}
      >
        <div style={{ paddingLeft: 15 }}>
          <Typography
            category="Default"
            variant="Small"
            style={{ fontSize: 12 }}
            htmlColor={LightColors.primary["2"]}
          >
            {t("MB")}
          </Typography>
        </div>
        {!swiped && (dataLength.current ?? 0) > 7 && (
          <div className={classes.swipe}>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["0"]}
            >
              {t("Swipe left and right to move")}
            </Typography>
          </div>
        )}
        <div className={classes.chartBody}>
          <Line
            style={{ position: "relative" }}
            id="chart"
            data={generateData(month, usage)}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              hover: {
                mode: "nearest",
                intersect: true,
              },
              plugins: {
                // plugins-legend-display: false 쓰니까 라벨 사라짐
                legend: {
                  display: false,
                },
                // ToolTip //
                tooltip: {
                  // Disable the on-canvas tooltip
                  enabled: false,
                  external: (context: any) => {
                    // Tooltip Element
                    var tooltipEl = document.getElementById("chartjs-tooltip");

                    // Create element on first render
                    if (!tooltipEl) {
                      tooltipEl = document.createElement("div");
                      tooltipEl.id = "chartjs-tooltip";
                      document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    var tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                      tooltipEl.style.opacity = "0";
                      return;
                    }

                    function getBody(bodyItem: any) {
                      return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                      var bodyLines = _.flattenDeep(
                        tooltipModel.body.map(getBody)
                      );

                      const bodies = _.map(
                        bodyLines,
                        (body: string, i: number) => {
                          return `<div style="display:flex;align-items:center;">${body}MB</div>`;
                        }
                      );

                      tooltipEl.innerHTML = `<div>${_.join(bodies, "")}</div>`;
                    }

                    var position = context.chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.boxShadow = "none";
                    tooltipEl.style.opacity = "1";
                    tooltipEl.style.backgroundColor = "transparent";
                    tooltipEl.style.color = "#8A3FFC";
                    tooltipEl.style.position = "absolute";
                    tooltipEl.style.left =
                      position.left +
                      window.pageXOffset +
                      tooltipModel.caretX +
                      "px";
                    tooltipEl.style.top =
                      position.top +
                      window.pageYOffset +
                      tooltipModel.caretY / 2 -
                      14 +
                      "px";
                    tooltipEl.style.padding = "4px 8px";
                    tooltipEl.style.pointerEvents = "none";
                    tooltipEl.style.fontFamily = "Roboto";
                    tooltipEl.style.fontWeight = "500";
                    tooltipEl.style.fontSize = "16px";
                    tooltipEl.style.lineHeight = "19px";
                    tooltipEl.style.transform = "translate(-50%, -5px)";
                  },
                },
                // // TooTip 여기까지
              },
              scales: {
                y: {
                  stacked: true,
                  min: 0,
                  max: chartData,
                  grid: {
                    //점선
                    borderDash: [5, 1],
                  },
                  ticks: {
                    stepSize: chartData / 4,
                    callback: function (val: any) {
                      if (terminated) {
                        if (chartData === val) {
                          return "0";
                        }
                        return "";
                      } else {
                        return val
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                      }
                    },
                  },
                },
                x: {
                  grid: {
                    //점선
                    borderDash: [5, 1],
                  },
                  offset: true,
                  stacked: true,
                  ticks: {
                    display: true,
                    minRotation: 10,
                  },
                  title: {
                    display: false,
                    text: "Date",
                  },
                },
              },
            }}
            plugins={[
              {
                id: "drawLine",
                afterDraw: (chart) => {
                  //@ts-ignore
                  if (chart.tooltip?._active?.length) {
                    //@ts-ignore
                    let x = chart.tooltip._active[0].element.x;
                    //@ts-ignore
                    let y = chart.tooltip._active[0].element.y;
                    let yAxis = chart.scales.y;
                    let ctx = chart.ctx;
                    ctx.save();
                    ctx.beginPath();
                    ctx.moveTo(x, yAxis.top);
                    ctx.lineTo(x, yAxis.bottom);
                    ctx.setLineDash([5, 5]);
                    ctx.lineWidth = 2;
                    ctx.strokeStyle = "#0095E0";
                    ctx.stroke();
                    ctx.restore();

                    ctx.save();
                    ctx.moveTo(x, y);
                    ctx.beginPath();
                    ctx.arc(x, y, 12, 0, Math.PI * 2, true);
                    ctx.fillStyle = "rgba(138, 63, 252, 0.2)";
                    ctx.fill();
                    ctx.restore();

                    ctx.save();
                    ctx.moveTo(x, y);
                    ctx.beginPath();
                    ctx.arc(x, y, 4, 0, Math.PI * 2, true);
                    ctx.fillStyle = "#8A3FFC";
                    ctx.fill();
                    ctx.restore();

                    ctx.save();
                    ctx.moveTo(x, y);
                    ctx.beginPath();
                    ctx.arc(x, y, 2, 0, Math.PI * 2, true);
                    ctx.fillStyle = "white";
                    ctx.fill();
                    ctx.restore();
                  }
                },
              },
            ]}
          />
        </div>
        <div style={{ textAlign: "right", marginTop: "-10px" }}>
          <Typography
            category="Default"
            variant="Small"
            style={{ fontSize: 12 }}
            htmlColor={LightColors.primary["2"]}
          >
            {t("Date")}
          </Typography>
        </div>
      </div>
    );
  }, [
    classes.chartBody,
    classes.overflow,
    classes.swipe,
    generateData,
    handlers,
    shownIndex,
    swiped,
    t,
    terminated,
    usages,
  ]);

  return (
    <Card className={classes.card}>
      <div style={{ marginBottom: 24 }}>
        <Typography category="Default" variant="H6">
          {t("Daily usage")}
        </Typography>
      </div>
      <div>{DataChart}</div>
    </Card>
  );
};
