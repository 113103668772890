import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";
import clsx from "clsx";

export interface IMinimalFooterProps {
  grayColor?: boolean;
  bodyClassName?: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
    height: 76,
    backgroundColor: (props: IMinimalFooterProps) =>
      props.grayColor ? "#EEEEF3" : LightColors.primary["0"],
    [theme.breakpoints.down("sm")]: {
      height: 90,
    },
  },

  body: {
    display: "flex",
    justifyContent: "center",
    padding: `${theme.spacing(3)}px 0`,
    [theme.breakpoints.up("md")]: {
      padding: "31px 24px 24px 32px",
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("sm")]: {
      // paddingTop: "45px",
    },
  },
  anchor: {
    textDecoration: "none",
    marginRight: theme.spacing(3),
    color: LightColors.primary["3"],
    "&:hover": {
      "&>span": {
        color: LightColors.primary["7"],
      },
    },
    "&:visited": {
      color: LightColors.primary["3"],
    },
    "&:last-child": {
      marginRight: 0,
    },
  },
}));

export const MinimalFooter = (props: IMinimalFooterProps) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { bodyClassName } = props;
  return (
    <div className={classes.root}>
      <Container className={clsx(classes.body, bodyClassName)}>
        <a
          className={classes.anchor}
          href="https://blackvue.com/warranty-terms-conditions/"
          target="_blank"
          rel="noreferrer"
        >
          <Typography category="Default" variant="Small">
            {t("Terms & Policies")}
          </Typography>
        </a>
        <a
          className={classes.anchor}
          href="https://helpcenter.blackvue.com/hc/en-us"
          target="_blank"
          rel="noreferrer"
        >
          <Typography category="Default" variant="Small">
            {t("Help")}
          </Typography>
        </a>
      </Container>
    </div>
  );
};
