import createSagaMiddleware from "@redux-saga/core";
import { persistReducer, persistStore, createTransform } from "redux-persist";
import { configureStore } from "@reduxjs/toolkit";
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { all } from "redux-saga/effects";
import storageSession from "redux-persist/lib/storage/session";

import createRootReducer from "./rootReducer";
import { watchUser } from "./User/saga";
import { USER } from "./User/slice";

const persistConfig = {
  key: "pitta-sim",
  whitelist: [USER],
  storage: storageSession,
  transforms: [
    createTransform((inboundState: any, key) => {
      if (key === USER) {
        return {
          ...inboundState,
          userId: undefined,
          init: false,
        };
      }
      return { ...inboundState };
    }),
  ],
};

export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);
const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);
export type RootState = ReturnType<typeof rootReducer>;

function* rootSaga() {
  yield all([watchUser()]);
}

const createStore = () => {
  const sagaMiddleware = createSagaMiddleware({});

  const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: [routerMiddleware(history), sagaMiddleware],
  });
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);
  return { store, persistor };
};

export type AppDispatch = ReturnType<typeof createStore>;
export default createStore;
