import React from "react";
import { LightColors } from "@thingsw/pitta-design-system";

import { makeStyles, Theme } from "@material-ui/core";

import SubscriptionPlan from "../components/SubscriptionPlan";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: LightColors.primary["0"],
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.25, 2, 1),
    flex: 1,
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      padding: theme.spacing(2, 2, 3, 2),
    },
  },
}));

const SubscriptionPlanScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SubscriptionPlan />
    </div>
  );
};

export default SubscriptionPlanScreen;
