import React, { useCallback, useState } from "react";
import { LightColors } from "@thingsw/pitta-design-system";

import { makeStyles, Theme } from "@material-ui/core";

import { IAddSimForm } from "../types";
import AddSIMForm from "../forms/AddSIMForm";
import { useHistory, useRouteMatch } from "react-router-dom";
import { SERVER_URI } from "../contants/Server";

import axios, { AxiosError } from "axios";
import * as yup from "yup";
import { SubmissionError } from "redux-form";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { RootState } from "../features/store";
import { USER } from "../features/User/slice";
import { generateAuthToken } from "../utils/Auth";
import { useQuery } from "../hooks/useQuery";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: LightColors.primary["0"],
    display: "flex",
    minHeight: "calc(var(--vh, 1vh) * 100 - 58px)",
    flexDirection: "column",
    padding: theme.spacing(1.25, 2, 3),
    flex: 1,
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      marginTop: theme.spacing(5),
      padding: theme.spacing(2),
      minHeight: "calc(var(--vh, 1vh) * 100 - 107px - 56px - 76px - 40px)",
    },
  },
  formDiv: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
}));

const schema = yup.object().shape({
  iccid: yup.string().required("Enter ICCID"),
});

const AddSimScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery();
  const { path } = useRouteMatch();
  const { userId, email, token_type, user_token } = useSelector(
    (state: RootState) => state[USER]
  );
  const [loading, setLoading] = useState(false);

  const handleAddSim = useCallback(
    async (addIccid: IAddSimForm) => {
      setLoading(true);

      try {
        await schema.validate(addIccid, { abortEarly: false });
        if (!email || !user_token || !token_type) return;
        const resp = await axios.post(
          `${SERVER_URI}/simcards`,
          {
            iccid: `894642${addIccid.iccid}`,
            userId: userId,
          },
          {
            headers: {
              Authorization: generateAuthToken(email, user_token),
              "X-Token-Type": token_type,
            },
          }
        );

        history.push(
          path === "/add-sim" ? "/subscription" : "/webviewer/subscription",
          {
            simcard: resp.data,
          }
        );
      } catch (err) {
        if (err instanceof yup.ValidationError) {
          const yupError = err as Error;
          throw new SubmissionError({
            iccid: yupError.message,
          });
        } else {
          const axiosError = err as AxiosError;
          let errMsg = "";
          if (axiosError.response) {
            if (axiosError.response.status === 409) {
              errMsg = "This sim card_";
            } else if (
              axiosError.response.status === 400 ||
              axiosError.response.status === 404
            ) {
              errMsg = "Incorrect BlackVue SIM_";
            } else if (axiosError.response.status === 410) {
              errMsg = "This sim card is_";
            }
            throw new SubmissionError({
              iccid: t(errMsg),
            });
          }
        }
      } finally {
        setLoading(false);
      }
    },
    [email, history, path, token_type, userId, user_token]
  );

  return (
    <div className={classes.root}>
      <div className={classes.formDiv}>
        <AddSIMForm
          onSubmit={handleAddSim}
          simPage={path === "/add-sim"}
          loading={loading}
          defaultIccid={query.get("iccid")}
        />
      </div>
    </div>
  );
};

export default AddSimScreen;
