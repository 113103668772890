import { call, put, select, takeLatest } from "redux-saga/effects";
import { RootState } from "../store";
import {
  clearLoginInfo,
  clearProfile,
  getFreetrialState,
  IUserProfile,
  loadSubscription,
  loadUserInfo,
  loadUserProfile,
  logout,
  setUserId,
  successGetFreetrialState,
  successLoadSubscription,
  successLoadUserProfile,
  USER,
} from "./slice";
import * as api from "../../apis/User";
import { Ifreetrial, ISubscriptionInfo, RESULT_CODE } from "../../types";
import { PayloadAction } from "@reduxjs/toolkit";
import { WEBVIEWER_URI } from "../../contants/Server";

function* handleLoadUserInfo() {
  try {
    const { email, user_token, token_type } = yield select(
      (state: RootState) => state[USER]
    );
    //@ts-ignore
    const resp: any = yield call(
      api.loadUserInfo,
      email,
      user_token,
      token_type
    );
    yield put(setUserId(resp.data.id));
  } catch (err) {
    console.error(err);
  }
}

function* handleLoadProfileImg() {
  try {
    const { email, user_token, token_type } = yield select(
      (state: RootState) => state[USER]
    );
    //@ts-ignore
    const res = yield call(api.getProfile, email, user_token, token_type);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: IUserProfile;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadUserProfile(response));
    }
  } catch (err: any) {
    console.log(err.response);
  }
}

function* handleLoadSubscription() {
  try {
    const { email, user_token, token_type } = yield select(
      (state: RootState) => state[USER]
    );
    //@ts-ignore
    const res = yield call(api.getSubscription, email, user_token, token_type);
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: ISubscriptionInfo;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successLoadSubscription(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleLogout({ payload }: PayloadAction<boolean | undefined>) {
  try {
    const { email, user_token, token_type } = yield select(
      (state: RootState) => state[USER]
    );

    yield call(api.logout, email, user_token, token_type);
    localStorage.setItem("PITTA_SESSION_CLEAR", Date.now().toString());
    localStorage.removeItem("PITTA_SESSION_CLEAR");

    if (payload !== false) {
      yield put(clearLoginInfo());
      yield put(clearProfile());

      window.location.href = `${WEBVIEWER_URI}/login`;
    }
  } catch (err) {
    console.log(err);
  }
}

function* handleGetFreetrialState() {
  try {
    const { email, user_token, token_type } = yield select(
      (state: RootState) => state[USER]
    );
    //@ts-ignore
    const res = yield call(
      api.getFreetrialState,
      email,
      user_token,
      token_type
    );
    const { resultcode, response } = res.data as {
      resultcode: RESULT_CODE;
      message: string;
      response: Ifreetrial;
    };
    if (resultcode === "BC_ERR_OK") {
      yield put(successGetFreetrialState(response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchUser() {
  yield takeLatest(loadUserInfo, handleLoadUserInfo);
  yield takeLatest(loadUserProfile, handleLoadProfileImg);
  yield takeLatest(loadSubscription, handleLoadSubscription);
  yield takeLatest(logout, handleLogout);
  yield takeLatest(getFreetrialState, handleGetFreetrialState);
}
