import React, { useMemo } from "react";
import { LightColors } from "@thingsw/pitta-design-system";

import { makeStyles, Theme } from "@material-ui/core";

import { SuccessSubscribed } from "../components/SuccessSubscribed";
import { useRouteMatch } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: LightColors.primary["0"],
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.25, 2, 1),
    flex: 1,
    minHeight: "calc(var(--vh, 1vh) * 100 - 58px)",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      padding: theme.spacing(2, 2, 1.875, 2),
      minHeight: "calc(var(--vh, 1vh) * 100 - 107px - 56px - 76px )",
    },
  },
}));

const SubscribedScreen = () => {
  const classes = useStyles();
  const { path } = useRouteMatch();

  // path값으로 버튼, 안내문구 유무 및 폰트변경
  const webviewerPage = useMemo(() => {
    if (path.startsWith("/webviewer")) {
      return true;
    } else {
      return false;
    }
  }, [path]);

  return (
    <div className={classes.root}>
      <SuccessSubscribed noBtn={webviewerPage} />
    </div>
  );
};

export default SubscribedScreen;
