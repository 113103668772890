import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  DarkColors,
  IconButton,
  LightColors,
  LogoOnly,
  Typography,
} from "@thingsw/pitta-design-system";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { HELPCENTER, SIMGUIDE } from "../contants/Links";
// import { HELPCENTER, HELPCENTER_MOBILE } from "../contants/Links";

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    width: "100%",
  },
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    backgroundColor: DarkColors.primary["0"],
    overflow: "auto",
  },
  header: {
    height: theme.spacing(7),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    padding: theme.spacing(0, 2),
    marginBottom: theme.spacing(2),
    backgroundColor: DarkColors.primary["0"],
    zIndex: 1000,
    borderBottom: `1px solid ${LightColors.primary["7"]}`,
  },
  body: {
    marginTop: theme.spacing(7),
    padding: theme.spacing(2),
  },
  menuItem: {
    height: 36,
    cursor: "pointer",
    paddingLeft: theme.spacing(1),
    marginBottom: theme.spacing(1),
    "&:hover": {
      //hover 색상 확인 후 수정예정
      color: LightColors.primary["8"],
    },
    "&:last-child": {
      marginBottom: 0,
    },
    "& > a": {
      textDecoration: "none",
      display: "flex",
      alignItems: "center",
    },
  },
  anchor: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    color: "inherit",
  },
  buttonRoot: {
    padding: 6,
    marginRight: -6,
  },
  accordionRoot: {
    backgroundColor: DarkColors.primary["0"],
    "&:before": {
      height: 0,
    },
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummaryRoot: {
    minHeight: 36,
    padding: 0,
    paddingLeft: theme.spacing(1),
    justifyContent: "flex-start",
    "&:hover > *": {
      color: LightColors.primary["8"],
    },
    "&.Mui-expanded": {
      minHeight: 36,
    },
  },
  accordionSummaryContent: {
    margin: 0,
    flexGrow: 0,
    "&.Mui-expanded": {
      margin: 0,
    },
  },
  accordionSummaryexpandIcon: {
    padding: 2,
  },
  accordionDetailRoot: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1, 3, 0),
  },
  openInNewIcon: {
    marginLeft: 3,
    color: DarkColors.primary["1"],
    display: "block",
  },
}));

interface NavigationDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const NavigationDrawer = ({ open, onClose }: NavigationDrawerProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Modal
      open={open}
      onClose={onClose}
      classes={{ paper: classes.drawerPaper }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open} timeout={{ enter: 150, exit: 250 }}>
        <div className={classes.root}>
          <div className={classes.header}>
            <Link to="/">
              <LogoOnly fontSize={36} style={{ display: "block" }} />
            </Link>
            <IconButton
              onClick={onClose}
              className={classes.buttonRoot}
              darkColor
            >
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.body}>
            <div
              className={classes.menuItem}
              onClick={() => {
                history.push("/");
                onClose();
              }}
            >
              <Typography
                category="Default"
                variant="Body"
                htmlColor={DarkColors.primary["1"]}
                className={classes.anchor}
              >
                {t("SIM card")}
              </Typography>
            </div>
            <div
              className={classes.menuItem}
              onClick={() => {
                history.push("/pricing");
                onClose();
              }}
            >
              <Typography
                category="Default"
                variant="Body"
                htmlColor={DarkColors.primary["1"]}
                className={classes.anchor}
              >
                {t("Pricing")}
              </Typography>
            </div>
            <Accordion
              square
              elevation={0}
              classes={{
                root: classes.accordionRoot,
              }}
            >
              <AccordionSummary
                classes={{
                  root: classes.accordionSummaryRoot,
                  content: classes.accordionSummaryContent,
                  expandIcon: classes.accordionSummaryexpandIcon,
                }}
                expandIcon={
                  <ExpandMoreIcon
                    fontSize="small"
                    style={{ color: DarkColors.primary["1"] }}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  category="Default"
                  variant="Body"
                  htmlColor={DarkColors.primary["1"]}
                >
                  {t("Support")}
                </Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.accordionDetailRoot }}>
                <div className={classes.menuItem}>
                  <a href={SIMGUIDE} target="_blank" rel="noreferrer">
                    <Typography
                      category="Default"
                      variant="Body"
                      htmlColor={DarkColors.primary["1"]}
                    >
                      {t("SIM guide")}
                    </Typography>
                    <OpenInNewIcon
                      fontSize="small"
                      className={classes.openInNewIcon}
                    />
                  </a>
                </div>
                <div className={classes.menuItem}>
                  <a href={HELPCENTER} target="_blank" rel="noreferrer">
                    <Typography
                      category="Default"
                      variant="Body"
                      htmlColor={DarkColors.primary["1"]}
                    >
                      {t("Help center")}
                    </Typography>

                    <OpenInNewIcon
                      fontSize="small"
                      className={classes.openInNewIcon}
                    />
                  </a>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};
