import { combineReducers } from "@reduxjs/toolkit";
import { connectRouter } from "connected-react-router";
import { reducer as formReducer } from "redux-form";
import userReducer, { USER } from "./User/slice";

const createRootReducer = (history: any) =>
  combineReducers({
    [USER]: userReducer,
    router: connectRouter(history),
    form: formReducer,
  });

export default createRootReducer;
