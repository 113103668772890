import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import {
  Container,
  LightColors,
  Typography,
  IconButton,
} from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";

import { AppBar } from "./AppBar";
import { Toolbar } from "./Toolbar";

import {
  Divider,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
} from "@material-ui/core";

import clsx from "clsx";
import _ from "lodash";

import CloseIcon from "@material-ui/icons/Close";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

interface IAppHeaderProps {
  helperIcon?: boolean;
  closeIcon?: boolean;
  arrowIcon?: boolean;
  activeStep?: number;
  onBack?: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  grow: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },

  //stepper
  stepperDiv: {
    display: "flex",
    padding: (props: IAppHeaderProps) =>
      props.helperIcon ? theme.spacing(0, 1, 0, 2) : theme.spacing(0, 2),
    width: "100%",
  },
  stepperRoot: {
    padding: 0,
    width: "100%",
  },
  horizontal: {
    padding: theme.spacing(0, 0.5),
  },
  stepperIconRoot: {
    fontSize: 22,
    color: `${LightColors.primary["0"]}`,
    border: `2px solid ${LightColors.primary["9"]}`,
    borderRadius: "50%",
  },
  stepperIconActive: {
    fontSize: 20,
    color: `${LightColors.primary["7"]}`,
    border: "none",
  },
  stepperIconCompleted: {
    fontSize: 20,
    color: `${LightColors.primary["7"]}`,
    border: "none",
  },
  stepperIconText: {
    fill: `${LightColors.primary["3"]}`,
  },
  iconContainer: {
    padding: 0,
  },
  labelContainer: {
    whiteSpace: "nowrap",
  },
  stepLabelRoot: {
    width: (props: IAppHeaderProps) =>
      props.helperIcon ? "calc(100vw - 187px)" : "calc(100vw - 145px)",
  },

  dividerBlue: {
    marginTop: -1,
    width: (props: IAppHeaderProps) =>
      props.activeStep === 0
        ? 125
        : props.activeStep === 1
        ? 250
        : props.activeStep === 2
        ? "100%"
        : 0,
    backgroundColor: `${LightColors.primary["7"]}`,
  },
}));

const Steps = ["Add camera", "Connect to Cloud", "Completed!"];

const useStepperConnectStyles = makeStyles((theme: Theme) => ({
  root: {
    width: 8,
    maxWidth: 8,
  },
  alternativeLabel: {
    left: "calc(-50% + 13px)",
    right: "calc(50% + 13px)",
    top: 10,
  },
  active: {
    "& $line": {
      borderColor: `${LightColors.primary["7"]}`,
    },
  },
  completed: {
    "& $line": {
      borderColor: `${LightColors.primary["7"]}`,
    },
  },
  line: {
    borderColor: `${LightColors.primary["9"]}`,
    borderLeftWidth: 2,
    borderWidth: 2,
  },
  vertical: {
    padding: 0,
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(1.125) }
      : { marginLeft: theme.spacing(1.125) }),
  },
  lineVertical: {
    minHeight: 11,
  },
}));

export const AppHeader = (props: IAppHeaderProps) => {
  const classes = useStyles(props);
  const { activeStep, helperIcon, closeIcon, arrowIcon, onBack } = props;
  const { t } = useTranslation();

  const stepperConnectClasses = useStepperConnectStyles();

  return (
    <AppBar color="default">
      <Container>
        <Toolbar app>
          {closeIcon && (
            <IconButton onClick={onBack}>
              <CloseIcon />
            </IconButton>
          )}
          {arrowIcon && (
            <IconButton onClick={onBack}>
              <ArrowBackIosIcon />
            </IconButton>
          )}

          <div className={classes.grow}>
            <div className={classes.stepperDiv}>
              <Stepper
                connector={<StepConnector classes={stepperConnectClasses} />}
                classes={{ root: classes.stepperRoot }}
                activeStep={activeStep}
              >
                {_.map(Steps, (step, index) => (
                  <Step classes={{ horizontal: classes.horizontal }}>
                    <StepLabel
                      classes={{
                        root: clsx({
                          [classes.stepLabelRoot]: activeStep === index,
                        }),
                        labelContainer: clsx({
                          [classes.labelContainer]: activeStep === index,
                        }),
                        iconContainer: clsx({
                          [classes.iconContainer]: activeStep !== index,
                        }),
                      }}
                      StepIconProps={{
                        classes: {
                          root: classes.stepperIconRoot,

                          active: classes.stepperIconActive,
                          completed: classes.stepperIconCompleted,
                          text: clsx({
                            [classes.stepperIconText]: activeStep !== index,
                          }),
                        },
                      }}
                    >
                      {activeStep === index && (
                        <Typography category="Default" variant="Small">
                          {`${t(step)}`}
                        </Typography>
                      )}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>

            {helperIcon && (
              <IconButton style={{ marginRight: -4 }} onClick={() => {}}>
                <HelpOutlineIcon />
              </IconButton>
            )}
          </div>
        </Toolbar>

        <Divider />
        <Divider className={classes.dividerBlue} />
      </Container>
    </AppBar>
  );
};
