export interface PaymentMethod {
  id: number;
  brand: string;
  createdAt: Date;
  expMonth: number;
  expYear: number;
  last4: string;
}

export interface Notification {
  id: number;
  message: string;
  type: string;
  createdAt: string;
}

export interface SubscriptionHistory {
  id: number;
  date: string;
  planName: string;
}

export interface Transaction {
  id: number;
  amount: number;
  brand: string;
  last4: string;
  planName: string;
  url: string;
  paidAt: string;
}

export interface Simcard {
  activatedAt: Date;
  chargedAt: Date;
  terminatedAt?: Date;
  iccid: string;
  id: number;
  imei: string;
  imsi: string;
  msisdn: string;
  pbrExitDate?: Date;
  name?: string;
  paymentId?: string;
  status: "Terminated" | "Activated" | "Paused" | "Deactivated";
  userId: number;
  subscription?: Subscription;
  usages?: Usage[];
  payment_method?: PaymentMethod;
  notifications?: Notification[];
  subscription_histories?: SubscriptionHistory[];
  transactions?: Transaction[];
  trigger_usage?: TriggerUsage;
  blackvueOne?: boolean;
}

export interface Subscription {
  id: number;
  itemId: string;
  priceId: string;
  planName: string;
  periodEnd: Date;
  status: string;
  simcardId: number;
  userId: number;
  paymentId: number;
  cancelAt: Date;
  cancelAtPeriodEnd: boolean;
  canceledAt: Date;
  nextPlan?: string;
  simcard?: Simcard;
  createdAt?: string;
}

export interface Usage {
  id: number;
  date: Date;
  imsi: string;
  msisdn: string;
  simcardId: number;
  download: number;
  upload: number;
  createdAt: string;
}

export interface TriggerUsage {
  id: number;
  date: Date;
  imsi: string;
  msisdn: string;
  simcardId: number;
  usage: number;
  createdAt: string;
}

export interface IAddSimForm {
  iccid: string;
}

export type RESULT_CODE =
  | "BC_ERR_AUTHENTICATION"
  | "BC_ERR_SIGNATURE"
  | "BC_ERR_INVALID_DATA"
  | "BC_ERR_AUTHENTICATION"
  | "BC_ERR_INVALID_PARAMETER"
  | "BC_ERR_NEED_TO_CONFIRM"
  | "BC_ERR_BLACK_LIST"
  | "BC_ERR_SERVER"
  | "BC_ERR_OK"
  | "BC_ERR_DUPLICATED"
  | "BC_ERR_ALREADY_EXIST_FILE"
  | "BC_ERR_ALREADY_EXIST_S3"
  | "BC_ERR_NOTBLACKVUELINKAGE"
  | "BC_ERR_LIMIT_EXCEEDED"
  | "BC_ERR_UNAVAILABLE"
  | "BC_ERR_INTERNAL_ERROR"
  | "401"
  | 400;

export const planAmount: { [key in PlanType]: string } = {
  "1GB": "10.99",
  "3GB": "23.99",
  "5GB": "39.99",
};
export type PlanType = "1GB" | "3GB" | "5GB";

export interface ReferenceObject {
  clientHeight: number;
  clientWidth: number;
  referenceNode?: Node;

  getBoundingClientRect(): ClientRect;
}

export interface ISubscriptionInfo {
  cameraLimit: number;
  cancelDT: moment.Moment;
  cancelled: number;
  customerID: string;
  endOfMonthDT: moment.Moment;
  masterEmail: string;
  paymentMethod: string;
  registered: boolean;
  servicePlanID: number;
  servicePlanName: string;
  startOfMonthDT: moment.Moment;
  tradeType: string;
  userEmail: string;
}

export const getPlanFromServiceID = (id: number) => {
  if (id === 1) {
    return "Free plan";
  } else if (id >= 1001 && id <= 1003) {
    return "Fleet plan";
  } else if (id === 1004) {
    return "Basic plan";
  } else if (id === 1005) {
    return "Smart plan";
  } else if (id === 1998 || id === 1999) {
    return "Fleet plan";
  } else if (id >= 4001 && id <= 4029) {
    return "Fleet plan";
  } else if (id >= 10001 && id <= 10003) {
    return "Smart plan";
  } else if (id >= 20001 && id <= 20999) {
    return "Fleet plan";
  } else if (id >= 30001 && id <= 30999) {
    return "Fleet plan";
  } else if (id >= 50001 && id <= 50999) {
    return "Plus plan";
  }
  return "";
};

export interface Ifreetrial {
  trialUsed: boolean;
}
