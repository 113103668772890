import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Container,
  LightColors,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";

import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";

import {
  CreateTokenCardData,
  loadStripe,
  Stripe,
  StripeCardElement,
} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import * as yup from "yup";
import {
  setSubmitFailed,
  startSubmit,
  stopSubmit,
  SubmissionError,
  submit,
} from "redux-form";
import _ from "lodash";
import BillingForm from "@thingsw/pitta-design-system/dist/components/BillingForm";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { planAmount, PlanType, Simcard } from "../../types";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { SERVER_URI, STRIPE_API_KEY } from "../../contants/Server";
import { generateAuthToken } from "../../utils/Auth";
import moment from "moment";
import { calculateProated } from "../../utils/Subscription";

const schema = yup.object().shape({
  name: yup.string().trim().required("Enter card name"),
  address_line1: yup.string().trim().required("Enter address"),
  address_city: yup.string().trim().required("Enter the city"),
  address_country: yup
    .object()
    .shape({
      key: yup.string(),
      value: yup.string(),
    })
    .required("Select country")
    .test(
      "notSelect",
      "Select country",
      (item: { key?: string; value?: string }) => {
        return item.key && item.key !== "country";
      }
    ),
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: LightColors.primary["0"],
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 906,
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 0,
  },
  title: {
    display: "flex",
  },
  formDiv: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  formTitle: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(5) - 1,
    },
  },

  btnStyle: {
    marginTop: 19,
    paddingRight: theme.spacing(5),
  },
  regionStyle: {
    width: "48.5%",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 13,
      width: "100%",
    },
  },
  zipStyle: {
    width: "48%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  addressDetailStyle: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 44,
    },
  },
  // modal css
  modalContent: {
    padding: theme.spacing(2.125, 1.875, 2.25, 1.875),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(5.375, 4, 2.375, 4),
    },
  },
  modalTitile: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1.25, 1.625, 0, 3),
    },
  },
  modalCloseStyle: {
    [theme.breakpoints.up("sm")]: {
      marginTop: -2,
    },
  },
  modalRButton: {
    minWidth: 125,
  },
  modalAction: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2, 2, 1.375, 2),
    },
  },
  // 설명 css
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  fleetPlan: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    marginLeft: theme.spacing(4),
    padding: theme.spacing(2.125, 2, 3),
    backgroundColor: `${LightColors.primary["6"]}73`,
    borderRadius: 4,
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 0, 3),
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 341,
      marginTop: 2,
      padding: theme.spacing(2, 2, 3, 2),
      marginLeft: 32,
    },
  },
  fleetPrice: {
    textDecorationLine: "line-through",
    textDecorationThickness: 2,
    marginBottom: 4,
    color: LightColors.secondary["17"],
    textAlign: "right",
    display: "flex",
    alignSelf: "flex-end",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 2,
    },
  },
  infoTitle: {
    marginBottom: theme.spacing(1) - 1,
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  beforePlanDiv: {
    paddingBottom: 15,
  },
  afterPlanDiv: {
    paddingBottom: 22,
    [theme.breakpoints.up("sm")]: {
      paddingBottom: 21,
    },
  },
  divider: {
    display: "flex",
    borderBottom: `1px solid ${LightColors.primary["5"]}`,
  },
  planPeriodInfo: {
    marginTop: 1,
  },
  todayPriceDiv: {
    marginBottom: 4,
    paddingTop: 20,
  },
}));

interface IServiceEndBillingModalProps {
  open: boolean;
  onClose?: () => void;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: () => void;
  afterPlan: string;
  currentSimCard?: Simcard;
}

const ServiceEndBillingModal = (props: IServiceEndBillingModalProps) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    onClickNegative,
    onClickPositive,
    currentSimCard,
    afterPlan,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const location = useLocation<{ plan: string; simcard: Simcard }>();
  const { userId, email, user_token, token_type } = useSelector(
    (state: RootState) => state[USER]
  );

  const [loading, setLoading] = useState(false);
  const currentPlan = useMemo(
    () => currentSimCard?.subscription?.planName ?? "1GB",
    [currentSimCard]
  ) as PlanType;

  const today = moment().format("MMM D, YYYY");
  const endDay = moment(today).endOf("month").format("MMM D, YYYY");
  const nextBillingDay = moment(endDay).add(1, "days").format("MMM D, YYYY");

  //billInfo
  const stripePromise = loadStripe(STRIPE_API_KEY);
  const [stripe, setStripe] = useState<Stripe>();
  //   const location = useLocation<any>();

  const [cardElement, setCardElement] = useState<StripeCardElement>();

  const handleSubmit = async (billingData: CreateTokenCardData) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();
    if (!email || !user_token || !token_type) return;
    setLoading(true);
    try {
      await schema.validate(billingData, { abortEarly: false });
      if (!stripe || !cardElement) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      if (cardElement) {
        const resp = await axios.post(
          `${SERVER_URI}/payments`,
          {
            userId,
          },
          {
            headers: {
              Authorization: generateAuthToken(email, user_token),
              "X-Token-Type": token_type,
            },
          }
        );
        const payment = await stripe.confirmCardSetup(resp.data.clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: billingData.name,
              address: {
                line1: billingData.address_line1,
                city: billingData.address_city,
                state: billingData.address_state,
                postal_code: billingData.address_zip,
                //@ts-ignore
                country: billingData.address_country.key,
              },
            },
          },
        });

        if (payment.error) {
          // Show error to your customer (for example, insufficient funds)
          console.log(payment.error.message);
          throw new SubmissionError({
            cardNumber: payment.error.code,
          });
        } else {
          // The payment has been processed!
          if (payment.setupIntent.status === "succeeded") {
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
            const resp = await axios.post(
              `${SERVER_URI}/subscriptions`,
              {
                userId,
                simcardId: currentSimCard?.subscription?.simcardId,
                payment: payment.setupIntent.payment_method,
                plan: currentSimCard?.subscription?.planName,
                nextPlan: afterPlan, // 1GB, 3GB, 5GB
              },
              {
                headers: {
                  Authorization: generateAuthToken(email, user_token),
                  "X-Token-Type": token_type,
                },
              }
            );
            onClickPositive?.();
            console.log("resp", resp.data);
          }
        }
      }
    } catch (err: any) {
      console.error("err", err);
      if (err instanceof SubmissionError) {
        throw err;
      }
      let submssionError = _.reduce(
        err.inner,
        (result, error) => {
          return { ...result, [error.path]: error.errors };
        },
        {}
      );

      //@ts-ignore
      if (!cardElement["_complete"]) {
        submssionError = {
          ...submssionError,
          cardNumber: "incomplete_number",
        };
      }
      throw new SubmissionError({
        ...submssionError,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      // hideBackdrop
      open={open}
      mobile={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={() => {
        dispatch(submit("BillInfoForm"));
      }}
      className={classes.root}
      contentClassName={classes.modalContent}
      titleClassName={classes.modalTitile}
      closeStyle={classes.modalCloseStyle}
      RButtonClassName={classes.modalRButton}
      actionClassName={classes.modalAction}
      heading={mobile ? t("Resubscribe") : t("Resubscribe plan")}
      close
      fullSize={mobile}
      content={
        <Container className={classes.body}>
          <div>
            <div className={classes.title}>
              <Typography
                category="Default"
                variant="H3"
                className={classes.formTitle}
              >
                {t("Billing information")}
              </Typography>
            </div>
            <div className={classes.formDiv}>
              <div style={{ width: mobile ? "100%" : 469 }}>
                <Elements stripe={stripePromise}>
                  <BillingForm
                    mode="change"
                    btnClassName={classes.btnStyle}
                    regionClassName={classes.regionStyle}
                    zipClassName={classes.zipStyle}
                    addressDetailClassName={classes.addressDetailStyle}
                    loading={loading}
                    onSubmit={handleSubmit}
                    onUpdateCardElement={(stripe, cardElement) => {
                      setStripe(stripe as Stripe);
                      setCardElement(cardElement);
                    }}
                    onCardError={(error) => {
                      dispatch(startSubmit("BillInfoForm"));
                      dispatch(
                        stopSubmit("BillInfoForm", { cardNumber: error })
                      );
                      dispatch(setSubmitFailed("BillInfoForm", "cardNumber"));
                    }}
                  />
                </Elements>
              </div>

              {/* fleetPlan 설명 */}
              <div className={classes.fleetPlan}>
                <Typography
                  category="Default"
                  variant="H6"
                  htmlColor={LightColors.primary["1"]}
                  className={classes.infoTitle}
                >
                  {currentPlan} data + {afterPlan} data
                </Typography>

                <div className={classes.marginB3}>
                  <div>
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["2"]}
                      dangerouslySetInnerHTML={{
                        __html: t("Subscribe to n_", {
                          a: `${currentPlan}`,
                          b: `${today}`,
                          c: `${endDay}`,
                          d: `${afterPlan}`,
                          e: `<strong>${nextBillingDay}</strong>`,
                        }),
                      }}
                    ></Typography>
                  </div>
                </div>

                <div>
                  <div
                    className={clsx(classes.flexBetween, classes.beforePlanDiv)}
                  >
                    <div className={classes.flexColumn}>
                      <Typography category="Default" variant="Body">
                        {currentPlan} data plan
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                        className={classes.planPeriodInfo}
                      >
                        ({today} ~ {endDay})
                      </Typography>
                    </div>

                    <Typography
                      category="Default"
                      variant="BodyBold"
                      style={{ textAlign: "right" }}
                    >
                      {calculateProated(currentPlan)} USD/mo
                    </Typography>
                  </div>
                  <div
                    className={clsx(classes.flexBetween, classes.afterPlanDiv)}
                  >
                    <div className={classes.flexColumn}>
                      <Typography category="Default" variant="Body">
                        {afterPlan} data plan
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                        className={classes.planPeriodInfo}
                      >
                        ({nextBillingDay} ~)
                      </Typography>
                    </div>
                    <div className={classes.flexColumn}>
                      <div className={classes.fleetPrice}>
                        <Typography
                          category="Default"
                          variant="BodyBold"
                          htmlColor={LightColors.primary["2"]}
                          style={{ textAlign: "right" }}
                        >
                          {planAmount[afterPlan as PlanType]} USD/mo
                        </Typography>
                      </div>

                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.secondary["17"]}
                        style={{ marginTop: mobile ? -3 : 0 }}
                      >
                        {t("Will be charged_", { a: nextBillingDay })}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.divider}></div>
                  <div
                    className={clsx(classes.flexBetween, classes.todayPriceDiv)}
                  >
                    <Typography category="Default" variant="BodyBold">
                      {t("Due today")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="BodyBold"
                      style={{ textAlign: "right" }}
                    >
                      {calculateProated(currentPlan)} USD
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      }
      LButton={t("Back")}
      RButton={t("subscribe")}
      Secondary={false}
      loading={loading}
    />
  );
};

export default ServiceEndBillingModal;
