import React, { useMemo, useState } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { LightColors, Typography } from "@thingsw/pitta-design-system";
import { useTranslation } from "react-i18next";

import { Bar } from "react-chartjs-2";
import _ from "lodash";
import moment from "moment";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { PlanType, Simcard } from "../types";
import { useMediaQuery } from "@material-ui/core";
import { CancelSubscriptionModal } from "./modals/CancelSubscriptionModal";

export interface IMonthlyUsage {
  month: moment.Moment;
  value: number;
  planName: string;
}

// 같은 비율로 하기 위해 1GB, 3GB 값 지정
export const planData: { [key in PlanType]: number } = {
  "1GB": 1706,
  "3GB": 3413,
  "5GB": 5120,
};

const MONTH_ABBR: { [key: number]: string } = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

export interface IMonthlyUsageGraph {
  monthlyData?: IMonthlyUsage[];
  currentSim?: Simcard;
  onUpdated: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: LightColors.primary["0"],
    minHeight: 328,
    padding: theme.spacing(3, 3, 5, 3),
    borderRadius: 5,
    border: `1px solid ${LightColors.primary["5"]}`,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3, 3.875),
    },
    flex: 1,
  },
  title: {
    marginBottom: theme.spacing(2.5),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(2.5) + 1,
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // width: "100%",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },
  rightDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(4) - 1,
    [theme.breakpoints.between("sm", "md")]: {
      paddingTop: 14,
      marginLeft: 32,
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
      marginLeft: 33,
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 153,
    },
  },
  rightText: {
    marginBottom: theme.spacing(2),
    textAlign: "center",

    [theme.breakpoints.up("lg")]: {
      marginBottom: 20,
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginBottom: 18,
    },
  },
  btnStyle: {
    maxWidth: 184,
    color: LightColors.primary["7"],
    borderColor: LightColors.primary["7"],
    "&:hover": {
      borderColor: LightColors.primary["7"],
    },
    [theme.breakpoints.up("sm")]: {
      maxWidth: "auto",
      margin: theme.spacing(0, 0.75),
    },
    [theme.breakpoints.between("sm", "md")]: {
      maxWidth: 141,
      margin: 0,
    },
  },
  usageBarChartDiv: {
    height: 222,
    marginLeft: -4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    minWidth: 180,
    [theme.breakpoints.up("md")]: {
      marginLeft: -14,
    },
    [theme.breakpoints.between("sm", "md")]: {
      marginLeft: -2,
    },
    [theme.breakpoints.up("sm")]: {},
  },
}));

export const MonthlyUsageGraph = (props: IMonthlyUsageGraph) => {
  const { monthlyData, currentSim, onUpdated } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [openCancelModal, setOpenCancelModal] = useState(false);

  const UsageBarChart = useMemo(() => {
    const month = moment();

    const months = _.range(0, 4)
      .reverse()
      .map((r) => {
        let pmonth = moment().subtract(r, "M");
        const found = _.find(
          monthlyData,
          (s) => s.month.get("M") === pmonth.get("M")
        );
        if (found) {
          let maxValue = 1024;
          if (found.planName === "3GB") {
            maxValue = 3 * 1024;
          } else if (found.planName === "5GB") {
            maxValue = 5 * 1024;
          }

          return {
            ...found,
            tvalue: Math.min(maxValue, found.value),
          };
        }
        return {
          month: pmonth,
          value: undefined,
          planName: 0,
        };
      });

    return (
      <Bar
        type="bar"
        redraw={true}
        data={{
          labels: _.map(months, (d) => MONTH_ABBR[d.month.get("M") + 1]),
          datasets: [
            {
              // 사용한 데이터
              barThickness: 23,
              data: _.map(months, (d) => {
                if (d.value === undefined) {
                  return "";
                } else if (d.value > 3413 && d.planName === "5GB") {
                  return d.value;
                } else if (
                  1072 < d.value &&
                  d.value <= 3413 &&
                  (d.planName === "3GB" || d.planName === "5GB")
                ) {
                  return Math.round(d.value * 1.111);
                } else {
                  return Math.round(d.value * 1.666);
                }
              }),

              //데이터값이 없는 경우이거나 0인경우 투명한 색으로, 해당 월은 보라색으로
              backgroundColor: _.map(months, (d) => {
                return d.value === undefined || d.value === 0
                  ? "transparent"
                  : MONTH_ABBR[d.month.get("M") + 1] ===
                    MONTH_ABBR[month.get("M") + 1]
                  ? `${LightColors.secondary["19"]}`
                  : `${LightColors.primary["7"]}`;
              }),
              hoverBackgroundColor: _.map(months, (d) => {
                return MONTH_ABBR[d.month.get("M") + 1] ===
                  MONTH_ABBR[month.get("M") + 1]
                  ? `${LightColors.secondary["19"]}`
                  : `${LightColors.primary["7"]}`;
              }),
            },
            {
              // 사용 가능한 데이터
              barThickness: 23,
              data: _.map(months, (d) => {
                const planDataAmount = planData[d.planName as PlanType];

                if (d.value === undefined) {
                  return "";
                } else if (d.value > 3413 && d.planName === "5GB") {
                  if (planDataAmount - d.value >= 0) {
                    return planDataAmount - d.value;
                  } else {
                    return 0;
                  }
                } else if (
                  1072 < d.value &&
                  d.value <= 3413 &&
                  (d.planName === "3GB" || d.planName === "5GB")
                ) {
                  if (planDataAmount - Math.round(d.value * 1.111) >= 0) {
                    return planDataAmount - Math.round(d.value * 1.111);
                  } else {
                    return 0;
                  }
                } else {
                  // 데이터 값이 plan GB 보다 높을 때 - 값이 나와
                  // 툴팁 위치가 변동되기 때문에 - 일때 0의 값을 넣음.
                  if (planDataAmount - Math.round(d.value * 1.666) >= 0) {
                    return planDataAmount - Math.round(d.value * 1.666);
                  } else {
                    return 0;
                  }
                }
              }),
              backgroundColor: [`${LightColors.primary["6"]}`],
              hoverBackgroundColor: [`${LightColors.primary["6"]}`],
              // categoryPercentage: 0.3,
              // barPercentage: 0.3,
            },
          ],
        }}
        //@ts-ignore
        plugins={[ChartDataLabels]}
        options={{
          maintainAspectRatio: false,
          responsive: true,
          layout: {
            padding: {
              left: mobile ? 0 : 13,
              top: 25,
            },
          },

          plugins: {
            legend: {
              display: false,
            },
            datalabels: {
              display: function (context: any) {
                return context.datasetIndex === 1 ? true : false;
              },
              align: "end",
              anchor: "end",
              offset: 0,
              padding: { top: 15 },
              color: _.map(months, (v, i) => {
                if (v.value === 0) {
                  return LightColors.primary["4"];
                } else if (i === 3) {
                  return LightColors.secondary["19"];
                } else {
                  return LightColors.primary["7"];
                }
              }),

              formatter: (value: any, context) => {
                // 사용량x -> 표시x , 사용량 0 -> 0MB , 사용량존재 -> 천단위 기준 콤마찍고 출력
                if (months[context.dataIndex].value === undefined) {
                  return "";
                } else if (months[context.dataIndex].value === 0) {
                  return "0 MB";
                } else {
                  let usageText = _.round(
                    months?.[context.dataIndex].value ?? 0
                  );
                  return `${usageText.toLocaleString()} MB`;
                }
              },
            },
            tooltip: {
              // Disable the on-canvas tooltip
              enabled: false,
            },
          },

          scales: {
            yAxes: {
              stacked: true,
              min: 0,
              max: 5120,
              grid: {
                drawBorder: false,
              },
              ticks: {
                // 5120/3으로 계산하여 0, 1GB, 3GB, 5GB사이의 간격 동일하게 맞춤.
                stepSize: 1706,
                callback: function (val: any) {
                  if (val !== 0) {
                    if (Math.floor(val) === 1706) {
                      return "1GB";
                    } else if (Math.floor(val) - 1 === 3412) {
                      return "3GB";
                    } else if (val === 5120) {
                      return "5GB";
                    }
                  }
                },
              },
            },
            xAxes: {
              grid: { display: false },
              offset: true,

              stacked: true,
              ticks: {
                // 해당 월은 검은색

                color: function (val: any) {
                  return val.tick.label === MONTH_ABBR[month.get("M") + 1]
                    ? LightColors.primary["1"]
                    : LightColors.primary["2"];
                },
              },
            },
          },
        }}
      />
    );
  }, [mobile, monthlyData]);

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <Typography category="Default" variant="H6">
          {t("Monthly usage")}
        </Typography>
      </div>

      <div className={classes.body}>
        <div className={classes.usageBarChartDiv}>{UsageBarChart}</div>
        {openCancelModal && currentSim?.subscription && (
          <CancelSubscriptionModal
            open={openCancelModal}
            subscriptionId={currentSim.subscription.id}
            cancelMonth={moment(currentSim?.subscription?.periodEnd)}
            onClose={(cancelled?: boolean) => {
              if (cancelled) {
                onUpdated();
              }
              setOpenCancelModal(false);
            }}
          />
        )}
      </div>
    </div>
  );
};
