import React, { useCallback, useMemo, useState } from "react";
import {
  LightColors,
  Modal,
  RadioButton,
  Typography,
} from "@thingsw/pitta-design-system";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  RadioGroup,
  TextField,
  useMediaQuery,
} from "@material-ui/core";
import moment from "moment";
import axios from "axios";
import { useSelector } from "react-redux";
import { SERVER_URI } from "../../contants/Server";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { generateAuthToken } from "../../utils/Auth";

export interface ModalPorps {
  cancelMonth?: moment.Moment;
  subscriptionId: number;
  onClose?: (cancelled?: boolean) => void;
  open: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 2),
    maxWidth: 672,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      marginTop: theme.spacing(16.25),
      marginBottom: theme.spacing(16.25),
      padding: 0,
    },
  },

  modalBody: {
    maxWidth: 672,
    // maxHeight: 750,
    // height: "100%",
    [theme.breakpoints.up("sm")]: {},
  },

  modalHeading: {
    padding: "11px 16px 12px",
    [theme.breakpoints.up("sm")]: {
      padding: "11px 13px 0px 24px",
    },
  },

  modalContent: {
    display: "flex",
    flexDirection: "column",
    padding: "16px 14px 0 16px",
    [theme.breakpoints.up("sm")]: {
      padding: "19px 24px",
    },
  },

  modalCard: {
    margin: "16px 0px 16px",
    padding: "23px 16px 23px 21px",
    borderRadius: 4,
    backgroundColor: LightColors.secondary["18"],
    [theme.breakpoints.up("sm")]: {
      margin: "24px 0px 16px",
      padding: "24px 24px 22px 29px",
    },
  },

  modalRadioTitle: {
    margin: "48px 0 18px",
    [theme.breakpoints.up("sm")]: {
      margin: "49px 0 19px",
    },
  },

  modalRadio: {
    marginBottom: 6,
    [theme.breakpoints.up("sm")]: {},
  },

  radioFormControl: {
    marginBottom: theme.spacing(0.625),
  },

  titleDiv: {
    ...(theme.direction === "rtl"
      ? { padding: theme.spacing(1.375, 2.875, 0, 1.625) }
      : { padding: theme.spacing(1.375, 1.625, 0, 2.875) }),
  },

  contentWrap: {
    padding: theme.spacing(2, 3, 2, 2),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2),
    },
  },

  contentDiv: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 35,
  },

  radioLabel: {
    marginBottom: 6,
    [theme.breakpoints.up("sm")]: {},
  },

  radioLastLabel: {
    marginBottom: 8,
    [theme.breakpoints.up("sm")]: {
      marginBottom: 8,
    },
  },

  radioTextLabel: {
    width: "fit-content",
    // width: 36,
    display: "flex",
    alignItems: "center",
    marginTop: 5,
    marginRight: 6,
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      marginTop: 2,
    },
  },

  radioBtn: {
    marginLeft: "-5px",
    marginRight: 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 3,
      marginRight: 1,
    },
  },

  radioTextBtn: {
    marginLeft: "-5px",
    marginRight: 1,
    paddingTop: 6,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 3,
      marginRight: 1,
    },
  },

  focused: {
    "& > fieldset.MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px!important",
      borderColor: `${LightColors.primary["7"]}!important`,
    },
  },

  textArea: {
    minWidth: 212,
    width: "100%",
    [theme.breakpoints.up("sm")]: {},
    "&>.MuiOutlinedInput-multiline": {
      padding: "9px 16px 9px 16px",
      [theme.breakpoints.up("sm")]: {},
    },
  },

  infoText: {
    margin: theme.spacing(4.625, 0.75, 1.25, 0.75),
  },

  RBtn: {
    width: 191,
    [theme.breakpoints.up("sm")]: {},
  },

  LBtn: {
    width: 96,
    [theme.breakpoints.up("sm")]: {},
  },

  modalAction: {
    padding: "16px 13px 12px 16px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 24px 24px",
    },
  },
}));

export const CancelSubscriptionModal = (props: ModalPorps) => {
  const { cancelMonth, onClose, open, subscriptionId } = props;
  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { email, user_token, token_type } = useSelector(
    (state: RootState) => state[USER]
  );

  const [cancelType, setCancelType] = useState("");
  const [etcText, setEtcText] = useState("");
  const [loading, setLoading] = useState(false);

  const month = useMemo(
    () => cancelMonth?.format("MMM DD, YYYY"),
    [cancelMonth]
  );
  const nextMonth = useMemo(
    () => moment(cancelMonth).add(30, "days").format("MMM DD, YYYY"),
    [cancelMonth]
  );

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEtcText(value);
  }, []);

  const handleSubscriptionCancel = useCallback(async () => {
    if (!token_type || !email || !user_token) return;
    setLoading(true);
    try {
      await axios.delete(
        `${SERVER_URI}/subscriptions/${subscriptionId}/cancel`,
        {
          headers: {
            Authorization: generateAuthToken(email, user_token),
            "X-Token-Type": token_type,
          },
          data: {
            reason: cancelType === "Etc" ? etcText : cancelType,
          },
        }
      );
      onClose?.(true);
    } catch (err) {
      onClose?.(false);
    } finally {
      setLoading(false);
    }
  }, [
    cancelType,
    email,
    etcText,
    onClose,
    subscriptionId,
    token_type,
    user_token,
  ]);

  return (
    <Modal
      mobile
      className={classes.modalBody}
      open={open}
      onClose={() => onClose?.()}
      titleClassName={classes.modalHeading}
      heading={t("Cancel subscription")}
      contentClassName={classes.modalContent}
      content={
        <>
          <div>
            <Typography category="Default" variant="Body">
              {t("Are you sure_Cancel")}
            </Typography>
          </div>
          <div className={classes.modalCard}>
            <span style={{ display: "flex" }}>
              <div style={{ paddingTop: 3 }}>ㆍ</div>
              <div style={{ marginLeft: 5, marginBottom: 1 }}>
                <Typography
                  category="Default"
                  variant="Body"
                  dangerouslySetInnerHTML={{
                    __html: t("Your current SIM_", {
                      a: `<strong>${month}</strong>`,
                    }),
                  }}
                ></Typography>
              </div>
            </span>
            <span style={{ display: "flex", marginTop: 16 }}>
              <div style={{ paddingTop: 3 }}>ㆍ</div>
              <div style={{ marginLeft: 5, marginBottom: 1 }}>
                <Typography category="Default" variant="Body">
                  {t("After canceling your_")}
                </Typography>
              </div>
            </span>
          </div>
          <div>
            <Typography
              category="Default"
              variant="Small"
              htmlColor={LightColors.primary["2"]}
              dangerouslySetInnerHTML={{
                __html: t("You can resubscribe_", {
                  a: `<strong>${nextMonth}</strong>`,
                }),
              }}
            ></Typography>
          </div>
          <div>
            <div className={classes.modalRadioTitle}>
              <Typography category="Default" variant="H6">
                {t("Reason to cancel_")}
              </Typography>
            </div>
            <div className={classes.contentDiv}>
              <FormControl className={classes.radioFormControl}>
                <RadioGroup
                  value={cancelType}
                  onChange={(e) => setCancelType(e.target.value)}
                >
                  <RadioButton
                    className={classes.radioBtn}
                    value="Found an alternative"
                    labelClassName={classes.radioLabel}
                    label={
                      <>
                        <Typography category="Default" variant="Body">
                          {t("Found an alternative")}
                        </Typography>
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={LightColors.primary["2"]}
                        ></Typography>
                      </>
                    }
                  />
                  <RadioButton
                    className={classes.radioBtn}
                    value="Too expensive"
                    labelClassName={classes.radioLabel}
                    label={
                      <>
                        <Typography category="Default" variant="Body">
                          {t("Too expensive")}
                        </Typography>
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={LightColors.primary["2"]}
                        ></Typography>
                      </>
                    }
                  />
                  <RadioButton
                    className={classes.radioBtn}
                    value="Not required anymore"
                    labelClassName={classes.radioLabel}
                    label={
                      <>
                        <Typography category="Default" variant="Body">
                          {t("Not required anymore")}
                        </Typography>
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={LightColors.primary["2"]}
                        ></Typography>
                      </>
                    }
                  />
                  <RadioButton
                    className={classes.radioBtn}
                    value="Quality issue"
                    labelClassName={classes.radioLastLabel}
                    label={
                      <>
                        <Typography category="Default" variant="Body">
                          {t("Quality issue")}
                        </Typography>
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={LightColors.primary["2"]}
                        ></Typography>
                      </>
                    }
                  />
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <RadioButton
                      className={classes.radioTextBtn}
                      value="Etc"
                      labelClassName={classes.radioLabel}
                      label={
                        <div className={classes.radioTextLabel}>
                          <Typography category="Default" variant="Body">
                            {t("etc_")}
                          </Typography>
                        </div>
                      }
                    />
                    <TextField
                      className={classes.textArea}
                      id="outlined-textarea"
                      placeholder={t("Please describe in_")}
                      multiline
                      variant="outlined"
                      rows={1}
                      value={etcText}
                      onChange={handleChange}
                      name="etcText"
                      InputProps={{
                        classes: {
                          focused: classes.focused,
                        },
                      }}
                    />
                  </div>
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </>
      }
      actionClassName={classes.modalAction}
      RButtonClassName={classes.RBtn}
      RButton={t("Cancel subscription")}
      LButtonClassName={classes.LBtn}
      LButton={t("Undo")}
      loading={loading}
      onClickNegative={() => onClose?.()}
      RButtonDisabled={
        cancelType === "" || (cancelType === "Etc" && etcText === "")
      }
      onClickPositive={handleSubscriptionCancel}
      close
      fullSize={mobile}
      Secondary={true}
    />
  );
};
