import { makeStyles, Theme, useTheme, useMediaQuery } from "@material-ui/core";
import { Elements } from "@stripe/react-stripe-js";
import {
  loadStripe,
  Stripe,
  StripeCardElement,
  CreateTokenCardData,
} from "@stripe/stripe-js";
import { LightColors, Modal } from "@thingsw/pitta-design-system";
import axios from "axios";
import _ from "lodash";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  setSubmitFailed,
  startSubmit,
  stopSubmit,
  SubmissionError,
  submit,
} from "redux-form";
import * as yup from "yup";
import { SERVER_URI, STRIPE_API_KEY } from "../../contants/Server";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import BillingForm from "@thingsw/pitta-design-system/dist/components/BillingForm";
import { Simcard } from "../../types";
import { generateAuthToken } from "../../utils/Auth";

const schema = yup.object().shape({
  name: yup.string().trim().required("Enter card name"),
  address_line1: yup.string().trim().required("Enter address"),
  address_city: yup.string().trim().required("Enter the city"),
  address_country: yup
    .object()
    .shape({
      key: yup.string(),
      value: yup.string(),
    })
    .required("Select country")
    .test(
      "notSelect",
      "Select country",
      (item: { key?: string; value?: string }) => {
        return item.key && item.key !== "country";
      }
    ),
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: LightColors.primary["0"],
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 439,
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 0,
  },
  title: {
    display: "flex",
  },
  formTitle: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(5) - 1,
    },
  },

  btnStyle: {
    marginTop: 19,
    paddingRight: theme.spacing(5),
  },
  // mantis - 8396 디자인 변경으로 인한 수정
  zipStyle: {
    marginBottom: theme.spacing(3),
  },
  // modal css
  modalContent: {
    padding: theme.spacing(3, 1.875, 0, 1.875),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(3.5, 3, 0, 3),
    },
  },
  modalTitile: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(1.25, 1.625, 0, 3),
    },
  },
  modalCloseStyle: {
    [theme.breakpoints.up("sm")]: {
      marginTop: -2,
    },
  },
  modalRButton: {
    minWidth: 125,
  },
  modalAction: {
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0, 2, 1.375, 2),
    },
  },
  // 설명 css
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
}));

interface ChangePaymentModalProps {
  open: boolean;
  onClose?: () => void;
  onClickNegative?: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive?: () => void;
  currentSimCard?: Simcard;
}

const ChangePaymentModal = (props: ChangePaymentModalProps) => {
  const classes = useStyles();
  const { open, onClose, onClickNegative, onClickPositive, currentSimCard } =
    props;
  const theme = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  // const location = useLocation<{ plan: string; simcard: Simcard }>();
  const { userId, email, user_token, token_type } = useSelector(
    (state: RootState) => state[USER]
  );

  const [loading, setLoading] = useState(false);

  //billInfo
  const stripePromise = loadStripe(STRIPE_API_KEY);
  const [stripe, setStripe] = useState<Stripe>();
  //   const location = useLocation<any>();

  const [cardElement, setCardElement] = useState<StripeCardElement>();

  const handleSubmit = async (billingData: CreateTokenCardData) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    // event.preventDefault();
    if (!email || !user_token || !token_type) return;
    setLoading(true);
    try {
      await schema.validate(billingData, { abortEarly: false });
      if (!stripe || !cardElement) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      if (cardElement) {
        const resp = await axios.post(
          `${SERVER_URI}/payments`,
          {
            userId,
          },
          {
            headers: {
              Authorization: generateAuthToken(email, user_token),
              "X-Token-Type": token_type,
            },
          }
        );
        const payment = await stripe.confirmCardSetup(resp.data.clientSecret, {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: billingData.name,
              address: {
                line1: billingData.address_line1,
                city: billingData.address_city,
                state: billingData.address_state,
                postal_code: billingData.address_zip,
                //@ts-ignore
                country: billingData.address_country.key,
              },
            },
          },
        });

        if (payment.error) {
          // Show error to your customer (for example, insufficient funds)
          console.log(payment.error.message);
          throw new SubmissionError({
            cardNumber: payment.error.code,
          });
        } else {
          // The payment has been processed!
          if (payment.setupIntent.status === "succeeded") {
            // Show a success message to your customer
            // There's a risk of the customer closing the window before callback
            // execution. Set up a webhook or plugin to listen for the
            // payment_intent.succeeded event that handles any business critical
            // post-payment actions.
            const resp = await axios.put(
              `${SERVER_URI}/subscriptions/${currentSimCard?.subscription?.id}/payment`,
              {
                payment: payment.setupIntent.payment_method,
              },
              {
                headers: {
                  Authorization: generateAuthToken(email, user_token),
                  "X-Token-Type": token_type,
                },
              }
            );
            onClickPositive?.();
            console.log("resp", resp.data);
          }
        }
      }
    } catch (err: any) {
      console.error("err", err);
      if (err instanceof SubmissionError) {
        throw err;
      }
      let submssionError = _.reduce(
        err.inner,
        (result, error) => {
          return { ...result, [error.path]: error.errors };
        },
        {}
      );

      //@ts-ignore
      if (!cardElement["_complete"]) {
        submssionError = {
          ...submssionError,
          cardNumber: "incomplete_number",
        };
      }
      throw new SubmissionError({
        ...submssionError,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      mobile={mobile}
      fullSizeSub={mobile}
      onClose={onClose}
      onClickNegative={onClickNegative}
      onClickPositive={() => {
        dispatch(submit("BillInfoForm"));
      }}
      className={classes.root}
      contentClassName={classes.modalContent}
      titleClassName={classes.modalTitile}
      closeStyle={classes.modalCloseStyle}
      actionClassName={classes.modalAction}
      heading={t("Change payment method")}
      close
      content={
        <div>
          <Elements stripe={stripePromise}>
            <BillingForm
              mode="change"
              btnClassName={classes.btnStyle}
              zipClassName={classes.zipStyle}
              loading={loading}
              onSubmit={handleSubmit}
              onUpdateCardElement={(stripe, cardElement) => {
                setStripe(stripe as Stripe);
                setCardElement(cardElement);
              }}
              onCardError={(error) => {
                dispatch(startSubmit("BillInfoForm"));
                dispatch(stopSubmit("BillInfoForm", { cardNumber: error }));
                dispatch(setSubmitFailed("BillInfoForm", "cardNumber"));
              }}
            />
          </Elements>
        </div>
      }
      LButton={t("Cancel")}
      RButton={t("Change")}
      Secondary={false}
      loading={loading}
    />
  );
};

export default ChangePaymentModal;
