import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Ifreetrial, ISubscriptionInfo } from "../../types";

export type UserType = "Etc" | "Master" | "SubMaster" | "User";

export interface IUserProfile {
  email: string;
  firstName: string;
  lastName: string;
  userType?: UserType;
  subMaster?: string;
  user?: string;
  pwLastChangedDT: string;
  profilePath?: string;
}

interface IState {
  init: boolean;
  loading: boolean;
  email?: string;
  user_token?: string;
  token_type?: string;
  userId?: number;
  userProfile?: IUserProfile;
  subscriptionInfo?: ISubscriptionInfo;
  freetrial?: Ifreetrial;
}

let initialState: IState = {
  init: false,
  loading: false,
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearLoginInfo: (state) => {
      state.email = undefined;
      state.user_token = undefined;
      state.token_type = undefined;
    },
    clearProfile: (state) => {
      state.userProfile = undefined;
      state.subscriptionInfo = undefined;
    },
    doneInit: (state) => {
      state.init = true;
    },
    setUserId: (state, { payload }: PayloadAction<number>) => {
      state.userId = payload;
    },
    setUserInfo: (
      state,
      {
        payload,
      }: PayloadAction<{
        email: string;
        user_token: string;
        token_type: string;
      }>
    ) => {
      state.init = true;
      state.email = payload.email;
      state.user_token = payload.user_token;
      state.token_type = payload.token_type;
    },
    loadUserInfo: (state) => {},
    loadUserProfile: (state) => {
      state.loading = true;
    },
    successLoadUserProfile: (
      state,
      { payload }: PayloadAction<IUserProfile>
    ) => {
      state.loading = false;
      state.userProfile = payload;
    },
    loadSubscription: (state, action: PayloadAction) => {
      state.loading = true;
    },
    successLoadSubscription: (
      state,
      { payload }: PayloadAction<ISubscriptionInfo>
    ) => {
      state.loading = false;
      state.subscriptionInfo = payload;
    },
    getFreetrialState: (state, action: PayloadAction) => {
      state.loading = true;
    },
    successGetFreetrialState: (
      state,
      { payload }: PayloadAction<Ifreetrial>
    ) => {
      state.loading = false;
      state.freetrial = payload;
    },
    clearLoading: (state) => {
      state.loading = false;
    },
    logout: (state, _action: PayloadAction<boolean | undefined>) => {
      state.loading = false;
    },
  },
});

export const {
  clearLoginInfo,
  doneInit,
  setUserInfo,
  loadUserInfo,
  setUserId,
  clearLoading,
  loadUserProfile,
  successLoadUserProfile,
  loadSubscription,
  successLoadSubscription,
  logout,
  clearProfile,
  getFreetrialState,
  successGetFreetrialState,
} = slice.actions;
export const USER = slice.name;
export default slice.reducer;
