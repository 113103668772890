import axios from "axios";
import {
  API_GATEWAY_URI,
  API_SERVER_URI,
  SERVER_URI,
} from "../contants/Server";
import { generateAuthToken } from "../utils/Auth";

export const loadUserInfo = (email: string, token: string, type: string) => {
  return axios.get(`${SERVER_URI}/users`, {
    headers: {
      Authorization: generateAuthToken(email, token),
      "X-Token-Type": type,
    },
  });
};

export const getProfile = (email: string, userToken: string, type: string) => {
  return axios.get(
    `${API_GATEWAY_URI}/Account/GetProfile?email=${email}&userToken=${userToken}&tokenType=${type}`
  );
};

export const getSubscription = (
  email: string,
  userToken: string,
  type: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Payment/GetSubscrInfo?email=${email}&user_token=${userToken}&tokenType=${type}`
  );
};

export const getFreetrialState = (
  email: string,
  user_token: string,
  type: string
) => {
  return axios.get(
    `${API_GATEWAY_URI}/Payment/GetCheckSubscrTrial?email=${email}&user_token=${user_token}&tokenType=${type}`
  );
};

export const logout = (email: string, userToken: string, type: string) => {
  return axios.get(
    `${API_SERVER_URI}/BCS/userLogout.php?email=${email}&userToken=${userToken}&tokenType=${type}`
  );
};
