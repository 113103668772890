import React from "react";
import MuiToolbar from "@material-ui/core/Toolbar";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: (props: ToolbarProps) => (props.simHeader ? "stretch" : ""),

    [theme.breakpoints.up("md")]: {
      // alignItems: "stretch",
    },
  },
  regular: {
    minHeight: (props: ToolbarProps) => (props.app ? 47 : 56),
  },
  gutters: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: (props: ToolbarProps) =>
        props.app ? theme.spacing(1.5) : theme.spacing(2),
      paddingRight: (props: ToolbarProps) =>
        props.app ? theme.spacing(1.5) : theme.spacing(2),
    },
  },
}));

interface ToolbarProps {
  simHeader?: boolean;
  children?: React.ReactNode;
  app?: boolean;
}

export const Toolbar = (props: ToolbarProps) => {
  const theme = useTheme() as Theme;
  const classes = useStyles(props);
  return <MuiToolbar classes={classes} dir={theme.direction} {...props} />;
};
