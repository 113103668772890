import React, { useEffect, useMemo, useState } from "react";
import {
  LightColors,
  Link,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import moment from "moment";
import _ from "lodash";
import { detect } from "detect-browser";
import { ScrollBar } from "../ScrollBar";
import { Transaction } from "../../types";
import axios from "axios";
import { useSelector } from "react-redux";
import { SERVER_URI } from "../../contants/Server";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { generateAuthToken } from "../../utils/Auth";

export interface InvoiceProps {
  openModal: boolean;
  onClose: () => void;
  simcardId?: number;
}

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 2),
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      marginTop: theme.spacing(16.25),
      marginBottom: theme.spacing(16.25),
      padding: 0,
    },
  },

  modalBody: {
    overflow: "hidden",
    [theme.breakpoints.up("sm")]: {
      maxWidth: 688,
      padding: "10px 23px 32px",
    },
  },

  modalHeader: {
    padding: "11.5px 16px",
    [theme.breakpoints.up("sm")]: {
      padding: "0 0 18px",
    },
  },

  modalContent: {
    overflow: "auto",
    padding: 16,
    paddingTop: 0,
    marginTop: 16,
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      padding: 0,
      display: "revert",
      overflowY: "hidden",
      marginTop: 0,
    },
  },

  modalContentAndroid: {
    overflow: "hidden",
    padding: 16,
    paddingTop: 0,
    marginTop: 16,
    backgroundColor: "white",
  },

  headerRow: {
    [theme.breakpoints.up("sm")]: {},
  },

  headerCell: {
    padding: "8px 0px 6px 8px",
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {},
  },

  bodyRow: {
    [theme.breakpoints.up("sm")]: {},
  },

  bodyCell: {
    padding: "6px 0px 6px 8px ",
    width: "auto",
    [theme.breakpoints.up("sm")]: {},
  },

  scrollPaper: {
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },

  bodyHeight: {
    height: "100%",
    maxHeight: "calc(100vh - 55px - 39px)",
    overflowY: "auto",
    overflowX: "visible",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "calc(100vh - 108px - 39px)",
    },
  },
  scrollbar: {
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "min(calc(var(--vh, 1vh) * 100 - 121px), 600px)",
    },
  },
}));

export const InvoiceModal = (props: InvoiceProps) => {
  const { openModal, simcardId, onClose } = props;
  const theme = useTheme() as Theme;
  const classes = useStyles();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const { email, user_token, token_type } = useSelector(
    (state: RootState) => state[USER]
  );

  const [transactions, setTransactions] = useState<Transaction[]>([]);

  const isAndroid = useMemo(() => {
    const browser = detect();
    return browser?.name === "chromium-webview";
  }, []);

  useEffect(() => {
    const getTransactions = async (
      id: number,
      email: string,
      user_token: string,
      token_type: string
    ) => {
      const resp = await axios.get(
        `${SERVER_URI}/simcards/${id}/transactions`,
        {
          headers: {
            Authorization: generateAuthToken(email, user_token),
            "X-Token-Type": token_type,
          },
        }
      );
      setTransactions(resp.data);
    };
    if (simcardId && email && user_token && token_type) {
      getTransactions(simcardId, email, user_token, token_type);
    }
  }, [email, simcardId, token_type, user_token]);

  const tableMarkup2 = useMemo(() => {
    return (
      <ScrollBar className={classes.scrollbar}>
        <Table stickyHeader style={{ width: 688, maxHeight: 37 }}>
          <TableHead>
            <TableRow className={classes.headerRow}>
              <TableCell className={classes.headerCell} style={{ width: 151 }}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Date")}
                </Typography>
              </TableCell>
              <TableCell className={classes.headerCell} style={{ width: 86 }}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Plan")}
                </Typography>
              </TableCell>
              <TableCell className={classes.headerCell} style={{ width: 94 }}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Amount, USD")}
                </Typography>
              </TableCell>
              <TableCell className={classes.headerCell} style={{ width: 151 }}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Method")}
                </Typography>
              </TableCell>
              <TableCell className={classes.headerCell} style={{ width: 101 }}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["2"]}
                >
                  {t("Invoice")}
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_.chain(transactions)
              .sortBy((tr) => moment.utc(tr.paidAt).unix())
              .reverse()
              .map((tr) => (
                <TableRow className={classes.bodyRow}>
                  <TableCell
                    className={classes.bodyCell}
                    style={{ width: "25.5%" }}
                  >
                    <Typography category="Default" variant="Body">
                      {moment.utc(tr.paidAt).format("MMM D, YYYY h:mm A")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.bodyCell}
                    style={{ width: "15.1%" }}
                  >
                    <Typography category="Default" variant="Body">
                      {tr.planName} {t("plan")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.bodyCell}
                    style={{ width: "16.5%" }}
                  >
                    <Typography category="Default" variant="Body">
                      {_.round(tr.amount / 100, 2).toFixed(2)}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.bodyCell}
                    style={{ width: "25.6%" }}
                  >
                    <Typography category="Default" variant="Body">
                      {`${_.capitalize(tr.brand)} ***${tr.last4}`}
                    </Typography>
                  </TableCell>
                  <TableCell className={classes.bodyCell} style={{ width: 88 }}>
                    <Link
                      category="Default"
                      variant="Body"
                      mode="anchor"
                      onClick={() => {
                        window.open(tr.url, "_blank");
                      }}
                    >
                      {t("Invoice")}
                    </Link>
                  </TableCell>
                </TableRow>
              ))
              .value()}
          </TableBody>
        </Table>
      </ScrollBar>
    );
  }, [
    classes.bodyCell,
    classes.bodyRow,
    classes.headerCell,
    classes.headerRow,
    classes.scrollbar,
    t,
    transactions,
  ]);

  return (
    <Modal
      scrollPaperClassName={classes.scrollPaper}
      className={classes.modalBody}
      open={openModal}
      close
      onClose={onClose}
      titleClassName={classes.modalHeader}
      heading={t("Invoice")}
      contentClassName={
        isAndroid ? classes.modalContentAndroid : classes.modalContent
      }
      content={tableMarkup2}
      fullSize={mobile}
    />
  );
};
