import React from "react";
import { ThemeProvider } from "@material-ui/core";
import { LightColors, theme } from "@thingsw/pitta-design-system";
import { history } from "./features/store";
import { ConnectedRouter } from "connected-react-router";
import { Route, Switch } from "react-router-dom";
import { detect } from "detect-browser";

import { SimRoutes } from "./routes/simRoutes";
import { WebviewerRoutes } from "./routes/webviewerRoutes";
import { WEBVIEWER_URI } from "./contants/Server";
import axios from "axios";
import { connect } from "react-redux";
import { clearLoginInfo } from "./features/User/slice";
import { withTranslation } from "react-i18next";

class App extends React.Component {
  constructor(props: any) {
    super(props);
    const dispatch = props.dispatch;
    const onRejected = (error: any) => {
      console.log("onRejected");
      if (error.response?.status === 401) {
        dispatch(clearLoginInfo());
        const browser = detect();
        if (browser?.name === "ios-webview") {
          //@ts-ignore
          return webkit.messageHandlers.logout.postMessage({});
        }
        //@ts-ignore
        if (
          browser?.name === "chromium-webview" &&
          //@ts-ignore
          window.Sim?.logout
        ) {
          //@ts-ignore
          return window.Sim.logout();
        }
        // 8732 - 로그인 안되어있을 경우, 링크에 포함된 요청자 메일을 포함하도록 수정
        const params = new URLSearchParams(window.location.search);
        params.append("from", "sim");
        params.append(
          "returnTo",
          window.location.origin + window.location.pathname
        );
        window.location.href = `${WEBVIEWER_URI}/login?${params.toString()}`;
      }
      return Promise.reject(error);
    };
    axios.defaults.timeout = 30 * 1000;
    axios.interceptors.response.use(undefined, onRejected);
    // console.log("constructor", props, history.location);

    const params = new URLSearchParams(history.location.search);
    const savedLang = localStorage.getItem("pitta-sim-frontend:lang");
    const lang = params.get("lang") || savedLang || "en";

    if (lang === "zh") {
      props.i18n.changeLanguage("sc");
    } else {
      props.i18n.changeLanguage(lang);
    }

    localStorage.setItem("pitta-sim-frontend:lang", lang);
  }

  render() {
    return (
      <ThemeProvider
        theme={theme({
          // fontFamily,
          // dir: direction,
          Colors: LightColors,
          breakpoints: {
            values: { xs: 0, sm: 662, md: 960, lg: 1280, xl: 1920 },
          },
        })}
      >
        <ConnectedRouter history={history}>
          <Switch>
            <Route path="/webviewer">
              <WebviewerRoutes />
            </Route>
            <Route path="/">
              <SimRoutes />
            </Route>
          </Switch>
        </ConnectedRouter>
      </ThemeProvider>
    );
  }
}
const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: any) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(App));
