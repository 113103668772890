import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button, LightColors, Typography } from "@thingsw/pitta-design-system";

import {
  makeStyles,
  Divider,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import CheckIcon from "@material-ui/icons/Check";
import { CSTeamEmail } from "../contants/Links";
import clsx from "clsx";
import moment from "moment";
import { planAmount, Simcard } from "../types";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { calculateProated } from "../utils/Subscription";

export interface ISubscriptionPlanProps {
  PBRDate?: string;
  billingDate?: string;
}
const useStyles = makeStyles((theme: Theme) => ({
  formDiv: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(6.625),
      // width: 502,
    },
  },
  title: {
    marginBottom: theme.spacing(4.875),
    marginTop: theme.spacing(1.375),
    [theme.breakpoints.up("sm")]: {
      maxWidth: 502,
      marginTop: 0,
      marginBottom: theme.spacing(5) + 2,
    },
  },
  topContent: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(4) - 1,
    },
  },
  box2Wrap: {
    backgroundColor: `${LightColors.primary["10"]}`,
    borderRadius: 4,
    padding: theme.spacing(3, 4),
    marginBottom: theme.spacing(1),
    minHeight: 439,
    [theme.breakpoints.up("sm")]: {
      width: "100%",
      display: "flex",
      padding: theme.spacing(3, 4),
      minHeight: 310,
      flexDirection: "column",
      alignItems: "center",
    },
  },
  box2WrapBonusDays: {
    minHeight: 392,
    [theme.breakpoints.up("sm")]: {
      minHeight: 277,
    },
  },
  box2WrapNoFree: {
    minHeight: 432,
    [theme.breakpoints.up("sm")]: {
      minHeight: 277,
    },
  },
  planPriceDetailDiv: {
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1.25, 1, 0),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 0.75),
    },
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  MT3UP: {
    [theme.breakpoints.up("sm")]: {
      marginTop: -3,
    },
  },
  bottomText: {
    margin: theme.spacing(0.25, 2, 4.75),
    lineHeight: "16px",
    [theme.breakpoints.up("sm")]: {
      lineHeight: "auto",
      maxWidth: 502,
    },
  },
  fleetPrice: {
    textDecorationLine: "line-through",
    color: LightColors.primary["3"],
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      alignSelf: "flex-end",
      textAlign: "right",
    },
  },
  noDecoLine: {
    textDecorationLine: "unset",
    color: LightColors.primary["1"],
  },
  freePrice: {
    textAlign: "left",
    marginTop: theme.spacing(1.375),
    // mantis - 8366, 다국어 번역시 다른 ui를 침범하여 해당 문구는 한줄로 수정 (Leehj)
    whiteSpace: "nowrap",
  },
  nextBtn: {
    marginTop: theme.spacing(6.75),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginTop: theme.spacing(5) - 4,
      marginBottom: 0,
    },
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  //첫번째 박스 css
  box1Wrap: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      width: "fit-content",
      alignItems: "flex-start",
    },
  },
  box1Div: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    backgroundColor: `${LightColors.primary["10"]}`,
    borderRadius: 4,
    padding: theme.spacing(3),
    [theme.breakpoints.up("sm")]: {},
  },
  priceBoxDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      width: "unset",
    },
  },
  priceInfoDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    borderRadius: 4,
    border: `1px solid ${LightColors.primary["9"]}`,
    backgroundColor: LightColors.primary["0"],
    padding: theme.spacing(1.75, 3),
    [theme.breakpoints.up("sm")]: {
      width: "fit-content",
      padding: theme.spacing(1.75, 2.25, 1.5),
      minWidth: 210,
      display: "block",
    },
  },
  bonusDays0Div: {
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2, 2.25, 2.25),
      minWidth: 210,
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  },
  bonusBlueWrap: {
    position: "relative",
    width: "100%",
    marginBottom: theme.spacing(0.25),
    [theme.breakpoints.up("sm")]: {
      width: "auto",
      marginBottom: 0,
    },
  },
  bonusDaysInfoDiv: {
    whiteSpace: "nowrap",
    borderRadius: 4,
    border: `1px solid ${LightColors.primary["9"]}`,
    backgroundColor: LightColors.primary["0"],
    padding: theme.spacing(2.25, 3, 1.75, 3),
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2.25, 3.5, 1.625, 3),
      textAlign: "inherit",
    },
  },
  dataText: {
    marginBottom: theme.spacing(1) - 1,
    [theme.breakpoints.up("sm")]: {
      width: "fit-content",
      marginBottom: theme.spacing(1),
    },
  },
  dataTextBonusDays0: {
    marginBottom: theme.spacing(1) - 1,
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  plus: {
    margin: theme.spacing(0.625, 1.625, 1.125, 1.5),
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 1.625, 0, 1.5),
    },
  },
  bonusBlueBox: {
    position: "absolute",
    top: -12,
    left: 8,
    display: "flex",
    backgroundColor: LightColors.primary["7"],
    color: LightColors.primary["0"],
    padding: theme.spacing(0.5, 1),
    borderRadius: 4,
  },

  //체크리스트 css
  checkListDiv: {
    margin: theme.spacing(2, 0, 5),
    paddingLeft: theme.spacing(0.25),
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(0, 0.375),
    },
  },
  checkIconDiv: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    "&:last-child": {
      marginBottom: 0,
    },
  },
  checkIcon: {
    fontSize: 18,
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
  },

  // 하단 박스 css
  flexCenter: {
    display: "flex",
    justifyContent: "center",
    height: "fit-content",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      height: "auto",
    },
  },

  // 하단 설명 css
  csTeamEmail: {
    textDecoration: "none",
    color: LightColors.primary["7"],
  },
  liSpan: {
    ...(theme.direction === "rtl"
      ? { margin: "0 -13px 0 0" }
      : { margin: "0 0 0 -13px" }),
  },
  ul: {
    padding: "0px 1px",
    margin: "8px 0 0 6px",
    [theme.breakpoints.up("sm")]: {
      margin: "9px 0 0 6px",
    },

    "& li": {
      listStylePosition: "outside",
      listStyleType: "none",
      marginTop: 1,
      marginLeft: theme.spacing(1.125),
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1.375),
        ...(theme.direction === "rtl"
          ? { margin: "0px 4px 0 0" }
          : { margin: "0px 0 0 4px" }),
      },
    },
    [theme.breakpoints.up("sm")]: {
      margin: "9px 0 0 4px",
    },
  },

  // 타임라인 css
  timeLineWrap: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      width: "fit-content",
    },
  },
  timeLineDiv: {
    position: "relative",
    transform: "scaleX(1) scaleY(-1) translateX(-50%) rotate(270deg)",
    top: 0,
    // mantis - 8400, 왼쪽으로 ui치우쳐져있는 추가 이슈 수정 (Leehj)
    left: "65%",
    transformOrigin: "left bottom",
    [theme.breakpoints.up("sm")]: {
      transform: "rotate(0deg)",
      display: "block",
      top: 0,
      right: 0,
      left: "unset",
    },
    [theme.breakpoints.down(380)]: {
      left: "70%",
    },
    [theme.breakpoints.down(330)]: {
      left: "80%",
    },
  },
  timeLineDivBonusDays0: {
    left: "50%",
    [theme.breakpoints.up("sm")]: {
      left: "unset",
    },
  },
  paper: {
    padding: theme.spacing(1.25, 1.75, 2),
    borderRadius: 4,
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: `2px solid ${LightColors.primary["7"]}`,
    position: "absolute",
    minWidth: 168,
    backgroundColor: LightColors.primary["0"],
    transformOrigin: "left center",
    "&:after": {
      borderTop: `10px solid transparent`,
      borderLeft: "0px solid transparent",
      borderRight: `25px solid ${LightColors.primary["0"]}`,
      borderBottom: "10px solid transparent",
      content: '""',
      position: "absolute",
      top: "40%",
      left: "-24px",
      [theme.breakpoints.up("sm")]: {
        borderTop: `25px solid ${LightColors.primary["0"]}`,
        borderLeft: "10px solid transparent",
        borderRight: `10px solid transparent`,
        borderBottom: "0px solid transparent",
        top: "95%",
        bottom: "-20px",
        left: "43%",
      },
    },
    "&:before": {
      borderTop: `10px solid transparent`,
      borderLeft: "0px solid transparent",
      borderRight: `25px solid ${LightColors.primary["7"]}`,
      borderBottom: "10px solid transparent",
      content: '""',
      position: "absolute",
      top: "40%",
      left: "-27px",
      borderStartStartRadius: 20,
      [theme.breakpoints.up("sm")]: {
        borderTop: `25px solid ${LightColors.primary["7"]}`,
        borderLeft: "10px solid transparent",
        borderRight: `10px solid transparent`,
        borderBottom: "0px solid transparent",
        top: "100%",
        bottom: "-25px",
        left: "43%",
        borderStartStartRadius: 0,
        borderEndEndRadius: 20,
      },
    },
    [theme.breakpoints.up("sm")]: {
      transform: "translateY(-100%)",
      padding: theme.spacing(1.75),
    },
  },
  leftPaperLoc: {
    top: 0,
    bottom: -70,
    left: 58,
    transform: "rotate(270deg) scaleX(-1)",
    transformOrigin: "center left",
    [theme.breakpoints.up("sm")]: {
      transform: "translateY(-100%)",
      top: -20,
      left: 0,
    },
  },
  leftPaperLoc220: {
    [theme.breakpoints.up("sm")]: {
      left: "4px !important",
    },
  },
  leftPaperLocBonusDays0: {
    top: 0,
    left: 58,
    transform: "rotate(270deg) scaleX(-1)",
    [theme.breakpoints.up("sm")]: {
      transform: "translateY(-100%)",
      top: -21,
      left: 2,
    },
  },
  leftPaperFreeTrialLocBonusDays0: {
    top: 25,
    left: 33,
    [theme.breakpoints.up("sm")]: {
      top: -21,
      left: 2,
    },
  },
  leftPaperLocNoFree: {
    top: 25,
    left: 95,
    transform: "rotate(270deg) scaleX(-1)",
    [theme.breakpoints.up("sm")]: {
      transform: "translateY(-100%)",
      top: -21,
      left: 50,
    },
  },

  leftPaperLocNoFree168: {
    [theme.breakpoints.up("sm")]: {
      left: 54,
    },
  },
  leftPaperLocNoFree180: {
    [theme.breakpoints.up("sm")]: {
      left: 58,
    },
  },
  leftPaperLocNoFree190: {
    [theme.breakpoints.up("sm")]: {
      left: 62,
    },
  },
  rightPaperLoc: {
    top: 0,
    bottom: -70,
    left: 264,
    transform: "rotate(270deg) scaleX(-1)",
    transformOrigin: "center left",
    [theme.breakpoints.up("sm")]: {
      top: -20,
      transform: "translateY(-100%)",
      left: 271,
    },
  },
  rightPaperLoc180: {
    [theme.breakpoints.up("sm")]: {
      left: "278px !important",
    },
  },
  rightPaperLoc185: {
    [theme.breakpoints.up("sm")]: {
      left: "273px !important",
    },
  },
  rightPaperLoc190: {
    [theme.breakpoints.up("sm")]: {
      left: "286px !important",
    },
  },
  rightPaperLoc200: {
    [theme.breakpoints.up("sm")]: {
      left: "283px !important",
    },
  },
  rightPaperLoc220: {
    [theme.breakpoints.up("sm")]: {
      left: "289px !important",
    },
  },
  rightPaperLocBonusDays0: {
    top: 111,
    left: 101,
    transform: "rotate(270deg) scaleX(-1)",
    [theme.breakpoints.up("sm")]: {
      top: -20,
      transform: "translateY(-100%)",
      left: 271,
    },
  },
  rightPaperFreeTrialLocBonusDays0: {
    top: 25,
    left: 185,
    [theme.breakpoints.up("sm")]: {
      top: -20,
      left: 271,
    },
  },
  rightPaperLocNoFree: {
    left: 242,
    top: 25,
    transform: "rotate(270deg) scaleX(-1)",
    [theme.breakpoints.up("sm")]: {
      top: -20,
      transform: "translateY(-100%)",
      left: 253,
      width: "max-content",
    },
  },
  paper2: {
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    transform: "scaleX(1) scaleY(-1) rotate(270deg)",
    transformOrigin: "center left",
    left: 156,
    top: 39,
    [theme.breakpoints.up("sm")]: {
      transform: "translateY(0%)",
      // mantis - 8366, 문구 위치 피그마 디자인에 맞게 이동 (Leehj)
      // marginLeft: 158,
      top: 39,
    },
  },
  dividerDiv: {
    position: "relative",
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      marginTop: 162,
      marginLeft: 0,
    },
  },
  dividerDivBonusDay0: {
    top: 27,
    right: 23,
    [theme.breakpoints.up("sm")]: {
      top: 0,
      right: 0,
    },
  },
  dividerDivNoFree: {
    [theme.breakpoints.up("sm")]: {
      marginBottom: 30,
    },
  },
  lineDiv: {
    display: "flex",
    alignItems: "center",
    color: LightColors.primary["7"],
    zIndex: 2,
  },
  divider: {
    backgroundColor: LightColors.primary["6"],
    width: "100%",
    top: "50%",
    transform: "translate(0, -50%);",
    height: 2,
  },
  shortDivider: {
    marginTop: 24,
    border: `1px solid ${LightColors.primary["7"]}`,
    height: 25,
    marginLeft: -12,
    transform: "translate(-3px, 2px)",
    [theme.breakpoints.up("sm")]: {
      marginTop: 15,
      height: 22,
    },
  },
  dotIcon: {
    fontSize: 12,
    marginLeft: -2,
    marginRight: 8,
  },
  circleIcon: {
    width: 16,
    height: 16,
    borderRadius: "50%",
    backgroundColor: LightColors.primary["0"],
    border: `3px solid ${LightColors.primary["7"]}`,
  },
  circleLeft: {
    marginLeft: 48,
    [theme.breakpoints.up("sm")]: {
      marginLeft: 76,
    },
  },
  circleRight: {
    marginRight: 36,
    marginLeft: -5,
    zIndex: 2,
    [theme.breakpoints.up("sm")]: {
      marginRight: 76,
      marginLeft: 0,
    },
  },
  circleRightBonusDays0: {
    marginRight: 36,
    zIndex: 2,
    [theme.breakpoints.up("sm")]: {
      marginRight: 0,
    },
  },
  greenColorDiv: {
    transform: "scaleX(-1)",
    border: `1px solid ${LightColors.secondary["15"]}`,
    borderBottom: "none",
    borderTopLeftRadius: 40,
    borderTopRightRadius: 40,
    backgroundColor: `${LightColors.secondary["16"]}BF`,
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(2.375),
    height: 19,
    [theme.breakpoints.up("sm")]: {
      transform: "scaleX(1)",
      height: 17,
    },
  },
  greenColorLeft: {
    width: 86,
    [theme.breakpoints.up("sm")]: {
      width: 99,
    },
  },
  greenColorRight: {
    width: 103,
    [theme.breakpoints.up("sm")]: {
      width: 147,
    },
  },
  greenColorbonus0: {
    width: 136,
    [theme.breakpoints.up("sm")]: {
      width: 254,
    },
  },
  greenColorNoFree: {
    width: 130,
    [theme.breakpoints.up("sm")]: {
      width: 187,
    },
  },
  width100: {
    width: "100%",
  },
  textCenter: {
    textAlign: "center",
  },
  noFreeActiveDiv: {
    position: "absolute",
    display: "flex",
    bottom: -125,
    left: -60,
    flexDirection: "column",
    alignItems: "center",
    zIndex: 99,

    [theme.breakpoints.up("sm")]: {
      bottom: -39,
      left: 0,
    },
  },

  noFreeLongActiveDiv: {
    left: -100,
    [theme.breakpoints.up("sm")]: {
      left: 0,
    },
  },
  noFreeTooLongActiveDiv: {
    left: -130,
    [theme.breakpoints.up("sm")]: {
      left: 0,
    },
  },
  noFreeShortActiveDiv: {
    bottom: -105,
    [theme.breakpoints.up("sm")]: {
      bottom: -39,
    },
  },
  hidden: {
    display: "none",
  },
  activateCircle: {
    borderRadius: "50%",
    backgroundColor: LightColors.primary["2"],
    width: 8,
    height: 8,
  },
  activateLine: {
    width: 1,
    height: 16,
    backgroundColor: LightColors.primary["2"],
  },
  activateText: {
    display: "flex",
    flexDirection: "column",
    transformOrigin: "center",
    transform: "rotate(270deg) scaleX(-1)",
    marginTop: 60,

    [theme.breakpoints.up("sm")]: {
      transform: "unset",
      position: "unset",
      marginTop: "unset",
      alignItems: "center",
    },
  },
  longActivateText: {
    marginLeft: 40,
  },
  tooLongActivateText: {
    marginLeft: 64,
  },
  shortActivateText: {
    marginTop: 40,
  },
}));

const SubscriptionPlan = (props: ISubscriptionPlanProps) => {
  // const { PBRDate, billingDate } = props;
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const history = useHistory();
  const location = useLocation<{ simcard: Simcard }>();
  const { path } = useRouteMatch();
  const [lPaperRef, setLPaperRef] = useState<HTMLDivElement | null>(null);
  const [rPaperRef, setRPaperRef] = useState<HTMLDivElement | null>(null);
  // mantis - 8400, 모바일인 경우 활성화 날짜부분 ui겹침현상 수정을 위한 추가 (Leehj)
  const [activateText, setActivateText] = useState<HTMLDivElement | null>(null);

  const [leftMargin, setLeftMargin] = useState(76);
  const [rightMargin, setRightMargin] = useState(76);

  const simcard = useMemo(
    () => location.state?.simcard,
    [location.state?.simcard]
  );

  const activatedDate = useMemo(() => {
    if (simcard?.pbrExitDate) {
      return moment.utc(simcard.pbrExitDate);
    }
    return moment.utc();
  }, [simcard.pbrExitDate]);

  const isFreeTrial = useMemo(() => {
    // 오늘날짜로부터 전달 1일이 마지막 free trial 적용되는 날짜
    const lastFreeTrialDay = moment.utc().subtract(1, "month").startOf("month");
    // pbr exit날짜가 free trial적용되는 날짜와 같거나 이후면, free trial 적용
    return activatedDate.isSameOrAfter(lastFreeTrialDay);
  }, [activatedDate]);

  const marginLeft = useMemo(() => {
    if (smUp) {
      if (isFreeTrial) {
        return Math.max(rightMargin, leftMargin);
      } else {
        return Math.max(rightMargin, leftMargin) + 50;
      }
    }
    if (!isFreeTrial) {
      return 110;
    }
    return undefined;
  }, [isFreeTrial, leftMargin, rightMargin, smUp]);

  useEffect(() => {
    if (lPaperRef) {
      const width = lPaperRef?.clientWidth ?? 168;
      if (smUp) {
        setLeftMargin((width - 16) / 2);
      } else {
        setLeftMargin(width - 100);
      }
    }
  }, [lPaperRef, smUp]);

  useEffect(() => {
    if (rPaperRef) {
      setRightMargin(((rPaperRef?.clientWidth ?? 168) - 16) / 2);
    }
  }, [rPaperRef]);

  const endDay = useMemo(() => {
    return moment(activatedDate).endOf("month");
  }, [activatedDate]);

  const listCreate = useCallback(
    (...list: any[]) => {
      return [
        <Typography
          category="Default"
          variant="Caption"
          htmlColor={LightColors.primary["3"]}
        >
          <ul className={classes.ul}>{list}</ul>
        </Typography>,
      ];
    },
    [classes.ul]
  );

  const listTypo = useCallback(
    (_text: string | React.ReactNode) => {
      return (
        <li key={`${_text}-marker`}>
          <span className={classes.liSpan} style={{ display: "flex" }}>
            ㆍ
            <div style={{ marginLeft: 3, marginBottom: mobile ? 0 : 1 }}>
              {typeof _text === "string" ? t(_text) : _text}
            </div>
          </span>
        </li>
      );
    },
    [classes.liSpan, mobile, t]
  );

  const nextBillingDate = useMemo(() => {
    if (!isFreeTrial) return moment.utc().endOf("month").format("MMM D, YYYY");
    if (activatedDate.get("D") === 1) {
      return moment(activatedDate).endOf("month").format("MMM D, YYYY");
    } else {
      return moment(activatedDate)
        .add(1, "months")
        .endOf("month")
        .format("MMM D, YYYY");
    }
  }, [activatedDate, isFreeTrial]);

  const bonusDays = useMemo(() => {
    if (!isFreeTrial) return 0;
    if (activatedDate.get("D") !== 1) {
      const endDay = moment(activatedDate).endOf("month");
      return Math.abs(activatedDate.diff(endDay, "days")) + 1;
    }
    return 0;
  }, [activatedDate, isFreeTrial]);

  const totalDays = useMemo(() => {
    if (activatedDate.get("D") === 1) {
      return parseInt(endDay.format("D"));
    } else {
      return (
        bonusDays +
        parseInt(
          moment(activatedDate).add(1, "months").endOf("month").format("D")
        )
      );
    }
  }, [activatedDate, bonusDays, endDay]);

  const resetDate = useMemo(() => {
    return moment(activatedDate)
      .add(1, "months")
      .startOf("month")
      .format("MMM D, YYYY");
  }, [activatedDate]);

  const freeTrialStartMarkup = useMemo(() => {
    return (
      <div className={clsx(classes.flexColumn, classes.MT3UP)}>
        <Typography
          category="Default"
          variant="H4"
          htmlColor={LightColors.primary["7"]}
          style={{ marginBottom: 4 }}
        >
          {activatedDate.format("MMM D, YYYY")}
        </Typography>
        <Typography
          category="Default"
          variant="Caption"
          style={{ whiteSpace: "nowrap" }}
        >
          {t("SIM card activate_")}*
        </Typography>
        <div className={classes.freePrice}>
          <Typography category="Default" variant="H5">
            0 USD
          </Typography>

          <Typography category="Default" variant="Caption">
            /{t("mo")}
          </Typography>
        </div>
      </div>
    );
  }, [activatedDate, classes.MT3UP, classes.flexColumn, classes.freePrice, t]);

  const noFreeTrialStartMarkup = useMemo(() => {
    // 금액 일할 계산
    const amount = calculateProated("1GB");

    return (
      <div className={clsx(classes.flexColumn, classes.MT3UP)}>
        <Typography
          category="Default"
          variant="Caption"
          style={{ whiteSpace: "nowrap", textAlign: "center", marginBottom: 4 }}
        >
          {t("Today")}
        </Typography>
        <Typography
          category="Default"
          variant="H4"
          htmlColor={LightColors.primary["7"]}
          style={{ marginBottom: 4 }}
        >
          {moment.utc().format("MMM D, YYYY")}
        </Typography>
        <div className={clsx(classes.freePrice, classes.textCenter)}>
          <Typography category="Default" variant="H5">
            {amount} USD
          </Typography>

          <Typography category="Default" variant="Caption">
            /{t("mo")}
          </Typography>
        </div>
      </div>
    );
  }, [
    classes.MT3UP,
    classes.flexColumn,
    classes.freePrice,
    classes.textCenter,
    t,
  ]);

  return (
    <div className={classes.formDiv}>
      <Typography
        category={mobile ? "Default" : "Large"}
        variant={mobile ? "H1" : "H3"}
        className={classes.title}
      >
        {isFreeTrial ? t("Start your free_") : t("Start your subscription now")}
      </Typography>
      <div>
        {/* 상단 박스 */}
        <div className={classes.box1Wrap}>
          <div className={classes.box1Div}>
            {/* 상단 왼쪽 박스 */}
            <div
              className={clsx(classes.priceBoxDiv, {
                [classes.width100]: !isFreeTrial,
              })}
            >
              <div
                className={clsx(classes.priceInfoDiv, {
                  [classes.bonusDays0Div]: bonusDays === 0,
                  [classes.width100]: !isFreeTrial,
                })}
              >
                <div
                  className={clsx({
                    [classes.dataText]: bonusDays !== 0,
                    [classes.dataTextBonusDays0]: bonusDays === 0,
                  })}
                >
                  <Typography
                    category="Default"
                    variant="H4"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    {t("1GB for 1_")}
                  </Typography>
                </div>

                <div
                  className={clsx(classes.fleetPrice, {
                    [classes.noDecoLine]: !isFreeTrial,
                  })}
                >
                  <Typography category="Large" variant="H6">
                    {planAmount["1GB"]} USD
                  </Typography>
                  <div style={{ marginTop: 1 }}>
                    <Typography category="Default" variant="Small">
                      /{t("mo")}
                    </Typography>
                  </div>
                </div>
              </div>
              {bonusDays !== 0 && (
                <div className={classes.plus}>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    htmlColor={LightColors.primary["7"]}
                  >
                    +
                  </Typography>
                </div>
              )}

              {/* 상단 오른쪽 박스 */}
              {bonusDays !== 0 && (
                <div
                  className={classes.bonusBlueWrap}
                  style={{ position: "relative" }}
                >
                  <div className={classes.bonusBlueBox}>
                    <Typography category="Default" variant="CaptionBold">
                      {t("Bonus")}
                    </Typography>
                  </div>
                  <div className={classes.bonusDaysInfoDiv}>
                    {/* {bonusDays && ( */}
                    <Typography
                      category="Default"
                      variant="H6"
                      htmlColor={LightColors.secondary["7"]}
                    >
                      {t(`1GB free for_`, { a: bonusDays })}
                    </Typography>
                    {/* )} */}
                  </div>
                </div>
              )}
            </div>

            <div>
              <Typography
                category="Default"
                variant="H3"
                htmlColor={LightColors.primary["7"]}
              >
                {isFreeTrial
                  ? t(`Total n days_`, { a: totalDays })
                  : t("Free trial period has expired")}
              </Typography>
            </div>
          </div>

          {/* 체크 리스트 */}
          <div className={classes.checkListDiv}>
            <div className={classes.checkIconDiv}>
              <CheckIcon color="primary" className={classes.checkIcon} />
              <Typography category="Default" variant="Body">
                {t("Cancel your plan_")}
              </Typography>
            </div>
            <div className={classes.checkIconDiv}>
              <CheckIcon color="primary" className={classes.checkIcon} />
              <Typography category="Default" variant="Body">
                {t("No Commitment")}
              </Typography>
            </div>
            <div className={classes.checkIconDiv}>
              <CheckIcon color="primary" className={classes.checkIcon} />
              <Typography category="Default" variant="Body">
                {t("Upgrade to 3GB_")}
              </Typography>
            </div>
          </div>

          {/* 하단 박스 */}

          <div
            className={clsx(classes.box2Wrap, {
              [classes.box2WrapBonusDays]: bonusDays === 0,
              [classes.box2WrapNoFree]: !isFreeTrial,
            })}
          >
            <div className={classes.flexCenter}>
              <Typography category="Default" variant="BodyBold">
                {t("Your subscription plan")}
              </Typography>
            </div>
            <div className={classes.timeLineWrap}>
              <div
                className={clsx(classes.timeLineDiv, {
                  // mantis - 8400, 보너스일자가 0일때 스타일 추가 (Leehj)
                  [classes.timeLineDivBonusDays0]:
                    isFreeTrial && bonusDays === 0,
                })}
              >
                <div
                  className={clsx(classes.noFreeActiveDiv, {
                    // mantis - 8400, 모바일인 경우 활성화 날짜부분 ui겹침현상 수정을 위한 스타일 추가 (Leehj)
                    [classes.noFreeLongActiveDiv]:
                      !isFreeTrial &&
                      mobile &&
                      activateText &&
                      activateText.clientWidth > 150,
                    [classes.noFreeShortActiveDiv]:
                      !isFreeTrial &&
                      mobile &&
                      activateText &&
                      activateText.clientWidth < 110,
                    [classes.noFreeTooLongActiveDiv]:
                      !isFreeTrial &&
                      mobile &&
                      activateText &&
                      activateText.clientWidth >= 200,
                    // mantis - 8366, 무료기간을 다 사용한 경우에만 해당 디자인 출력으로 수정 (Leehj)
                    [classes.hidden]: isFreeTrial,
                  })}
                >
                  <div className={classes.activateCircle} />
                  <div className={classes.activateLine} />

                  <div
                    // mantis - 8400, 모바일인 경우 활성화 날짜부분 ui겹침현상 수정을 위한 스타일 추가 (Leehj)
                    ref={(ref) => setActivateText(ref)}
                    className={clsx(classes.activateText, {
                      [classes.shortActivateText]:
                        mobile &&
                        activateText &&
                        activateText.clientWidth < 110,
                    })}
                  >
                    <Typography
                      category="Default"
                      variant="SmallBold"
                      htmlColor={LightColors.primary["2"]}
                      // mantis - 8400, 모바일인 경우 활성화 날짜부분 ui겹침현상 수정을 위한 스타일 추가 (Leehj)
                      className={clsx({
                        [classes.longActivateText]:
                          mobile &&
                          activateText &&
                          activateText.clientWidth > 150,
                        [classes.tooLongActivateText]:
                          mobile &&
                          activateText &&
                          activateText.clientWidth >= 200,
                      })}
                    >
                      {activatedDate.format("MMM D, YYYY")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      style={{ whiteSpace: "nowrap" }}
                      htmlColor={LightColors.primary["3"]}
                      // mantis - 8400, 모바일인 경우 활성화 날짜부분 ui겹침현상 수정을 위한 스타일 추가 (Leehj)
                      className={clsx({
                        [classes.longActivateText]:
                          mobile &&
                          activateText &&
                          activateText.clientWidth > 150,
                        [classes.tooLongActivateText]:
                          mobile &&
                          activateText &&
                          activateText.clientWidth >= 200,
                      })}
                    >
                      {t("SIM card activate_")}*
                    </Typography>
                  </div>
                </div>

                <div
                  ref={(ref) => {
                    // setLeftMargin(((ref?.clientWidth ?? 168) - 16) / 2)
                    setLPaperRef(ref);
                  }}
                  className={clsx(classes.paper, {
                    [classes.leftPaperLoc]: bonusDays !== 0,
                    [classes.leftPaperLocBonusDays0]: bonusDays === 0,
                    // mantis - 8400, 무료이면서 보너스일자가 0일때 스타일 추가 (Leehj)
                    [classes.leftPaperFreeTrialLocBonusDays0]:
                      isFreeTrial && bonusDays === 0,
                    [classes.leftPaperLocNoFree]: !isFreeTrial,
                    // matis - 8366, 왼쪽 말풍선 width값에 따라 왼쪽 말풍선 left값 변경 (Leehj)
                    [classes.leftPaperLoc220]:
                      isFreeTrial && lPaperRef && lPaperRef?.clientWidth >= 220,
                    // matis - 8366, 오른쪽 말풍선 width값에 따라 왼쪽 말풍선 left값 변경 (Leehj)
                    [classes.leftPaperLocNoFree168]:
                      !isFreeTrial && rPaperRef && rPaperRef?.clientWidth > 168,
                    [classes.leftPaperLocNoFree180]:
                      !isFreeTrial &&
                      rPaperRef &&
                      rPaperRef?.clientWidth >= 175,
                    [classes.leftPaperLocNoFree190]:
                      !isFreeTrial &&
                      rPaperRef &&
                      rPaperRef?.clientWidth >= 190,
                  })}
                  // style={{
                  //   top: smUp ? undefined : leftMargin,
                  // }}
                >
                  {isFreeTrial ? freeTrialStartMarkup : noFreeTrialStartMarkup}
                </div>

                <div
                  ref={(ref) => setRPaperRef(ref)}
                  className={clsx(classes.paper, {
                    [classes.rightPaperLoc]: bonusDays !== 0,
                    [classes.rightPaperLocBonusDays0]: bonusDays === 0,
                    // mantis - 8400, 무료이면서 보너스일자가 0일때 스타일 추가 (Leehj)
                    [classes.rightPaperFreeTrialLocBonusDays0]:
                      isFreeTrial && bonusDays === 0,
                    [classes.rightPaperLocNoFree]: !isFreeTrial,
                    // matis - 8366, 왼쪽 말풍선 width값에 따라 오른쪽 말풍선 left값 변경 (Leehj)
                    [classes.rightPaperLoc180]:
                      isFreeTrial && lPaperRef && lPaperRef?.clientWidth >= 180,
                    [classes.rightPaperLoc185]:
                      isFreeTrial && lPaperRef && lPaperRef?.clientWidth >= 185,
                    [classes.rightPaperLoc190]:
                      isFreeTrial && lPaperRef && lPaperRef?.clientWidth >= 190,
                    [classes.rightPaperLoc200]:
                      isFreeTrial && lPaperRef && lPaperRef?.clientWidth >= 200,
                    [classes.rightPaperLoc220]:
                      isFreeTrial && lPaperRef && lPaperRef?.clientWidth >= 220,
                  })}
                >
                  <div className={clsx(classes.flexColumn, classes.MT3UP)}>
                    {!isFreeTrial && (
                      <Typography
                        category="Default"
                        variant="Caption"
                        className={classes.textCenter}
                        // mantis - 8400, 두 줄로 출력되는 이슈 수정 (Leehj)
                        style={{ marginBottom: 4, whiteSpace: "nowrap" }}
                      >
                        {t("Next billing date")}
                      </Typography>
                    )}
                    <Typography
                      category="Default"
                      variant="H4"
                      htmlColor={LightColors.primary["7"]}
                      // mantis - 8400, 날짜 두 줄로 출력되는 이슈 수정 (Leehj)
                      style={{ marginBottom: 4, whiteSpace: "nowrap" }}
                      className={clsx({
                        [classes.textCenter]: !isFreeTrial,
                      })}
                    >
                      {nextBillingDate}
                    </Typography>
                    {isFreeTrial && (
                      <Typography
                        category="Default"
                        variant="Caption"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {t("Next billing date")}
                      </Typography>
                    )}
                    <div
                      className={clsx(classes.freePrice, {
                        [classes.textCenter]: !isFreeTrial,
                      })}
                    >
                      <Typography category="Default" variant="H5">
                        {planAmount["1GB"]} USD
                      </Typography>
                      <Typography category="Default" variant="Caption">
                        /{t("mo")}
                      </Typography>
                    </div>
                  </div>
                </div>

                <div
                  className={clsx(classes.dividerDiv, {
                    [classes.dividerDivBonusDay0]: bonusDays === 0,
                    [classes.dividerDivNoFree]: !isFreeTrial,
                  })}
                >
                  <div className={classes.lineDiv}>
                    <div
                      className={clsx(classes.circleIcon, classes.circleLeft)}
                      style={{
                        marginLeft,
                      }}
                    />

                    {bonusDays !== 0 && (
                      <>
                        <div
                          className={clsx(
                            classes.greenColorDiv,
                            classes.greenColorLeft
                          )}
                        >
                          <Typography
                            category="Default"
                            variant="Small"
                            htmlColor={LightColors.secondary["15"]}
                            style={{ marginBottom: 2 }}
                          >
                            1GB
                          </Typography>
                        </div>
                        <FiberManualRecordIcon className={classes.dotIcon} />
                        <Divider className={classes.shortDivider} />
                      </>
                    )}

                    <div
                      className={clsx(
                        classes.greenColorDiv,
                        classes.greenColorRight,
                        {
                          [classes.greenColorbonus0]: bonusDays === 0,
                          [classes.greenColorNoFree]: !isFreeTrial,
                        }
                      )}
                    >
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.secondary["15"]}
                        style={{ marginBottom: 2 }}
                      >
                        1GB
                      </Typography>
                    </div>
                    <div
                      className={clsx(classes.circleIcon, {
                        [classes.circleRight]: bonusDays !== 0,
                        [classes.circleRightBonusDays0]: bonusDays === 0,
                      })}
                      style={{
                        marginRight: smUp
                          ? Math.max(rightMargin, leftMargin)
                          : undefined,
                      }}
                    />
                  </div>
                  <Divider absolute className={classes.divider} />
                  {bonusDays !== 0 && (
                    <div className={classes.paper2}>
                      <Typography
                        category="Default"
                        variant="Small"
                        htmlColor={LightColors.primary["8"]}
                      >
                        {resetDate}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Caption"
                        htmlColor={LightColors.primary["2"]}
                        style={{ marginLeft: -3 }}
                      >
                        {t("Data reset date")}
                      </Typography>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className={classes.bottomText}>
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={LightColors.primary["3"]}
            >
              *{t("Based on the_")}
            </Typography>
          </div>

          {/* 하단 설명 */}
          <div style={{ maxWidth: 502 }}>
            <Typography
              category="Default"
              variant="SmallBold"
              htmlColor={LightColors.primary["3"]}
              style={{ marginBottom: 3 }}
            >
              {t("Keep in mind")}:
            </Typography>
            {listCreate(
              listTypo("Once 1GB of_"),
              listTypo("After the SIM_"),
              listTypo(
                <Trans
                  t={t}
                  components={{
                    a: (
                      // eslint-disable-next-line jsx-a11y/anchor-has-content
                      <a
                        href={`mailto:${CSTeamEmail}`}
                        className={classes.csTeamEmail}
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                  }}
                >
                  If you have_questions
                </Trans>
              )
            )}
          </div>
          <Button
            color="primary"
            fullWidth
            className={classes.nextBtn}
            onClick={() => {
              if (path.startsWith("/webviewer")) {
                history.push("/webviewer/billing", {
                  ...location.state,
                  plan: "1GB",
                  nextBillingDate,
                  bonusDays,
                  activatedDate: activatedDate.toISOString(),
                });
              } else {
                history.push("/billing", {
                  ...location.state,
                  plan: "1GB",
                  nextBillingDate,
                  bonusDays,
                  activatedDate: activatedDate.toISOString(),
                });
              }
            }}
          >
            {t("Next")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionPlan;
