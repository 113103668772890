import React, { useMemo } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Hidden } from "@material-ui/core";

import clsx from "clsx";
import {
  Container,
  DarkColors,
  IconButton,
  LightColors,
  LogoFullDark,
  LogoOnly,
  Menu,
  Tab,
  Tabs,
  WebMenuItem,
  Button,
} from "@thingsw/pitta-design-system";
import { AppBar } from "./AppBar";
import { Toolbar } from "./Toolbar";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import { detect } from "detect-browser";
import { HELPCENTER, SIMGUIDE } from "../contants/Links";

const useStyles = makeStyles((theme: Theme) => ({
  menu1: {
    ...(theme.direction === "rtl"
      ? {
          marginRight: 56,
        }
      : {
          marginLeft: 16,
          [theme.breakpoints.up("sm")]: {
            marginLeft: 56,
          },
        }),
  },

  menu2: {
    ...(theme.direction === "rtl"
      ? { marginRight: "auto" }
      : {
          marginLeft: "auto",
        }),
  },
  vcenter: {
    display: "flex",
    alignItems: "center",
  },
  grow: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "flex-end",
    alignItems: "center",
  },
  mobileMenu: {
    paddingRight: 0,
  },
  DarkColor: {
    color: DarkColors.primary["1"],
    "&:hover": {
      color: LightColors.primary["7"],
    },
    "&:active": {
      color: LightColors.primary["7"],
    },
  },
  appBar: {
    backgroundColor: LightColors.primary["1"],
    borderBottom: `1px solid ${LightColors.primary["7"]}`,
  },
  padding0: {
    padding: 0,
  },
  simCardTab: {
    padding: theme.spacing(0, 2.75),
  },
  pricingTab: {
    padding: theme.spacing(0, 3.5),
  },
  backToAppBtn: {
    backgroundColor: LightColors.primary["1"],
    color: LightColors.primary["7"],
    height: 36,
    marginRight: 24,
    borderColor: LightColors.primary["7"],
    "&:hover": {
      backgroundColor: LightColors.primary["1"],
      borderColor: LightColors.primary["7"],
    },
    "&:active": {
      backgroundColor: LightColors.primary["1"],
      borderColor: LightColors.primary["7"],
    },
  },
}));

export interface MainSimHeaderProps {
  value?: number;
  onClickMenu: () => void;
  tabMenu?: boolean;
}

export const MainSimHeader = React.forwardRef(
  (props: MainSimHeaderProps, ref) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory();

    const { value, onClickMenu, tabMenu } = props;

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current!.focus();
      }

      prevOpen.current = open;
    }, [open]);

    const backToAppMarkup = useMemo(() => {
      const browser = detect();
      if (
        browser?.name === "ios-webview" ||
        browser?.name === "chromium-webview"
      ) {
        return (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              if (browser?.name === "ios-webview") {
                //@ts-ignore
                return webkit.messageHandlers.back.postMessage({});
              }
              if (
                browser?.name === "chromium-webview" &&
                //@ts-ignore
                window.Sim?.back
              ) {
                //@ts-ignore
                return window.Sim.back();
              }
            }}
            className={classes.backToAppBtn}
          >
            {t("Back to App")}
          </Button>
        );
      }
    }, [classes.backToAppBtn, t]);

    return (
      <AppBar className={classes.appBar} ref={ref}>
        <Container>
          <Toolbar simHeader>
            {/* mobile */}
            <Hidden smUp>
              <Link to="/" className={classes.vcenter}>
                <LogoOnly width={36} height={36} />
              </Link>

              <div className={classes.grow}>
                {backToAppMarkup}
                <IconButton
                  onClick={onClickMenu}
                  className={classes.mobileMenu}
                >
                  <MenuIcon className={classes.DarkColor} />
                </IconButton>
              </div>
            </Hidden>
            {/* PC */}
            <Hidden xsDown>
              <Link to="/" className={classes.vcenter}>
                <LogoFullDark width={166} height={29} />
              </Link>
              {tabMenu && (
                <Tabs value={value ?? 0} className={classes.menu1}>
                  <Tab
                    onClick={() => {
                      history.push("/");
                    }}
                    label={t("SIM card")}
                    className={clsx(classes.DarkColor, classes.simCardTab)}
                  />
                  <Tab
                    onClick={() => {
                      history.push("/pricing");
                    }}
                    label={t("Pricing")}
                    className={clsx(classes.DarkColor, classes.pricingTab)}
                  />
                </Tabs>
              )}
              <Tabs value={1} className={classes.menu2}>
                <Tab
                  ref={anchorRef}
                  onClick={() => setOpen((o) => !o)}
                  className={clsx(classes.DarkColor, classes.padding0)}
                  label={
                    <>
                      {t("Support")}
                      {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </>
                  }
                />
                <Tab style={{ display: "none" }} />
              </Tabs>

              <Menu
                open={open}
                darkMode
                anchorEl={anchorRef.current}
                placement="bottom-end"
                modifiers={{
                  offset: { enabled: true, offset: "0, 2px" },
                  preventOverflow: {
                    enabled: false,
                  },
                }}
                onClickAway={() => {
                  setOpen(false);
                }}
              >
                <WebMenuItem
                  darkMode
                  endIcon={<OpenInNewIcon fontSize="inherit" />}
                  onClick={() => {
                    setOpen(false);
                    window.open(SIMGUIDE, "_blank");
                  }}
                >
                  {t("SIM guide")}
                </WebMenuItem>
                <WebMenuItem
                  darkMode
                  endIcon={<OpenInNewIcon fontSize="inherit" />}
                  onClick={() => {
                    setOpen(false);
                    window.open(HELPCENTER, "_blank");
                  }}
                >
                  {t("Help center")}
                </WebMenuItem>
              </Menu>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
    );
  }
);
