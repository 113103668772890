import React, { useMemo, useState } from "react";
import {
  Avatars,
  Button,
  IconButton,
  LightColors,
  Link,
  Typography,
} from "@thingsw/pitta-design-system";
import { Card, makeStyles, Theme } from "@material-ui/core";
import moment from "moment";
import EditIcon from "@material-ui/icons/Edit";
import { Simcard } from "../types";
import { UpgradeSelectModal } from "./modals/UpgradeSelectModal";
import { CancelSubscriptionModal } from "./modals/CancelSubscriptionModal";
import { InvoiceModal } from "./modals/InvoiceModal";
import ChangePaymentModal from "./modals/ChangePaymentModal";
import { useTranslation } from "react-i18next";
import {
  checkCanceled,
  checkPaused,
  checkSubscribed,
  checkTerminated,
  getBrandName,
  getCardIcon,
} from "../utils/Subscription";

interface ISimInformationProps {
  currentSim?: Simcard;
  onRenameICCID?: () => void;
  onResubscribe: () => void;
  onUpdated: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    // padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    [theme.breakpoints.up("md")]: {
      // padding: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },

  card: {
    display: "flex",
    width: "100%",
    height: "100%",
    boxShadow: "none",
    border: `1px solid ${LightColors.primary["5"]}`,
    padding: theme.spacing(4, 3, 5),
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
    },
  },

  avatarDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    minWidth: 148,
    [theme.breakpoints.up("sm")]: {
      margin: theme.spacing(0, 5.5, 0, 2.25),
    },
  },

  divGroup: {
    display: "flex",
    flexFlow: "wrap",
    flex: 1,
  },

  div: {
    width: "100%",
    marginBottom: theme.spacing(4),
    "&:last-child": {
      marginBottom: 0,
    },
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
    [theme.breakpoints.up("md")]: {
      width: "33.3%",
    },
  },

  divMarginNon: {
    width: "100%",
    maxWidth: 203,
    marginBottom: 32,
    [theme.breakpoints.up("sm")]: {
      marginBottom: 48,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 48,
      marginRight: 64,
    },
  },

  divLast: {
    width: "100%",
    maxWidth: 203,
    marginBottom: 32,
    [theme.breakpoints.up("sm")]: {
      marginRight: 72,
      // marginRight: 0,
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: 48,
      marginRight: 64,
    },
  },

  payment: {
    width: "100%",
    maxWidth: 186,
    marginTop: 7,
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: 312,
      marginTop: 0,
    },
  },

  btn: {
    // color: LightColors.primary["7"],
    padding: 0,
    minWidth: "auto",
    minHeight: "auto",
    // backgroundColor: "transparent",
    justifyContent: "revert",
  },

  textMargin: {
    marginBottom: 7,
  },

  avatarsMargin: {
    margin: "auto",
  },

  ipadFirstStyle: {
    width: "100%",
    maxWidth: 203,
    marginBottom: 0,
  },
  ipadMiddleStyle: {
    margin: "auto",
  },
  ipadLastStyle: {
    width: "100%",
    maxWidth: 203,
    marginBottom: 0,
  },
  simCardIconDiv: {
    display: "flex",
    justifyContent: "center",
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
      flexDirection: "column",
    },
  },
  cardIconDiv: {
    display: "block",
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(1) }
      : { marginRight: theme.spacing(1) }),
  },
  cardIcon: {
    display: "block",
    height: 18,
    fontSize: 32,
    // width: "100%",
  },
  cardInfo: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "row",
    },
  },
  btnStyle: {
    color: LightColors.primary["7"],
    borderColor: LightColors.primary["7"],
    "&:hover": {
      borderColor: LightColors.primary["7"],
    },
  },
  simNameTextDiv: {
    display: "flex",
    alignItems: "center",
    // mantis - 12867, 텍스트 겹치는 이슈 수정 (Leehj)
    whiteSpace: "pre-wrap",
    overflowWrap: "anywhere",
    [theme.breakpoints.up("sm")]: {
      // mantis - 12867, 텍스트 겹치는 이슈 수정 (Leehj)
      marginRight: 20,
    },
  },
}));

export const SimInformation = (props: ISimInformationProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { currentSim, onRenameICCID, onResubscribe, onUpdated } = props;

  const [openManageModal, setOpenManageModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openInvoiceModal, setOpenInvoiceModal] = useState(false);
  const [openChangePaymentModal, setOpenChangePaymentModal] = useState(false);

  // const { email, user_token, token_type } = useSelector(
  //   (state: RootState) => state[USER]
  // );

  const terminated = useMemo(() => {
    return !!currentSim && checkTerminated(currentSim);
  }, [currentSim]);

  const subscribed = useMemo(() => {
    return !!currentSim && checkSubscribed(currentSim);
  }, [currentSim]);

  const canceled = useMemo(() => {
    return !!currentSim && checkCanceled(currentSim);
  }, [currentSim]);

  const paused = useMemo(() => {
    return !!currentSim && checkPaused(currentSim);
  }, [currentSim]);

  const simMode = useMemo(() => {
    if (terminated) {
      return "Terminated";
    }
    if (canceled) {
      return "Cancelled";
    }
    if (subscribed) {
      return "Subscribed";
    }
    return "Terminated";
  }, [canceled, subscribed, terminated]);

  const simModeColor = useMemo(() => {
    if (terminated) {
      return `${LightColors.primary["1"]}40`;
    }
    if (canceled) {
      return LightColors.secondary["11"];
    }
    if (subscribed) {
      return LightColors.secondary["15"];
    }
    return `${LightColors.primary["1"]}40`;
  }, [canceled, subscribed, terminated]);

  const paymentInfo = useMemo(() => {
    if (terminated) return "-";
    if (currentSim?.blackvueOne) {
      return (
        <Typography
          category="Default"
          variant="Body"
          style={{ marginRight: 4 }}
        >
          {t("One Package")}
        </Typography>
      );
    }
    if (currentSim?.payment_method) {
      const pm = currentSim.payment_method;
      const cardNumberString = `${getBrandName(pm.brand)} ***${pm.last4}`;

      let cardImg = getCardIcon(pm.brand, classes.cardIcon);

      return (
        <>
          <div className={classes.cardIconDiv}>{cardImg}</div>
          <div className={classes.cardInfo}>
            <Typography
              category="Default"
              variant="Body"
              style={{ marginRight: 4 }}
            >
              {cardNumberString}
            </Typography>
            <Typography category="Default" variant="Body">
              (
              {t("valid thru_", {
                a: `${String(pm.expMonth).padStart(2, "0")}/${
                  pm.expYear - 2000
                }`,
              })}
              )
            </Typography>
          </div>
        </>
      );
    }
  }, [
    classes.cardIcon,
    classes.cardIconDiv,
    classes.cardInfo,
    currentSim?.blackvueOne,
    currentSim?.payment_method,
    t,
    terminated,
  ]);

  const nextBillingMarkup = useMemo(() => {
    if (
      terminated
      // 심 terminated된 날짜로부터 한달이 지난 경우 경우
      // && moment.utc(currentSim?.terminatedAt).add(1, "M").isBefore(moment.utc())
    ) {
      return "-";
    }
    if (subscribed && !canceled) {
      if (currentSim?.blackvueOne) {
        return (
          <>
            <Typography category="Default" variant="Body">
              {moment
                .utc(currentSim?.subscription?.createdAt)
                .format("MMM D, YYYY")}{" "}
              ~{" "}
              {moment
                .utc(currentSim?.subscription?.periodEnd)
                .format("MMM D, YYYY")}
            </Typography>
            <br />
            <Typography category="Default" variant="BodyBold">
              [
              {currentSim?.subscription?.nextPlan !== null
                ? currentSim?.subscription?.nextPlan
                : currentSim.subscription?.planName}{" "}
              {t("Plan")}]
            </Typography>
          </>
        );
      }
      return (
        <>
          <Typography category="Default" variant="Body">
            {moment
              .utc(currentSim?.subscription?.periodEnd)
              .format("MMM D, YYYY")}
          </Typography>{" "}
          <Typography category="Default" variant="BodyBold">
            [
            {currentSim?.subscription?.nextPlan !== null
              ? currentSim?.subscription?.nextPlan
              : currentSim.subscription?.planName}{" "}
            {t("Plan")}]
          </Typography>
        </>
      );
    }
    if (canceled) {
      return (
        <div>
          <Button
            variant="outlined"
            color="primary"
            className={classes.btnStyle}
            onClick={(e) => {
              onResubscribe();
            }}
          >
            {t("Resubscribe")}
          </Button>
        </div>
      );
    }
  }, [
    canceled,
    classes.btnStyle,
    currentSim,
    onResubscribe,
    subscribed,
    t,
    terminated,
  ]);

  const planNameMarkup = useMemo(() => {
    if (terminated) {
      return "-";
    }
    return (
      currentSim?.subscription?.planName && (
        <Typography category="Default" variant="Body">
          {currentSim?.subscription?.planName} {t("Plan")}
        </Typography>
      )
    );
  }, [currentSim, t, terminated]);

  const activationDateMarkup = useMemo(() => {
    if (currentSim?.pbrExitDate) {
      return moment(currentSim?.pbrExitDate).format("MMM D, YYYY");
    }
    return moment(currentSim?.chargedAt).format("MMM D, YYYY");
  }, [currentSim?.chargedAt, currentSim?.pbrExitDate]);

  const remainsDayMarkup = useMemo(() => {
    if (
      subscribed &&
      canceled &&
      moment().isBefore(currentSim?.subscription?.periodEnd)
    ) {
      return (
        <div>
          <Typography
            category="Default"
            variant="Caption"
            htmlColor={simModeColor}
          >
            {t("Service end_", {
              a: moment(currentSim?.subscription?.periodEnd).format(
                "MMM D, YYYY"
              ),
            })}
          </Typography>
        </div>
      );
    }

    if (!subscribed && paused && moment().isBefore(currentSim?.terminatedAt)) {
      return (
        <div>
          <Typography
            category="Default"
            variant="Caption"
            htmlColor={LightColors.secondary["11"]}
          >
            {t("Resubscribe until_", {
              a: moment(currentSim?.terminatedAt).format("MMM D, YYYY"),
            })}
          </Typography>
        </div>
      );
    }
  }, [
    canceled,
    currentSim?.subscription?.periodEnd,
    currentSim?.terminatedAt,
    paused,
    simModeColor,
    subscribed,
    t,
  ]);

  return (
    <div className={classes.body}>
      <Card className={classes.card}>
        <div className={classes.simCardIconDiv}>
          <div className={classes.avatarDiv}>
            <Avatars size="sim" mode="sim" simMode={simMode} />
            <div style={{ marginTop: 12 }}>
              <Typography
                category="Default"
                variant="H6"
                htmlColor={simModeColor}
              >
                {t(simMode)}
              </Typography>
            </div>
            {remainsDayMarkup}
          </div>
        </div>
        <div className={classes.divGroup}>
          <div className={classes.div}>
            <div className={classes.textMargin}>
              <Typography category="Default" variant="H6">
                {t("ICCID number")}
              </Typography>
            </div>
            <div className={classes.textMargin}>
              <Typography category="Default" variant="Body">
                {currentSim?.iccid}
              </Typography>
            </div>
          </div>
          <div className={classes.div}>
            <div className={classes.textMargin}>
              <Typography category="Default" variant="H6">
                {t("Name")}
              </Typography>
            </div>
            {/* mantis - 8808, 띄어쓰기 출력안되는 이슈 수정 (Leehj) */}
            <div className={classes.simNameTextDiv}>
              {currentSim?.name ? (
                <Typography category="Default" variant="Body">
                  {currentSim?.name}
                </Typography>
              ) : (
                <Typography
                  category="Default"
                  variant="Body"
                  style={{ color: `${LightColors.primary["4"]}` }}
                >
                  {t("Name")}
                </Typography>
              )}

              <IconButton onClick={onRenameICCID}>
                <EditIcon
                  style={{
                    marginLeft: 8,
                    color: `${LightColors.primary["3"]}`,
                  }}
                />
              </IconButton>
            </div>
          </div>

          <div className={classes.div}>
            <div className={classes.textMargin}>
              <Typography category="Default" variant="H6">
                {t("Activation date")}
              </Typography>
            </div>
            <div>
              {/* PBR Exit, 과금이 시작되는 시점 */}
              <Typography category="Default" variant="Body">
                {activationDateMarkup}
              </Typography>
            </div>
          </div>
          <div className={classes.div}>
            <div className={classes.textMargin}>
              <Typography category="Default" variant="H6">
                {t("Current plan")}
              </Typography>
            </div>
            <div className={classes.textMargin}>{planNameMarkup}</div>
            {!(canceled || paused || terminated || currentSim?.blackvueOne) && (
              <Link
                category="Default"
                variant="Body"
                mode="anchor"
                onClick={() => setOpenManageModal(true)}
              >
                {t("Manage Plan")}
              </Link>
            )}
          </div>
          <div className={classes.div}>
            <div className={classes.textMargin}>
              <Typography category="Default" variant="H6">
                {t("Next billing on")}
              </Typography>
            </div>
            <div className={classes.textMargin}>{nextBillingMarkup}</div>
            <Link
              category="Default"
              variant="Body"
              mode="anchor"
              onClick={() => setOpenInvoiceModal(true)}
            >
              {t("View Invoices")}
            </Link>
          </div>
          <div className={classes.div}>
            <div className={classes.textMargin}>
              <Typography category="Default" variant="H6">
                {t("Payment method")}
              </Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center" }}
              className={classes.textMargin}
            >
              {paymentInfo}
            </div>
            {!(canceled || paused || terminated || currentSim?.blackvueOne) && (
              <Link
                category="Default"
                variant="Body"
                mode="anchor"
                onClick={() => setOpenChangePaymentModal(true)}
              >
                {t("Change payment method")}
              </Link>
            )}
          </div>
        </div>
      </Card>
      {openManageModal && (
        <UpgradeSelectModal
          open={openManageModal}
          onClose={() => setOpenManageModal(false)}
          onClickCancel={() => {
            setOpenManageModal(false);
            setOpenCancelModal(true);
          }}
          onClickPositive={() => {
            setOpenManageModal(false);
            onUpdated();
          }}
          currentSimCard={currentSim}
          mode="manage"
        />
      )}
      {openCancelModal && currentSim?.subscription && (
        <CancelSubscriptionModal
          open={openCancelModal}
          subscriptionId={currentSim.subscription.id}
          cancelMonth={moment(currentSim?.subscription?.periodEnd)}
          onClose={(cancelled?: boolean) => {
            if (cancelled) {
              onUpdated();
            }
            setOpenCancelModal(false);
          }}
        />
      )}
      {openInvoiceModal && (
        <InvoiceModal
          openModal={openInvoiceModal}
          onClose={() => setOpenInvoiceModal(false)}
          simcardId={currentSim?.id}
        />
      )}
      {openChangePaymentModal && (
        <ChangePaymentModal
          open={openChangePaymentModal}
          currentSimCard={currentSim}
          onClose={() => setOpenChangePaymentModal(false)}
          onClickNegative={() => setOpenChangePaymentModal(false)}
          onClickPositive={() => {
            setOpenChangePaymentModal(false);
            onUpdated();
          }}
        />
      )}
    </div>
  );
};
