import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useMemo } from "react";
import SimpleBarReact from "simplebar-react";
import { detect } from "detect-browser";
import "simplebar/src/simplebar.css";

type ScrollBarProps = SimpleBarReact.Props & {
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
};

export const ScrollBar = ({
  children,
  style,
  className,
  ...props
}: ScrollBarProps) => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const isAndroid = useMemo(() => {
    const browser = detect();
    return browser?.name === "chromium-webview";
  }, []);

  const scrollbarMarkup = useMemo(() => {
    if (isAndroid) {
      return (
        <SimpleBarReact className={className} style={{ ...style }} {...props}>
          {children}
        </SimpleBarReact>
      );
    }
    if (mobile) {
      return (
        <div className={className} style={{ ...style }} {...props}>
          {children}
        </div>
      );
    }

    return (
      <SimpleBarReact className={className} style={{ ...style }} {...props}>
        {children}
      </SimpleBarReact>
    );
  }, [children, className, isAndroid, mobile, props, style]);

  return scrollbarMarkup;
};
