import React, { useCallback } from "react";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import Input from "@thingsw/pitta-design-system/dist/components/Input";
import { Button, LightColors, Typography } from "@thingsw/pitta-design-system";
import { TFunction, useTranslation } from "react-i18next";
import { useMediaQuery } from "@material-ui/core";

import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  submit,
  WrappedFieldProps,
} from "redux-form";
import { detect } from "detect-browser";
import { IAddSimForm } from "../types";

export interface IAddSimProps {
  simPage?: boolean;
  loading?: boolean;
  defaultIccid?: string | null;
}

interface FieldProps {
  label?: string;
  error?: string;
  value?: string;
  t: TFunction;
}

const renderEmailField = ({
  label,
  input,
  meta: { error: metaError },
  error,
  t,
  ...custom
}: WrappedFieldProps & FieldProps) => {
  console.log("input", input, custom);
  return (
    <Input
      label={label}
      error={!!error || !!metaError}
      helperText={(error && t(error)) || (metaError && t(metaError))}
      {...input}
      {...custom}
    />
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(4.625),
    [theme.breakpoints.up("sm")]: {
      marginTop: 14,
    },
  },
  title: {
    display: "flex",
  },
  subTitle: {
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(4.875),
    },
  },
  marginB4: {
    marginBottom: theme.spacing(4),
  },
  iccidWrap: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(2, 2, 4.375, 2),
    backgroundColor: `${LightColors.primary["10"]}`,
    borderRadius: 4,
    marginBottom: 28,
    [theme.breakpoints.up("sm")]: {
      minHeight: 132,
      marginBottom: 34,
      padding: theme.spacing(2, 4, 4.375, 3),
    },
  },
  inputTitle: {
    marginBottom: theme.spacing(2) - 1,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
    },
  },
  iccidDiv: {
    display: "flex",
    width: "100%",
    [theme.breakpoints.up("sm")]: {},
  },
  iccidDefaultNumber: {
    whiteSpace: "nowrap",
    marginRight: 13,
    marginTop: 10,
  },
  inputBackStyle: {
    backgroundColor: LightColors.primary["0"],
  },
  imgDiv: {
    display: "flex",
    justifyContent: "center",
  },
  btnDiv: {
    marginTop: theme.spacing(8.875),
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "center",
      marginTop: theme.spacing(8),
    },
  },
  nextBtn: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
}));

const AddSIM = (props: InjectedFormProps<IAddSimForm> & IAddSimProps) => {
  const { handleSubmit, error, simPage, loading } = props;
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const history = useHistory();

  const handleAddSim = useCallback(() => {
    dispatch(submit("AddSIMForm"));
  }, [dispatch]);

  const handleBack = useCallback(() => {
    const browser = detect();
    console.log("browser", JSON.stringify(browser));

    if (simPage) {
      history.push("/");
    } else {
      if (browser?.name === "ios-webview") {
        //@ts-ignore
        return webkit.messageHandlers.back.postMessage({});
      }
      //@ts-ignore
      if (browser?.name === "chromium-webview" && window.Sim?.back) {
        //@ts-ignore
        return window.Sim.back();
      }
      history.goBack();
    }
  }, [history, simPage]);

  return (
    <form onSubmit={handleSubmit}>
      <div className={classes.root}>
        <div className={classes.title}>
          <Typography
            category="Default"
            variant={mobile ? "H2" : "H1"}
            className={classes.marginB4}
          >
            {t("Enter BlackVue SIM_")}
          </Typography>
        </div>
        <div className={classes.title}>
          <Typography
            category="Default"
            variant="Body"
            className={classes.subTitle}
            // style={{ maxWidth: 502 }}
          >
            {t("ICCID number is_")}
          </Typography>
        </div>

        <div className={classes.iccidWrap}>
          <div className={classes.inputTitle}>
            <Typography
              category="Default"
              variant="H6"
              htmlColor={LightColors.primary["7"]}
            >
              {t("ICCID number")}
            </Typography>
          </div>
          <div className={classes.iccidDiv}>
            <div className={classes.iccidDefaultNumber}>
              <Typography
                category="Default"
                variant="Body"
                htmlColor={LightColors.primary["2"]}
              >
                8 9 4 6 4 2
              </Typography>
            </div>

            <Field
              name="iccid"
              t={t}
              component={renderEmailField}
              error={error && t(error)}
              autoFocus
              placeholder={t(`n digit`, {
                a: 14,
              })}
              outlinedInputClassName={classes.inputBackStyle}
            />
          </div>
        </div>
        <div className={classes.imgDiv}>
          <img
            srcSet="/images/addSim.png 1x"
            alt="simImage"
            style={{ width: mobile ? "89%" : "100%", maxWidth: 444 }}
          />
        </div>

        <div className={classes.btnDiv}>
          <Button
            color="primary"
            fullWidth={mobile}
            onClick={handleAddSim}
            className={classes.nextBtn}
            loading={loading}
          >
            {t("Next")}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            fullWidth={mobile}
            onClick={handleBack}
          >
            {t("Back")}
          </Button>
        </div>
      </div>
    </form>
  );
};

const AddSIMForm = reduxForm<IAddSimForm, IAddSimProps>({
  form: "AddSIMForm",
  enableReinitialize: true,
})(AddSIM);

export default connect<any, any, IAddSimProps>((state, props) => {
  return {
    initialValues: { iccid: props.defaultIccid?.substring(6) },
  };
})(AddSIMForm);
