import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Container,
  IconButton,
  LightColors,
  Typography,
} from "@thingsw/pitta-design-system";

import {
  CircularProgress,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import {
  CreateTokenCardData,
  loadStripe,
  Stripe,
  StripeCardElement,
} from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
// import { useLocation } from "react-router-dom";
import * as yup from "yup";
import {
  setSubmitFailed,
  startSubmit,
  stopSubmit,
  SubmissionError,
  submit,
} from "redux-form";
import _ from "lodash";
import { SERVER_URI, STRIPE_API_KEY } from "../contants/Server";
import { useHistory, useLocation, useRouteMatch } from "react-router";
import BillingForm from "@thingsw/pitta-design-system/dist/components/BillingForm";
import clsx from "clsx";
import { PaymentMethod, planAmount, Simcard } from "../types";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../features/store";
import { USER } from "../features/User/slice";
import axios, { AxiosError } from "axios";
import { generateAuthToken } from "../utils/Auth";
import moment from "moment";
import { getBrandName, getCardIcon } from "../utils/Subscription";
import { ScrollBar } from "../components/ScrollBar";

import ControlPointIcon from "@material-ui/icons/ControlPoint";
import CreditCardIcon from "@material-ui/icons/CreditCard";
import LockIcon from "@material-ui/icons/Lock";
import { calculateProated } from "../utils/Subscription";

const schema = yup.object().shape({
  name: yup.string().trim().required("Enter card name"),
  address_line1: yup.string().trim().required("Enter address"),
  address_city: yup.string().trim().required("Enter the city"),
  address_country: yup
    .object()
    .shape({
      key: yup.string(),
      value: yup.string(),
    })
    .required("Select country")
    .test(
      "notSelect",
      "Select country",
      (item: { key?: string; value?: string }) => {
        return item.key && item.key !== "country";
      }
    ),
});

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: LightColors.primary["0"],
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(var(--vh, 1vh) * 100 - 107px - 56px - 90px)",
    [theme.breakpoints.up("md")]: {
      minHeight: "calc(var(--vh, 1vh) * 100 - 107px - 56px - 76px)",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 0,
  },
  billingDiv: {
    width: "100%",
    padding: theme.spacing(2, 2, 5.625),
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      width: "60%",
      padding: 0,
      marginTop: theme.spacing(9) - 2,
    },
    [theme.breakpoints.up("md")]: {
      width: "auto",
      marginLeft: theme.spacing(10) + 3,
    },
  },
  title: {
    display: "flex",
  },
  formDiv: {
    display: "flex",
    flexDirection: "column-reverse",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  marginB5: {
    marginBottom: theme.spacing(5),
  },
  paymentTitile: {
    marginBottom: theme.spacing(5) - 3,
  },
  errorDiv: {
    width: "100%",
  },
  marginR25: {
    marginRight: theme.spacing(3) + 1,
  },
  marginR4: {
    marginRight: theme.spacing(4),
  },
  headerTextDiv: {
    display: "flex",
    padding: theme.spacing(1, 1, 0, 6.375),
    paddingLeft: 51,
  },
  flexBetween: {
    display: "flex",
    justifyContent: "space-between",
  },
  fleetPlan: {
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    marginLeft: theme.spacing(4),
    padding: theme.spacing(2.125, 2, 2.25),
    backgroundColor: `${LightColors.primary["6"]}73`,
    borderRadius: 4,
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(3, 0, 3.875),
    },
    [theme.breakpoints.up("md")]: {
      minWidth: 320,
      maxWidth: 320,
      marginTop: -8,
      padding: theme.spacing(2),
      marginLeft: 32,
    },
  },
  fleetPlanPayment: {
    margin: theme.spacing(6, 0, 4.25),
    [theme.breakpoints.up("md")]: {
      marginTop: 6,
      marginLeft: theme.spacing(4),
    },
  },
  fleetPlanTitle: {
    marginBottom: theme.spacing(1) - 2,
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(1) - 3,
    },
  },
  marginB3: {
    marginBottom: theme.spacing(3),
  },
  paddingB14: {
    paddingBottom: 14,
  },
  paddingB2: {
    paddingBottom: theme.spacing(2),
  },
  paddingT2: {
    paddingTop: theme.spacing(2),
  },
  divider: {
    display: "flex",
    borderBottom: `1px solid ${LightColors.primary["5"]}`,
  },
  btnStyle: {
    marginTop: 11,
    paddingRight: theme.spacing(5),
    [theme.breakpoints.up("md")]: {
      marginTop: 27,
    },
  },
  noButton: {
    display: "none",
  },
  addressDetailDiv: {
    marginBottom: 19,
  },

  regionStyle: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: 24,
    },
  },
  // mantis - 8396 디자인 변경으로 인한 수정
  zipStyle: {
    marginBottom: 19,
  },
  loadingDiv: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
  cardSelectDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: theme.spacing(1, 0, 0),
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      marginTop: theme.spacing(1) - 3,
    },
  },
  cardDivider: {
    width: 32,
  },
  cardDiv: {
    width: 343,
    padding: theme.spacing(3.375, 3.375, 2.75),
    border: `2px solid ${LightColors.primary["7"]}`,
    borderRadius: 12,
    marginBottom: 19,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(3.375, 4.25, 2.75, 3.75),
      marginBottom: 0,
      width: 352,
    },
  },
  grayCardDiv: {
    border: `2px solid #F8F8F8`,
    backgroundColor: "#F8F8F8",
  },
  cardIcon: {
    width: "auto",
    height: 31,
    marginBottom: 34,
    [theme.breakpoints.up("md")]: {
      // marginBottom: 37,
    },
  },
  cardSmallIcon: {
    width: "auto",
    height: 22,
  },
  cardSmallDiv: {
    minWidth: 63,
    height: 40,
    border: `1px solid ${LightColors.primary["5"]}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: 4,
    marginRight: 8,
    marginBottom: 8,
    "&:last-child": {
      marginRight: 0,
    },
    [theme.breakpoints.up("md")]: {
      width: 67,
      height: 56,
      marginBottom: 16,
      marginRight: 0,
      "&:last-child": {
        marginBottom: 0,
      },
      "&:first-child": {
        marginTop: 4,
      },
    },
  },
  cardSmallSelected: {
    backgroundColor: LightColors.primary["10"],
    borderColor: LightColors.primary["7"],
  },
  cardNumDiv: {
    width: 264,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(5),
  },
  cardNameDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  newCardDiv: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 4,
    border: " 1px solid #D4D4D5",
    padding: theme.spacing(2.125, 2),
    margin: theme.spacing(3, 0, 5),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4.75, 0),
      // padding: theme.spacing(2.25, 2),
    },
  },
  selectNewCardDiv: {
    border: `2px solid ${LightColors.primary["7"]}`,
    padding: theme.spacing(2, 1.875, 1.875, 2),
    margin: theme.spacing(4, 0, 6),
    [theme.breakpoints.up("md")]: {
      margin: theme.spacing(4.75, 0, 6),
      padding: theme.spacing(1.875, 1.875, 2.125, 2),
    },
  },
  newCardText: {
    margin: theme.spacing(0, 1.625),
  },
  scrollbar: {
    flex: 1,
    display: "flex",
    overflow: "auto",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxHeight: 211,
      flexDirection: "column",
    },
  },
  scrollbarDiv: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
  },
  newCardBillingformDiv: {
    marginTop: 27,
    [theme.breakpoints.up("md")]: {
      marginTop: 26,
    },
  },
}));

const stripePromise = loadStripe(STRIPE_API_KEY);

const BillingScreen = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const location = useLocation<{
    plan: string;
    simcard: Simcard;
    nextBillingDate: string;
    activatedDate: string;
  }>();
  const { userId, email, user_token, token_type } = useSelector(
    (state: RootState) => state[USER]
  );
  const history = useHistory();
  const { path } = useRouteMatch();

  const [loadingPayments, setLoadingPayments] = useState(true);
  const [loading, setLoading] = useState(false);
  const [openNewCard, setOpenNewCard] = useState(false);
  const [stripe, setStripe] = useState<Stripe>();
  const [cardElement, setCardElement] = useState<StripeCardElement>();
  const [payments, setPayments] = useState<{
    count: number;
    payments: PaymentMethod[];
  }>({ count: 0, payments: [] });
  const [payment, setPayment] = useState<PaymentMethod>();

  const activatedDate = useMemo(() => {
    return moment.utc(location.state.activatedDate);
  }, [location.state.activatedDate]);

  const isFreeTrial = useMemo(() => {
    // 오늘날짜로부터 전달 1일이 마지막 free trial 적용되는 날짜
    const lastFreeTrialDay = moment.utc().subtract(1, "month").startOf("month");
    // pbr exit날짜가 free trial적용되는 날짜와 같거나 이후면, free trial 적용
    return activatedDate.isSameOrAfter(lastFreeTrialDay);
  }, [activatedDate]);

  useEffect(() => {
    const getPaymentMethods = async (
      email: string,
      user_token: string,
      token_type: string
    ) => {
      try {
        setLoadingPayments(true);
        const resp = await axios.get(`${SERVER_URI}/payments`, {
          headers: {
            Authorization: generateAuthToken(email, user_token),
            "X-Token-Type": token_type,
          },
        });
        setPayments(resp.data);
        if (resp.data.count > 0) {
          setPayment(resp.data.payments[0]);
        }
        setLoadingPayments(false);
      } catch (err) {
      } finally {
        setLoading(false);
      }
    };

    if (!(email && user_token && token_type)) return;

    getPaymentMethods(email, user_token, token_type);
  }, [email, token_type, user_token]);

  const doSubscription = useCallback(
    async (payment: { payment?: string; paymentId?: number }) => {
      if (!(email && user_token && token_type)) return;

      try {
        await axios.post(
          `${SERVER_URI}/subscriptions`,
          {
            userId,
            simcardId: location.state.simcard.id,
            ...payment,
            plan: location.state.plan, // 1GB, 3GB, 5GB
          },
          {
            headers: {
              Authorization: generateAuthToken(email, user_token),
              "X-Token-Type": token_type,
            },
          }
        );

        if (path.startsWith("/webviewer")) {
          history.push("/webviewer/subscribed", {
            ...location.state,
          });
        } else {
          history.push("/subscribed", {
            ...location.state,
          });
        }
      } catch (err) {
        const axiosErr = err as AxiosError;
        if (axiosErr.response?.status === 409) {
          setLoading(false);

          if (path.startsWith("/webviewer")) {
            history.push("/webviewer/failed", {
              ...location.state,
            });
          } else {
            history.push("/failed", {
              ...location.state,
            });
          }
        }
      }
    },
    [email, history, location.state, path, token_type, userId, user_token]
  );

  const handleCardSubmit = useCallback(() => {
    console.log("dispatch");
    dispatch(submit("BillInfoForm"));
  }, [dispatch]);

  const handleSubmit = useCallback(
    async (billingData: CreateTokenCardData) => {
      if (!email || !user_token || !token_type) return;
      setLoading(true);
      if (_.keys(billingData).length !== 7 && payment && !openNewCard) {
        doSubscription({ paymentId: payment.id });
        return;
      }

      // We don't want to let default form submission happen here,
      // which would refresh the page.
      try {
        await schema.validate(billingData, { abortEarly: false });
        if (!stripe || !cardElement) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          setLoading(false);
          return;
        }

        if (cardElement) {
          const resp = await axios.post(
            `${SERVER_URI}/payments`,
            {
              userId,
            },
            {
              headers: {
                Authorization: generateAuthToken(email, user_token),
                "X-Token-Type": token_type,
              },
            }
          );
          const payment = await stripe.confirmCardSetup(
            resp.data.clientSecret,
            {
              payment_method: {
                card: cardElement,
                billing_details: {
                  name: billingData.name,
                  address: {
                    line1: billingData.address_line1,
                    city: billingData.address_city,
                    state: billingData.address_state,
                    postal_code: billingData.address_zip,
                    //@ts-ignore
                    country: billingData.address_country.key,
                  },
                },
              },
            }
          );

          if (payment.error) {
            // Show error to your customer (for example, insufficient funds)
            console.log(payment.error.message);
            throw new SubmissionError({
              cardNumber: payment.error.code,
            });
          } else {
            // The payment has been processed!
            if (
              payment.setupIntent.status === "succeeded" &&
              payment.setupIntent.payment_method
            ) {
              // Show a success message to your customer
              // There's a risk of the customer closing the window before callback
              // execution. Set up a webhook or plugin to listen for the
              // payment_intent.succeeded event that handles any business critical
              // post-payment actions.
              await doSubscription({
                payment: payment.setupIntent.payment_method,
              });
            }
          }
        }
      } catch (err: any) {
        console.error(err);
        if (err instanceof SubmissionError) {
          throw err;
        }
        let submssionError = _.reduce(
          err.inner,
          (result, error) => {
            return { ...result, [error.path]: error.errors };
          },
          {}
        );

        //@ts-ignore
        if (!cardElement["_complete"]) {
          submssionError = {
            ...submssionError,
            cardNumber: "incomplete_number",
          };
        }
        throw new SubmissionError({
          ...submssionError,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      cardElement,
      doSubscription,
      email,
      openNewCard,
      payment,
      stripe,
      token_type,
      userId,
      user_token,
    ]
  );

  const billingForm = useMemo(() => {
    if (payment) {
      return (
        <BillingForm
          mode="sim"
          btnClassName={classes.noButton}
          regionClassName={classes.regionStyle}
          zipClassName={classes.zipStyle}
          addressDetailClassName={classes.addressDetailDiv}
          loading={loading}
          onSubmit={handleSubmit}
          onUpdateCardElement={(stripe, cardElement) => {
            //@ts-ignore
            setStripe(stripe);
            setCardElement(cardElement);
          }}
          onCardError={(error) => {
            dispatch(startSubmit("BillInfoForm"));
            dispatch(stopSubmit("BillInfoForm", { cardNumber: error }));
            dispatch(setSubmitFailed("BillInfoForm", "cardNumber"));
          }}
        />
      );
    }

    return (
      <BillingForm
        mode="sim"
        btnClassName={classes.btnStyle}
        regionClassName={classes.regionStyle}
        zipClassName={classes.zipStyle}
        loading={loading}
        onSubmit={handleSubmit}
        // mantis - 8671, 무료기간 끝난 심 subscribe로 출력
        freetrial={!isFreeTrial}
        onUpdateCardElement={(stripe, cardElement) => {
          //@ts-ignore
          setStripe(stripe);
          setCardElement(cardElement);
        }}
      />
    );
  }, [
    classes.addressDetailDiv,
    classes.btnStyle,
    classes.noButton,
    classes.regionStyle,
    classes.zipStyle,
    dispatch,
    handleSubmit,
    isFreeTrial,
    loading,
    payment,
  ]);

  const billingFormMarkup = useMemo(() => {
    if (loadingPayments) {
      return (
        <div className={classes.loadingDiv}>
          <CircularProgress />
        </div>
      );
    }
    if (payments.count > 0 && payment) {
      const cardIcon = getCardIcon(payment.brand, classes.cardIcon);
      return (
        <div>
          <div>
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={LightColors.primary["3"]}
            >
              {t("Payment method")}
            </Typography>
          </div>
          <div className={classes.cardSelectDiv}>
            <div
              className={clsx(
                classes.cardDiv,
                openNewCard && classes.grayCardDiv
              )}
            >
              <div>{cardIcon}</div>
              <div className={classes.cardNumDiv}>
                <Typography category="Default" variant="H6">
                  * * * *
                </Typography>
                <Typography category="Default" variant="H6">
                  * * * *
                </Typography>
                <Typography category="Default" variant="H6">
                  * * * *
                </Typography>
                <Typography category="Default" variant="H6">
                  {_.split(payment.last4, "").join(" ")}
                </Typography>
              </div>
              <div className={classes.cardNameDiv}>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["3"]}
                >
                  {getBrandName(payment.brand)}
                </Typography>
                <Typography
                  category="Default"
                  variant="Small"
                  htmlColor={LightColors.primary["3"]}
                >
                  {`${payment.expMonth}/${payment.expYear - 2000}`}
                </Typography>
              </div>
            </div>
            <div className={classes.cardDivider}></div>
            <ScrollBar className={classes.scrollbar}>
              <div className={classes.scrollbarDiv}>
                {_.map(payments.payments, (pm) => (
                  <div
                    key={`card-icon-${pm.id}`}
                    className={clsx(classes.cardSmallDiv, {
                      [classes.cardSmallSelected]:
                        pm.id === payment.id && !openNewCard,
                    })}
                    onClick={() => {
                      setPayment(pm);
                    }}
                  >
                    {getCardIcon(pm.brand, classes.cardSmallIcon)}
                  </div>
                ))}
              </div>
            </ScrollBar>
          </div>
          <div
            className={clsx(
              classes.newCardDiv,
              openNewCard && classes.selectNewCardDiv
            )}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CreditCardIcon htmlColor={LightColors.primary["7"]} />
                <Typography
                  category="Default"
                  variant="Body"
                  className={classes.newCardText}
                >
                  {t("New card")}
                </Typography>
              </div>
              <div>
                <IconButton onClick={() => setOpenNewCard((o) => !o)}>
                  <ControlPointIcon />
                </IconButton>
              </div>
            </div>
            <div
              className={classes.newCardBillingformDiv}
              style={{ display: openNewCard ? "block" : "none" }}
            >
              {billingForm}
            </div>
          </div>
          <div style={{ marginBottom: 50 }}>
            <Button
              fullWidth
              color="primary"
              type="submit"
              loading={loading}
              startIcon={isFreeTrial ? <LockIcon /> : ""}
              onClick={handleCardSubmit}
            >
              {/* mantis - 8671, 무료기간 끝난 심 subscribe로 출력 */}
              {isFreeTrial ? t("Try 1 month free") : t("subscribe")}
            </Button>
          </div>
        </div>
      );
    }
    return billingForm;
  }, [
    billingForm,
    classes.cardDiv,
    classes.cardDivider,
    classes.cardIcon,
    classes.cardNameDiv,
    classes.cardNumDiv,
    classes.cardSelectDiv,
    classes.cardSmallDiv,
    classes.cardSmallIcon,
    classes.cardSmallSelected,
    classes.grayCardDiv,
    classes.loadingDiv,
    classes.newCardBillingformDiv,
    classes.newCardDiv,
    classes.newCardText,
    classes.scrollbar,
    classes.scrollbarDiv,
    classes.selectNewCardDiv,
    handleCardSubmit,
    isFreeTrial,
    loading,
    loadingPayments,
    openNewCard,
    payment,
    payments.count,
    payments.payments,
    t,
  ]);

  const planNameMarkup = useMemo(() => {
    if (isFreeTrial) {
      return `${t("1GB data")} · ${t("1 month free trial_")}`;
    }
    return t("1GB data");
  }, [isFreeTrial, t]);

  const planDescriptionMarkup = useMemo(() => {
    if (!isFreeTrial) {
      return (
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
          style={{ lineHeight: 0.5 }}
          dangerouslySetInnerHTML={{
            __html: t("1 data plan will start from_", {
              a: `<strong>${moment.utc().format("MMM D, YYYY")}</strong>`,
            }),
          }}
        />
      );
    }
    if (payments.count > 0 && payment) {
      return (
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
          style={{ lineHeight: 0.5 }}
          dangerouslySetInnerHTML={{
            __html: t("1 month free_2", {
              a: `<strong>${activatedDate.format("MMM D, YYYY")}</strong>`,
            }),
          }}
        />
      );
    }
    return (
      <Typography
        category="Default"
        variant="Small"
        htmlColor={LightColors.primary["2"]}
        style={{ lineHeight: 0.5 }}
        dangerouslySetInnerHTML={{
          __html: t("Your free trial will_", {
            a: `<strong>${activatedDate.format("MMM D, YYYY")}</strong>`,
            b: `<strong>${moment(location.state?.nextBillingDate)
              .subtract(1, "d")
              .format("MMM D, YYYY")}</strong>`,
          }),
        }}
      />
    );
  }, [
    activatedDate,
    isFreeTrial,
    location.state?.nextBillingDate,
    payment,
    payments.count,
    t,
  ]);

  const billingDateMarkup = useMemo(() => {
    if (!isFreeTrial) {
      return (
        <Typography
          category="Default"
          variant="Small"
          htmlColor={LightColors.primary["2"]}
          dangerouslySetInnerHTML={{
            __html: t("Your next billing_", {
              a: `<strong>${moment
                .utc()
                .endOf("month")
                .format("MMM D, YYYY")}</strong>`,
            }),
          }}
        />
      );
    }
    return (
      <Typography
        category="Default"
        variant="Small"
        htmlColor={LightColors.primary["2"]}
        dangerouslySetInnerHTML={{
          __html: t("Your next billing_", {
            a: `<strong>${location.state?.nextBillingDate}</strong>`,
          }),
        }}
      />
    );
  }, [isFreeTrial, location.state?.nextBillingDate, t]);

  const planAmountMarkup = useMemo(() => {
    if (!isFreeTrial) {
      return calculateProated("1GB");
    }
    return planAmount["1GB"];
  }, [isFreeTrial]);

  return (
    <React.Fragment>
      <div className={classes.root}>
        {/* {error && (
          <div className={classes.errorDiv}>
            <Alerts mode="web" severity="error">
              {t(error)}
            </Alerts>
          </div>
        )} */}
        <Container className={classes.body}>
          <div className={classes.billingDiv}>
            <div className={classes.title}>
              <Typography
                category="Default"
                variant={mobile ? "H2" : "H1"}
                className={
                  mobile
                    ? ""
                    : clsx(classes.marginB5, payment && classes.paymentTitile)
                }
                style={{ marginLeft: mobile ? 0 : 3 }}
              >
                {t("Billing information")}
              </Typography>
            </div>
            <div className={classes.formDiv}>
              <div style={{ width: mobile ? "100%" : 469 }}>
                <Elements stripe={stripePromise}>{billingFormMarkup}</Elements>
              </div>

              {/* fleetPlan 설명 */}
              <div
                className={clsx(classes.fleetPlan, {
                  [classes.fleetPlanPayment]: payments.count > 0 && payment,
                })}
              >
                <Typography
                  category="Default"
                  variant="H6"
                  htmlColor={LightColors.primary["1"]}
                  className={clsx(classes.fleetPlanTitle)}
                >
                  {planNameMarkup}
                </Typography>

                <div className={classes.marginB3}>
                  <div>{planDescriptionMarkup}</div>
                  <div>{billingDateMarkup}</div>
                </div>

                <div>
                  <div
                    className={clsx(
                      classes.flexBetween,
                      mobile ? classes.paddingB14 : classes.paddingB2
                    )}
                  >
                    <Typography category="Default" variant="Small">
                      {t("1GB data plan")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Small"
                      style={{ textAlign: "right" }}
                    >
                      {planAmountMarkup} USD/mo
                    </Typography>
                  </div>
                  <div className={classes.divider}></div>
                  {isFreeTrial && (
                    <div
                      className={clsx(classes.flexBetween, classes.paddingT2)}
                    >
                      <Typography category="Default" variant="Small">
                        {t("Total after free trial")}
                      </Typography>
                      <Typography
                        category="Default"
                        variant="Small"
                        style={{ textAlign: "right" }}
                      >
                        {planAmount["1GB"]} USD/mo
                      </Typography>
                    </div>
                  )}

                  <div
                    style={{ marginBottom: 4 }}
                    className={clsx(classes.flexBetween, classes.paddingT2)}
                  >
                    <Typography category="Default" variant="SmallBold">
                      {isFreeTrial ? t("Due today") : t("Total")}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="SmallBold"
                      style={{ textAlign: "right" }}
                    >
                      {isFreeTrial ? "0.00 USD" : `${planAmountMarkup} USD/mo`}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BillingScreen;
