import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { detect } from "detect-browser";
import {
  clearLoginInfo,
  doneInit,
  loadUserInfo,
  setUserInfo,
  USER,
} from "../features/User/slice";
import AddSimScreen from "../screens/AddSimScreen";
import BillingScreen from "../screens/BillingScreen";
import { SimDetailScreen } from "../screens/SimDetailScreen";
import PricingScreen from "../screens/PricingScreen";
import SimListScreen from "../screens/SimListScreen";
import SubscribedScreen from "../screens/SubscribedScreen";
import SubscriptionPlanScreen from "../screens/SubscriptionPlanScreen";
import { WEBVIEWER_URI } from "../contants/Server";
import { RootState } from "../features/store";
import SubscriptionFailedScreen from "../screens/SubscriptionFailedScreen";
// import { useTranslation } from "react-i18next";

export const CommonRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  // const { i18n } = useTranslation();

  const { email, user_token, token_type, userId, init } = useSelector(
    (state: RootState) => state[USER]
  );

  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const savedLang = localStorage.getItem("pitta-sim-frontend:lang");
  //   const lang = params.get("lang") || savedLang || "en";

  //   console.log("CommonRoutes", lang);
  //   if (lang === "zh") {
  //     i18n.changeLanguage("sc");
  //   } else {
  //     i18n.changeLanguage(lang);
  //   }

  //   localStorage.setItem("pitta-sim-frontend:lang", lang);
  // }, [i18n, location.search]);

  useEffect(() => {
    if (!init) return;
    // 8732 - 로그인 안되어있을 경우, 링크에 포함된 요청자 메일을 포함하도록 수정
    const params = new URLSearchParams(window.location.search);
    if (
      email &&
      //8732 - url param에 email이 있는 경우, 저장되어 있는 email과 url param의 이메일이 같아야함
      (!params.has("email") || params.get("email") === email) &&
      user_token &&
      token_type
    ) {
      if (!userId) {
        dispatch(loadUserInfo());
      }
    } else {
      dispatch(clearLoginInfo());
      const browser = detect();
      if (browser?.name === "ios-webview") {
        //@ts-ignore
        return webkit.messageHandlers.logout.postMessage({});
      }
      //@ts-ignore
      if (
        browser?.name === "chromium-webview" &&
        //@ts-ignore
        window.Sim?.logout
      ) {
        //@ts-ignore
        return window.Sim.logout();
      }
      params.append("from", "sim");
      params.append(
        "returnTo",
        window.location.origin + window.location.pathname
      );
      window.location.href = `${WEBVIEWER_URI}/login?${params.toString()}`;
    }
  }, [dispatch, email, init, token_type, userId, user_token]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pemail = params.get("email");
    const puser_token = params.get("user_token");
    const ptoken_type = params.get("token_type");

    if (pemail && puser_token && ptoken_type) {
      dispatch(
        setUserInfo({
          //@ts-ignore
          email: pemail,
          //@ts-ignore
          user_token: puser_token,
          //@ts-ignore
          token_type: ptoken_type,
        })
      );
    } else {
      dispatch(doneInit());
    }
  }, [dispatch, location]);

  return (
    <Switch>
      {path === "/" && <Route path={path} exact component={SimListScreen} />}
      <Route path={"/pricing"} render={() => <PricingScreen />} />

      <Route
        path={`${path === "/" ? "" : path}/add-sim`}
        component={AddSimScreen}
      />
      <Route
        path={`${path === "/" ? "" : path}/subscription`}
        component={SubscriptionPlanScreen}
      />
      <Route
        path={`${path === "/" ? "" : path}/billing`}
        component={BillingScreen}
      />
      <Route
        path={`${path === "/" ? "" : path}/subscribed`}
        component={SubscribedScreen}
      />
      <Route
        path={`${path === "/" ? "" : path}/failed`}
        component={SubscriptionFailedScreen}
      />
      <Route
        path={`${path}:id`}
        render={() => (
          <SimDetailScreen
          // onDeleteMember={handleDeleteMember}
          />
        )}
      />
    </Switch>
  );
};
