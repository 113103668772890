import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  LightColors,
  Link,
  Modal,
  Typography,
} from "@thingsw/pitta-design-system";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Trans, useTranslation } from "react-i18next";
import { Card, useMediaQuery } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { planAmount, Simcard } from "../../types";
import axios, { AxiosError } from "axios";
import { SERVER_URI } from "../../contants/Server";
import { useSelector } from "react-redux";
import { RootState } from "../../features/store";
import { USER } from "../../features/User/slice";
import { generateAuthToken } from "../../utils/Auth";
import { ScrollBar } from "../ScrollBar";

export type PlanType = "1GB" | "3GB" | "5GB";

const useStyles = makeStyles((theme: Theme) => ({
  body: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(3, 2),
    maxWidth: 672,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up(956)]: {
      width: "100%",
      marginTop: theme.spacing(16.25),
      marginBottom: theme.spacing(16.25),
      padding: 0,
    },
  },

  modalBody: {
    maxWidth: 672,
    [theme.breakpoints.down(956)]: {
      maxWidth: "100%",
      // maxHeight: 1049,
    },
  },

  eventModalBody: {
    maxWidth: 672,
    [theme.breakpoints.down(956)]: {
      maxWidth: "100%",
      // maxHeight: 1049,
    },
  },

  modalDiv: {
    width: "100%",
    [theme.breakpoints.down(956)]: {},
  },

  cardGroup: {
    [theme.breakpoints.up(956)]: {
      display: "flex",
      justifyContent: "space-between",
    },
  },

  cardDiv: {
    display: "flex",
    textAlign: "end",
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
    cursor: "pointer",
    justifyContent: "space-between",
    padding: "18px 24px 17px 22px",
    alignItems: "center",
    marginTop: 16,

    [theme.breakpoints.up(956)]: {
      width: 191,
      height: 128,
      flexDirection: "column",
      justifyContent: "center",
      marginTop: 0,
      padding: 0,
      aliginItems: "normal",
      textAlign: "center",
    },
  },

  cardDivNone: {
    display: "flex",
    textAlign: "end",
    boxShadow:
      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)",
    borderRadius: 4,
    cursor: "pointer",
    justifyContent: "space-between",
    padding: 24,
    alignItems: "center",
    marginTop: 16,

    [theme.breakpoints.up(956)]: {
      width: 191,
      height: 128,
      flexDirection: "column",
      justifyContent: "center",
      marginTop: 0,
      padding: 0,
      aliginItems: "normal",
      textAlign: "center",
    },
  },

  titleDiv: {
    [theme.breakpoints.up(956)]: {
      padding: "12px 24px 26px",
    },
  },

  contentDiv: {
    padding: "0 0 20px",
    // overflow: "hidden",
    display: "flex",
    flexDirection: "row",
    height: "100%",

    [theme.breakpoints.up("md")]: {
      width: 672,
      // minHeight: 239,
      maxHeight: 349,
      overflow: "hidden",
    },
  },
  scrollDiv: {
    flex: 1,
    [theme.breakpoints.up("md")]: {
      maxHeight: 349,
    },
  },

  planContDiv: {
    minWidth: "100%",
    maxWidth: 343,
    padding: theme.spacing(2, 2),

    [theme.breakpoints.up("sm")]: {
      padding: "16px 32px 37px",
      maxWidth: "unset",
    },
  },

  eventPlanContDiv: {
    minWidth: "100%",
    maxWidth: 343,
    padding: theme.spacing(2, 2),

    [theme.breakpoints.up("sm")]: {
      padding: "16px 32px",
      maxWidth: "unset",
    },
  },
  contentTitle: {
    paddingBottom: "17px",
    [theme.breakpoints.up(956)]: {
      paddingBottom: "31px",
    },
  },

  currentTitle: {
    paddingBottom: "33px",
    [theme.breakpoints.up(956)]: {
      paddingBottom: "31px",
    },
  },

  select: {
    backgroundColor: `${LightColors.primary["10"]}`,
    "&:hover": {
      backgroundColor: `${LightColors.primary["10"]}`,
    },
  },

  currentStyle: {
    backgroundColor: LightColors.primary["6"],
    cursor: "default",
  },

  priceDiv: {
    [theme.breakpoints.up(956)]: {
      margin: "9px 0px 0px",
    },
  },

  planDiv: {
    [theme.breakpoints.up(956)]: {
      paddingTop: 20,
    },
  },

  currentContent: {
    display: "none",
  },

  mobileContentDiv: {
    padding: 16,
  },

  summaryCard: {
    marginTop: 4,
    display: "flex",
    justifyContent: "space-between",
    padding: "24px 22px 24px 24px",
    border: `1px solid ${LightColors.primary["3"]}59`,
    boxShadow: "none",
    flexDirection: "column",

    [theme.breakpoints.up(956)]: {
      padding: "26px 24px 19px 23px",
      width: 241,
      boxSizing: "border-box",
      display: "revert",
      justifyContent: "normal",
    },
  },

  summaryPlanPrice: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 20,
    [theme.breakpoints.up("md")]: {
      flexDirection: "column",
    },
  },

  arrowDiv: {
    textAlign: "center",
    [theme.breakpoints.up("md")]: {
      textAlign: "revert",
      marginTop: 32,
      padding: theme.spacing(0, 3),
    },
  },
  summaryPrice: {
    textAlign: "end",
    marginBottom: 16,
    marginTop: 4,

    [theme.breakpoints.up(956)]: {
      padding: "12px 0 21px",
      textAlign: "revert",
      marginBottom: 0,
      marginTop: 0,
    },
  },

  summaryDiv: {
    paddingTop: 63,
    paddingBottom: 4,

    [theme.breakpoints.up(956)]: {
      paddingTop: 76,
      paddingBottom: 0,
    },
  },

  manageSummaryDiv: {
    paddingBottom: 4,
    [theme.breakpoints.up(956)]: {
      paddingBottom: 0,
    },
  },

  summaryDate: {
    display: "flex",
    justifyContent: "end",

    [theme.breakpoints.up(956)]: {},
  },

  summaryCardDiv: {
    [theme.breakpoints.up(956)]: {
      display: "flex",
      alignItems: "center",
      padding: "0 31px",
      justifyContent: "center",
    },
  },

  actionClassName: {
    [theme.breakpoints.down(956)]: {
      padding: "16px 14px 12px 16px",
    },
  },

  eventContentDiv: {
    padding: 16,
    overflow: "hidden",

    [theme.breakpoints.up(956)]: {
      padding: "0 32px 58px",
      height: 188,
    },
  },

  manageContentDiv: {
    padding: 16,
    overflow: "hidden",

    [theme.breakpoints.up(956)]: {
      padding: "0 32px 48px",
    },
  },

  bottomContent: {
    padding: theme.spacing(4, 0, 2.5),
  },
  cancelDiv: {
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: theme.spacing(4, 0, 3, 0),
  },
  cancelLine: {
    borderTop: `1px solid ${LightColors.primary["6"]}`,
    position: "absolute",
    left: 0,
    right: 0,
    zIndex: 1,
  },
  orTextDiv: {
    backgroundColor: LightColors.primary["0"],
    zIndex: 2,
    padding: theme.spacing(0, 1),
  },
  cancelText: {
    color: LightColors.primary["7"],
    "&:hover": {
      color: LightColors.primary["8"],
      cursor: "pointer",
    },
  },
}));

interface IUpgradeSelectModal {
  open: boolean;
  onClose: React.MouseEventHandler<HTMLButtonElement>;
  onClickPositive: () => void;
  onClickCancel?: () => void;
  currentSimCard?: Simcard;
  mode: "upgrade" | "manage" | "resubscribe";
}

export const UpgradeSelectModal = (props: IUpgradeSelectModal) => {
  const {
    open,
    onClose,
    currentSimCard,
    mode,
    onClickCancel,
    onClickPositive,
  } = props;

  const classes = useStyles();
  const theme = useTheme() as Theme;
  const { t } = useTranslation();
  const mobile = useMediaQuery(theme.breakpoints.down(956));

  const summaryRef = useRef<HTMLDivElement>(null);
  const scrollRef = createRef<HTMLElement>();

  const { email, user_token, token_type } = useSelector(
    (state: RootState) => state[USER]
  );

  const [loading, setLoading] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const [selectPlan, setSelectPlan] = useState<PlanType>();

  const currentPlan = useMemo(
    () => currentSimCard?.subscription?.planName,
    [currentSimCard]
  );

  // const getPrice = useMemo(() => {
  //   if (currentPlan === "1GB") {
  //     return "10.00";
  //   } else if (currentPlan === "3GB") {
  //     return "23.00";
  //   } else if (currentPlan === "5GB") {
  //     return "50.00";
  //   }
  // }, [currentPlan]);

  const subscriptionEndDate = useMemo(() => {
    // 무료 사용기간 계산
    let freetrial = moment.utc().add(1, "month").endOf("month");
    // pbr exit을 기준으로 free trial계산/ pbr exit이 없는 경우 chargedAt으로 계산
    if (currentSimCard?.pbrExitDate || currentSimCard?.chargedAt) {
      freetrial = moment
        .utc(currentSimCard.pbrExitDate || currentSimCard?.chargedAt)
        .add(1, "month")
        .endOf("month");
    }
    const endOfThisMonth = moment.utc().endOf("month");
    // 무료사용 기간이 이번달 말 이후면 무료사용 기간이
    if (freetrial.isAfter(endOfThisMonth)) {
      return freetrial;
    }
    return endOfThisMonth;
  }, [currentSimCard?.chargedAt, currentSimCard?.pbrExitDate]);

  const renderCardBox = useCallback(
    (
      planName: string,
      // planPrice: string,
      choosePlan: PlanType,
      currentType: Boolean
    ) => {
      const actived = mode !== "resubscribe" && currentType === true;
      return (
        <Card
          className={clsx(classes.cardDiv, {
            [classes.select]: selectPlan === choosePlan,
            [classes.currentStyle]: actived,
            [classes.cardDivNone]: !actived,
          })}
          onClick={() => {
            if (
              (currentPlan !== choosePlan && currentType === false) ||
              mode === "resubscribe"
            ) {
              if (mode !== "manage") {
                setShowNext(true);
              }
              setSelectPlan(choosePlan);
            }
          }}
        >
          <Typography
            category="Default"
            variant="H2"
            htmlColor={LightColors.primary["1"]}
            className={classes.planDiv}
          >
            {t(planName)}
          </Typography>
          <div>
            <div className={classes.priceDiv}>
              <Typography
                category="Default"
                variant="H5"
                htmlColor={LightColors.primary[actived ? "2" : "7"]}
              >
                {planAmount[planName as PlanType]} USD
                {/* {t(planPrice)} */}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary[actived ? "2" : "7"]}
              >
                /{t("mo")}
              </Typography>
            </div>
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={LightColors.primary["2"]}
              style={
                !mobile
                  ? {
                      visibility: currentType === true ? "visible" : "hidden",
                    }
                  : { display: currentType === true ? "revert" : "none" }
              }
            >
              {t("Current plan")}
            </Typography>
          </div>
        </Card>
      );
    },
    [
      classes.cardDiv,
      classes.cardDivNone,
      classes.currentStyle,
      classes.planDiv,
      classes.priceDiv,
      classes.select,
      currentPlan,
      mobile,
      mode,
      selectPlan,
      t,
    ]
  );

  const handleResubscribed = useCallback(async () => {
    try {
      if (!email || !user_token || !token_type) return;
      setLoading(true);
      await axios.put(
        `${SERVER_URI}/subscriptions/${currentSimCard?.subscription?.id}/resubscribe`,
        {},
        {
          headers: {
            Authorization: generateAuthToken(email, user_token),
            "X-Token-Type": token_type,
          },
        }
      );
      onClickPositive?.();
    } catch (err: any) {
      const axiosError = err as AxiosError;
      console.error(axiosError.response?.data.error);
    } finally {
      setLoading(false);
    }
  }, [currentSimCard, email, onClickPositive, token_type, user_token]);

  const handleChangePlan = useCallback(async () => {
    try {
      if (!email || !user_token || !token_type) return;
      setLoading(true);
      await axios.put(
        `${SERVER_URI}/subscriptions/${currentSimCard?.subscription?.id}/changePlan`,
        { plan: selectPlan },
        {
          headers: {
            Authorization: generateAuthToken(email, user_token),
            "X-Token-Type": token_type,
          },
        }
      );
      onClickPositive?.();
    } catch (err) {
      const axiosError = err as AxiosError;
      console.error(axiosError.response?.data.error);
    } finally {
      setLoading(false);
    }
  }, [
    currentSimCard,
    email,
    onClickPositive,
    selectPlan,
    token_type,
    user_token,
  ]);

  const cancelMarkup = useMemo(() => {
    return (
      <>
        <div className={classes.cancelDiv}>
          <div className={classes.cancelLine}></div>
          <div className={classes.orTextDiv}>
            <Typography category="Default" variant="Small">
              {t("OR")}
            </Typography>
          </div>
        </div>
        <Typography category="Default" variant="Body">
          <Trans
            t={t}
            components={{
              a: (
                /* 스타일 수정하기 */
                <Link
                  category="Default"
                  variant="Body"
                  mode="anchor"
                  style={{ color: LightColors.primary["8"] }}
                  onClick={() => {
                    onClickCancel?.();
                  }}
                >
                  Cancel subscription
                </Link>
              ),
            }}
          >
            If you want to cancel_
          </Trans>
        </Typography>
      </>
    );
  }, [
    classes.cancelDiv,
    classes.cancelLine,
    classes.orTextDiv,
    onClickCancel,
    t,
  ]);

  const planSelectMarkup = useMemo(() => {
    if (mode === "manage" && selectPlan && showNext) {
      return;
    }
    return (
      <>
        <div className={classes.contentTitle}>
          <Typography
            category="Default"
            variant="H3"
            htmlColor={LightColors.primary["1"]}
          >
            {t("Choose best plan_")}
          </Typography>
        </div>
        <div className={classes.cardGroup}>
          {renderCardBox("1GB", "1GB", currentPlan === "1GB")}
          {renderCardBox("3GB", "3GB", currentPlan === "3GB")}
          {renderCardBox("5GB", "5GB", currentPlan === "5GB")}
        </div>
        {mode === "manage" && cancelMarkup}
      </>
    );
  }, [
    cancelMarkup,
    classes.cardGroup,
    classes.contentTitle,
    currentPlan,
    mode,
    renderCardBox,
    selectPlan,
    showNext,
    t,
  ]);

  const planSummaryMarkup = useMemo(() => {
    if (selectPlan && showNext)
      return (
        <div
          className={clsx({
            [classes.summaryDiv]: mode === "upgrade" || mode === "resubscribe",
            [classes.manageSummaryDiv]: mode === "manage",
          })}
        >
          <div className={classes.currentTitle} ref={summaryRef} id="summery">
            <Typography
              category="Default"
              variant="H3"
              htmlColor={LightColors.primary["1"]}
            >
              {t("Plan summary")}
            </Typography>
          </div>
          <div className={classes.summaryCardDiv}>
            <div>
              <Typography
                category="Default"
                variant="SmallBold"
                htmlColor={LightColors.primary["2"]}
              >
                {t("Current plan")}
              </Typography>
              <Card className={classes.summaryCard}>
                <div className={classes.summaryPlanPrice}>
                  <Typography
                    category="Default"
                    variant="H4"
                    htmlColor={LightColors.primary["1"]}
                    style={{ paddingBottom: 12 }}
                  >
                    {currentPlan} {t("plan")}
                  </Typography>

                  <div>
                    <Typography
                      category="Default"
                      variant="H6"
                      htmlColor={LightColors.primary["1"]}
                    >
                      {planAmount[currentPlan as PlanType]} USD
                      {/* {getPrice} {t("USD")} */}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["1"]}
                    >
                      /{t("mo")}
                    </Typography>
                  </div>
                </div>

                <div>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    htmlColor={LightColors.primary["3"]}
                    className={classes.summaryDate}
                    dangerouslySetInnerHTML={{
                      __html: t("Ends on_", {
                        a: `${subscriptionEndDate.format("MMM D, YYYY")}`,
                      }),
                    }}
                  ></Typography>
                </div>
              </Card>
            </div>
            <div className={classes.arrowDiv}>
              {mobile ? (
                <ExpandMoreIcon fontSize="large" />
              ) : (
                <ArrowForwardIosIcon />
              )}
            </div>
            <div>
              <Typography
                category="Default"
                variant="SmallBold"
                htmlColor={LightColors.primary["7"]}
              >
                {t("Next plan")}
              </Typography>
              <Card
                className={classes.summaryCard}
                style={{ border: `2px solid ${LightColors.primary["7"]}` }}
              >
                <div className={classes.summaryPlanPrice}>
                  <Typography
                    category="Default"
                    variant="H4"
                    htmlColor={LightColors.primary["1"]}
                    style={{ paddingBottom: 12 }}
                  >
                    {t(`${selectPlan} plan`)}
                  </Typography>
                  <div>
                    <Typography
                      category="Default"
                      variant="H6"
                      htmlColor={LightColors.primary["1"]}
                    >
                      {planAmount[selectPlan as PlanType]} USD
                      {/* {selectPlan === "1GB"
                        ? "10.00 USD"
                        : selectPlan === "3GB"
                        ? "23.00 USD"
                        : "50.00 USD"} */}
                    </Typography>
                    <Typography
                      category="Default"
                      variant="Caption"
                      htmlColor={LightColors.primary["1"]}
                    >
                      /{t("mo")}
                    </Typography>
                  </div>
                </div>
                <div>
                  <Typography
                    category="Default"
                    variant="BodyBold"
                    htmlColor={LightColors.primary["7"]}
                    className={classes.summaryDate}
                    dangerouslySetInnerHTML={{
                      __html: t("Start from_", {
                        a: `${moment(subscriptionEndDate)
                          .add(1, "month")
                          .startOf("month")
                          .format("MMM D, YYYY")}`,
                      }),
                    }}
                  ></Typography>
                </div>
              </Card>
            </div>
          </div>
          <div className={classes.bottomContent}>
            <Typography
              category="Default"
              variant={"Body"}
              htmlColor={LightColors.primary["1"]}
              dangerouslySetInnerHTML={{
                __html: t("Your subscription will_", {
                  a: `<strong>${moment(subscriptionEndDate)
                    .add(1, "month")
                    .startOf("month")
                    .format("MMM D, YYYY")}</strong>`,
                }),
              }}
            ></Typography>
          </div>
        </div>
      );
  }, [
    selectPlan,
    showNext,
    classes.summaryDiv,
    classes.manageSummaryDiv,
    classes.currentTitle,
    classes.summaryCardDiv,
    classes.summaryCard,
    classes.summaryPlanPrice,
    classes.summaryDate,
    classes.arrowDiv,
    classes.bottomContent,
    mode,
    t,
    currentPlan,
    subscriptionEndDate,
    mobile,
  ]);

  useEffect(() => {
    if (planSummaryMarkup) {
      if (mobile) {
        summaryRef.current?.scrollIntoView();
      } else {
        scrollRef.current?.scrollTo({ top: 1000 });
      }
    }
  }, [mobile, planSummaryMarkup, scrollRef]);

  const handlePositive = useCallback(() => {
    if (mode === "resubscribe") {
      return handleResubscribed();
    }
    if (mode === "manage") {
      if (showNext) {
        return handleChangePlan();
      } else {
        return setShowNext(true);
      }
    }

    handleChangePlan();
  }, [handleChangePlan, handleResubscribed, mode, showNext]);

  const rbuttonMarkup = useMemo(() => {
    if (mode === "manage") {
      if (showNext) {
        return t("Change");
      } else {
        return t("Next");
      }
    }
    if (mode === "resubscribe") {
      return t("Subscribe");
    }
    return t("Upgrade");
  }, [mode, showNext, t]);

  const headingMarkup = useMemo(() => {
    if (mode === "manage") {
      return t("Manage plan");
    }
    if (mode === "resubscribe") {
      return t("Resubscribe plan");
    }
    return t("Upgrade plan");
  }, [mode, t]);

  return (
    <Modal
      mobile={mobile}
      titleClassName={classes.titleDiv}
      contentClassName={clsx(classes.contentDiv)}
      className={clsx(classes.modalBody, {
        [classes.eventModalBody]: selectPlan && showNext,
        [classes.eventModalBody]: mode === "manage",
      })}
      open={open}
      onClose={onClose}
      onClickPositive={handlePositive}
      onClickNegative={() => setShowNext(false)}
      heading={headingMarkup}
      content={
        <ScrollBar
          className={classes.scrollDiv}
          scrollableNodeProps={{ ref: scrollRef }}
        >
          <div
            className={clsx(classes.planContDiv, {
              [classes.eventPlanContDiv]: selectPlan && showNext,
            })}
          >
            {planSelectMarkup}
            {planSummaryMarkup}
          </div>
        </ScrollBar>
      }
      RButtonDisabled={selectPlan === undefined}
      // current plan이 5GB면 R버튼 hidden
      RButton={rbuttonMarkup}
      LButton={mode === "manage" && showNext ? t("Back") : undefined}
      close
      fullSize={mobile}
      actionClassName={classes.actionClassName}
      loading={loading}
    />
  );
};
