import React, { useMemo } from "react";
import { LightColors, Typography } from "@thingsw/pitta-design-system";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import moment from "moment";
import clsx from "clsx";
import _ from "lodash";
import { Notification } from "../types";
import { ScrollBar } from "./ScrollBar";

const useStyles = makeStyles((theme: Theme) => ({
  headerCell: {
    position: "sticky",
    top: 0,
    padding: "8px 0px 0px 8px",
    lineHeight: "0",
    backgroundColor: LightColors.primary["0"],
    [theme.breakpoints.up("sm")]: {
      padding: "8px 0px 1px 8px",
    },

    [theme.breakpoints.up("md")]: {
      padding: "8px 0px 0px 8px",
    },
  },

  bodyCell: {
    padding: "7px 0px 9px 8px ",
    [theme.breakpoints.up("sm")]: {
      padding: "7px 0px 7px 8px ",
    },
  },

  tableHeader: {
    maxHeight: 24,
  },

  headerWidth: {
    minWidth: 140,
  },

  bodyWidth: {
    minWidth: 140,
    width: 140,
  },

  bodyHeight: {
    height: "100%",
    [theme.breakpoints.up("sm")]: {
      overflowY: "auto",
    },
  },

  tableCard: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 32px",
    border: `1px solid ${LightColors.primary["5"]}`,
    borderRadius: 5,
    boxShadow: "none",
    height: "100%",
    minHeight: 376,
    maxHeight: 376,
    flex: 1,

    [theme.breakpoints.up("sm")]: {
      minHeight: 296,
      maxHeight: 296,
    },

    [theme.breakpoints.up("md")]: {
      minHeight: 296,
      maxHeight: 296,
    },
  },
  width100: {
    width: "100%",
  },
  emptyDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: 250,
    [theme.breakpoints.up("sm")]: {
      minHeight: 170,
    },
  },
}));

interface NotificationsProps {
  notifications?: Notification[];
}

export const Notifications = (props: NotificationsProps) => {
  const { t } = useTranslation();
  const { notifications } = props;
  const classes = useStyles(props);
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down("xs"));

  const tableMarkup = useMemo(() => {
    return (
      <>
        <TableContainer
          component={Paper}
          elevation={0}
          className={classes.bodyHeight}
        >
          <ScrollBar style={{ maxHeight: mobile ? 375 : 204 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={clsx(classes.headerCell, classes.headerWidth)}
                  >
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Date")}
                    </Typography>
                  </TableCell>
                  <TableCell
                    className={classes.headerCell}
                    style={{ width: "100%" }}
                  >
                    <Typography
                      category="Default"
                      variant="Small"
                      htmlColor={LightColors.primary["2"]}
                    >
                      {t("Notification")}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody
                style={{
                  overflow: "auto",
                }}
              >
                {_.map(notifications, (noti) => (
                  <TableRow key={`notification-${noti.id}`}>
                    <TableCell
                      className={clsx(classes.bodyCell, classes.bodyWidth)}
                    >
                      <Typography category="Default" variant="Small">
                        {moment.utc(noti.createdAt).format("MMM D, YYYY hh:mm")}
                      </Typography>
                    </TableCell>
                    <TableCell
                      className={clsx(classes.bodyCell, classes.width100)}
                    >
                      <Typography
                        category="Default"
                        variant="Small"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {noti.message}
                      </Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </ScrollBar>
        </TableContainer>

        {notifications && notifications.length === 0 && (
          <div className={classes.emptyDiv}>
            <Typography
              category="Default"
              variant="Body"
              htmlColor={LightColors.primary["2"]}
            >
              {t("No notifications yet")}
            </Typography>
          </div>
        )}
      </>
    );
  }, [
    classes.bodyCell,
    classes.bodyHeight,
    classes.bodyWidth,
    classes.emptyDiv,
    classes.headerCell,
    classes.headerWidth,
    classes.width100,
    mobile,
    notifications,
    t,
  ]);

  return (
    <Card className={classes.tableCard}>
      <div style={{ marginBottom: 14 }}>
        <Typography category="Default" variant="H6">
          {t("Notifications")}
        </Typography>
      </div>

      {tableMarkup}
    </Card>
  );
};
