export let SERVER_URI: string = "http://localhost:3000/dev";
if (process.env.REACT_APP_ENV === "tw") {
  SERVER_URI = "http://localhost:3000/dev";
} else if (process.env.REACT_APP_ENV === "qa") {
  SERVER_URI =
    "https://pnscyq5m27.execute-api.ap-northeast-2.amazonaws.com/dev";
} else if (process.env.REACT_APP_ENV === "stage") {
  SERVER_URI = "https://6k30m569yb.execute-api.us-west-1.amazonaws.com/stage";
} else if (process.env.REACT_APP_ENV === "production") {
  SERVER_URI = "https://p31w81y028.execute-api.us-west-1.amazonaws.com/prod";
}

export let WEBVIEWER_URI: string = "https://thingsw-alpha.blackvuecloud.com";
if (process.env.REACT_APP_ENV === "qa") {
  WEBVIEWER_URI = "https://thingsw-alpha.blackvuecloud.com";
  // WEBVIEWER_URI = "http://localhost:3000";
} else if (process.env.REACT_APP_ENV === "stage") {
  WEBVIEWER_URI = "https://thingsw.blackvuecloud.com";
} else if (process.env.REACT_APP_ENV === "production") {
  WEBVIEWER_URI = "https://www.blackvuecloud.com";
}

export let STRIPE_API_KEY: string;
if (process.env.REACT_APP_ENV === "production") {
  STRIPE_API_KEY = "pk_live_sQMEB5KXacXq1HLdJv36FDd100ZGI7aNZm";
} else {
  STRIPE_API_KEY = "pk_test_30Ah19N51uj4ErDcR3beBiZU00ydngcn0Z";
}

export let API_GATEWAY_URI: string = "https://alpha-gateway.blackvuecloud.com";
if (process.env.REACT_APP_ENV === "qa") {
  API_GATEWAY_URI = "https://alpha-gateway.blackvuecloud.com";
} else if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "stage"
) {
  API_GATEWAY_URI = "https://gateway.blackvuecloud.com";
}

export let API_SERVER_URI: string = "https://alpha1.blackvuecloud.com";
if (process.env.REACT_APP_ENV === "qa") {
  API_SERVER_URI = "https://alpha1.blackvuecloud.com";
} else if (
  process.env.REACT_APP_ENV === "production" ||
  process.env.REACT_APP_ENV === "stage"
) {
  API_SERVER_URI = "https://api.blackvuecloud.com";
}
