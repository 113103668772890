import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";
import MuiTable from "@material-ui/core/Table";

import MuiTableContainer from "@material-ui/core/TableContainer";

import { withStyles } from "@material-ui/styles";
import _ from "lodash";
import React, { useCallback, useMemo } from "react";

import moment from "moment";
import clsx from "clsx";
import { TFunction, Trans } from "react-i18next";
import {
  Button,
  DataUsage,
  IconButton,
  LightColors,
  Tooltip,
  Typography,
} from "@thingsw/pitta-design-system";
import MuiTableBody from "@material-ui/core/TableBody";
import MuiTableCell from "@material-ui/core/TableCell";
import MuiTableHead from "@material-ui/core/TableHead";
import MuiTableRow from "@material-ui/core/TableRow";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import FilterListIcon from "@material-ui/icons/FilterList";
import SimCardIcon from "@material-ui/icons/SimCard";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

import { Simcard } from "../types";
import {
  checkCanceled,
  checkPaused,
  checkSubscribed,
  checkTerminated,
} from "../utils/Subscription";
import { ScrollBar } from "./ScrollBar";

const TableHeaderCell = withStyles((theme: Theme) => ({
  root: {
    border: "none",
    borderTop: `1px solid ${LightColors.primary["6"]}`,
  },
  head: {
    padding: theme.spacing(1, 1.5, 1.375),
    "&:first-child": {
      padding: "5px 5px 5px 56px",
    },
    "&:nth-child(2)": {
      padding: theme.spacing(1, 1.125, 1.375),
    },
    color: LightColors.primary["2"],
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "0.875rem",
    lineHeight: 1.5,
    letterSpacing: undefined,
  },
}))(MuiTableCell);

const TableCell = withStyles((theme: Theme) => ({
  root: {
    border: "none",
    boxShadow: `0px -1px 0px ${LightColors.primary["6"]}`,
    ...(theme.direction === "rtl"
      ? { textAlign: "right" }
      : { textAlign: "left" }),
  },
  body: {
    padding: "12px 12px 13px 12px",
    "&:first-child": {
      padding: "21px 12px 21px 21px",
      maxWidth: 225,
    },
    color: LightColors.primary["1"],
    fontWeight: 400,
    fontStyle: "normal",
    fontSize: "1rem",
    lineHeight: 1.5,
    letterSpacing: undefined,
  },
}))(MuiTableCell);

const useStyles = makeStyles((theme: Theme) => ({
  simcardsTablewrap: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
    overflowX: "unset",
    minWidth: 1142,
  },
  simcardsTable: {
    backgroundColor: LightColors.primary["0"],
  },
  deleteDiv: {
    padding: 5,
  },
  moreDiv: {
    width: 24,
  },
  moreBtn: {
    padding: 0,
  },
  rowHover: {
    "&:hover": {
      backgroundColor: `${LightColors.primary["10"]}!important`,
    },
  },
  rowSelected: {
    backgroundColor: `${LightColors.primary["10"]}!important`,
  },
  avatarDiv: {
    ...(theme.direction === "rtl"
      ? { marginLeft: theme.spacing(2) }
      : { marginRight: theme.spacing(2) }),
  },
  row: {
    display: "flex",
    alignItems: "center",
    "&:first-child": {
      marginBottom: 2,
    },
  },
  infoDiv: {
    display: "flex",
    alignItems: "center",
  },
  statusDiv: {
    display: "flex",
    alignItems: "center",
  },
  tdCheckBox: {
    minWidth: 30,
    width: 40,
  },
  tdName: {
    width: 142,
    whiteSpace: "nowrap",
  },
  tdPlan: {
    width: 86,
  },
  tdDataUsage: {
    width: 192,
  },
  tdNextBill: {
    width: 121,
  },
  tdStatus: {
    width: 133,
  },
  tdDetail: {
    width: 30,
  },

  dateTimeText: {
    whiteSpace: "nowrap",
  },
  borderNone: {
    "&:nth-child(2)": {
      width: 250,
    },
    border: "none",
    boxShadow: "none",
  },

  flex: {
    display: "flex",
    alignItems: "center",
  },
  upgradeBtn: {
    cursor: "pointer",
  },
  filterIcon: {
    fontSize: 18,
    marginLeft: 8,
  },
  btnStyle: {
    color: LightColors.primary["7"],
    borderColor: LightColors.primary["7"],
    "&:hover": {
      borderColor: LightColors.primary["7"],
    },
  },
  simIconStyle: {
    padding: 3,
    fontSize: 30,
    marginRight: 5,
  },
  bodyHeight: {
    flex: 1,
  },
}));

export type SimcardDetailEventHandler = (id: number) => void;
export type SimcardsEventHandler = (iccid: string) => void;
export interface SimcardOrderType {
  key: "iccid" | "planName" | "subscription_status";
  order: "asc" | "desc";
}

export interface ISimTableProps {
  onClick?: SimcardDetailEventHandler;
  onChangeOrder?: (sort?: SimcardOrderType) => void;
  currentOrder?: SimcardOrderType | undefined;
  value?: number;
  onUpgrade?: SimcardsEventHandler;
  onResubscribed?: SimcardsEventHandler;
  onEndResubscribed?: SimcardsEventHandler;
  simcards?: Simcard[];
  t: TFunction<string>;
  noResult?: boolean;
}

export const SimTable: React.FunctionComponent<ISimTableProps> = (props) => {
  const {
    t,
    simcards,
    onClick,
    noResult,
    currentOrder,
    onChangeOrder,
    onUpgrade,
    onResubscribed,
    onEndResubscribed,
    value,
  } = props;
  const classes = useStyles(props);

  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  // iccid 기준으로 정렬
  const handleIccidOrder = useCallback(() => {
    if (currentOrder?.key !== "iccid") {
      onChangeOrder?.({
        key: "iccid",
        order: "asc",
      });
    } else if (currentOrder?.order === "asc") {
      onChangeOrder?.({
        key: "iccid",
        order: "desc",
      });
    } else if (currentOrder?.order === "desc") {
      onChangeOrder?.(undefined);
    }
  }, [currentOrder, onChangeOrder]);

  //planName 기준으로 정렬
  const handlePlanNameOrder = useCallback(() => {
    if (currentOrder?.key !== "planName") {
      onChangeOrder?.({
        key: "planName",
        order: "asc",
      });
    } else if (currentOrder?.order === "asc") {
      onChangeOrder?.({
        key: "planName",
        order: "desc",
      });
    } else if (currentOrder?.order === "desc") {
      onChangeOrder?.(undefined);
    }
  }, [currentOrder, onChangeOrder]);

  //subscription status 기준으로 정렬
  const handleSubStatusOrder = useCallback(() => {
    if (currentOrder?.key !== "subscription_status") {
      onChangeOrder?.({
        key: "subscription_status",
        order: "asc",
      });
    } else if (currentOrder?.order === "asc") {
      onChangeOrder?.({
        key: "subscription_status",
        order: "desc",
      });
    } else if (currentOrder?.order === "desc") {
      onChangeOrder?.(undefined);
    }
  }, [currentOrder, onChangeOrder]);

  // 스크린에서 order 상태에따라 달라지는 value값을 받아와 아이콘 지정
  // iccic 필터 아이콘 모양
  const getIccidFilterIcon = useMemo(() => {
    if (value === 0 || (value !== 1 && value !== 2)) {
      return <FilterListIcon className={classes.filterIcon} />;
    } else if (value === 1) {
      return <ArrowUpwardIcon className={classes.filterIcon} />;
    } else if (value === 2) {
      return <ArrowDownwardIcon className={classes.filterIcon} />;
    }
  }, [classes.filterIcon, value]);

  // planName 필터 아이콘 모양
  const getPlanNameFilterIcon = useMemo(() => {
    if (value === 0 || (value !== 3 && value !== 4)) {
      return <FilterListIcon className={classes.filterIcon} />;
    } else if (value === 3) {
      return <ArrowUpwardIcon className={classes.filterIcon} />;
    } else if (value === 4) {
      return <ArrowDownwardIcon className={classes.filterIcon} />;
    }
  }, [classes.filterIcon, value]);

  // Subscription status 필터 아이콘 모양
  const getStatusFilterIcon = useMemo(() => {
    if (value === 0 || (value !== 5 && value !== 6)) {
      return <FilterListIcon className={classes.filterIcon} />;
    } else if (value === 5) {
      return <ArrowUpwardIcon className={classes.filterIcon} />;
    } else if (value === 6) {
      return <ArrowDownwardIcon className={classes.filterIcon} />;
    }
  }, [classes.filterIcon, value]);

  const iconMarkup = useMemo(() => {
    if (value !== 1 && value !== 2) {
      return (
        <IconButton color="secondary" onClick={handleIccidOrder}>
          {getIccidFilterIcon}
        </IconButton>
      );
    } else {
      return (
        <Tooltip
          disableTouchListener={mobile}
          title={
            <Typography category="Default" variant="Caption">
              {value === 1
                ? t("Sort ascending")
                : value === 2 && t("Sort descending")}
            </Typography>
          }
        >
          <IconButton color="secondary" onClick={handleIccidOrder}>
            {getIccidFilterIcon}
          </IconButton>
        </Tooltip>
      );
    }
  }, [getIccidFilterIcon, handleIccidOrder, mobile, t, value]);

  const StatusIconIconMarkup = useMemo(() => {
    return (
      <IconButton color="secondary" onClick={handleSubStatusOrder}>
        {getStatusFilterIcon}
      </IconButton>
    );
  }, [getStatusFilterIcon, handleSubStatusOrder]);

  const planNameIconMarkup = useMemo(() => {
    if (value !== 3 && value !== 4) {
      return (
        <IconButton color="secondary" onClick={handlePlanNameOrder}>
          {getPlanNameFilterIcon}
        </IconButton>
      );
    } else {
      return (
        <Tooltip
          disableTouchListener={mobile}
          title={
            <Typography category="Default" variant="Caption">
              {value === 3
                ? t("Sort ascending")
                : value === 4 && t("Sort descending")}
            </Typography>
          }
        >
          <IconButton color="secondary" onClick={handlePlanNameOrder}>
            {getPlanNameFilterIcon}
          </IconButton>
        </Tooltip>
      );
    }
  }, [getPlanNameFilterIcon, handlePlanNameOrder, mobile, t, value]);

  // 심 테이블 헤더
  const simHeaderMarkup = useMemo(() => {
    return (
      <>
        <TableHeaderCell style={{ width: noResult ? 240 : 180 }}>
          <div className={classes.flex}>
            {t("ICCID")}
            {iconMarkup}
          </div>
        </TableHeaderCell>
        <TableHeaderCell>{t("Name")}</TableHeaderCell>
        <TableHeaderCell>
          <div className={classes.flex}>
            {t("Plan")}
            {planNameIconMarkup}
          </div>
        </TableHeaderCell>
        <TableHeaderCell>{t("Data usage")}</TableHeaderCell>
        <TableHeaderCell>{t("Next billing on")}</TableHeaderCell>
        <TableHeaderCell>
          <div className={classes.flex} style={{ whiteSpace: "nowrap" }}>
            {t("Subscription status")}
            {StatusIconIconMarkup}
          </div>
        </TableHeaderCell>
        <TableHeaderCell></TableHeaderCell>
      </>
    );
  }, [
    noResult,
    classes.flex,
    t,
    iconMarkup,
    planNameIconMarkup,
    StatusIconIconMarkup,
  ]);

  const tableMarkup = useMemo(() => {
    return (
      <ScrollBar className={classes.bodyHeight}>
        <MuiTableContainer className={classes.simcardsTablewrap}>
          <MuiTable aria-label="simple table" className={classes.simcardsTable}>
            <MuiTableHead
              style={{
                boxShadow: `0px -1px 0px ${LightColors.primary["6"]}`,
              }}
            >
              <MuiTableRow
                style={{
                  boxShadow: `0px -1px 0px ${LightColors.primary["6"]}`,
                }}
              >
                {simHeaderMarkup}
              </MuiTableRow>
            </MuiTableHead>
            {!noResult && (
              <MuiTableBody>
                {_.map(simcards, (simcard, index) => {
                  // 심 terminated여부
                  const terminated = checkTerminated(simcard);
                  const paused = checkPaused(simcard);
                  const subscribed = checkSubscribed(simcard);
                  const canceled = checkCanceled(simcard);

                  let nextBillingText: JSX.Element | string = "";

                  if (terminated || (!subscribed && !canceled)) {
                    nextBillingText = "-";
                  } else if (canceled) {
                    nextBillingText = (
                      <Button
                        variant="outlined"
                        color="primary"
                        className={classes.btnStyle}
                        onClick={(e) => {
                          e.stopPropagation();
                          // 버튼이 cancelled일때 보이므로 조건문에 if(cancelled)는 무조건 참 / 구독중인지 아닌지를 체크함
                          if (!subscribed) {
                            onEndResubscribed?.(simcard.iccid);
                          } else {
                            onResubscribed?.(simcard.iccid);
                          }
                        }}
                      >
                        {t("Resubscribe")}
                      </Button>
                    );
                  } else if (subscribed && !canceled) {
                    if (simcard.blackvueOne) {
                      nextBillingText = (
                        <div>
                          {moment
                            .utc(simcard.subscription?.createdAt)
                            // .add(1, "days")
                            .format("MMM D, YYYY")}
                          ~
                          <br />
                          {moment
                            .utc(simcard.subscription?.periodEnd)
                            // .add(1, "days")
                            .format("MMM D, YYYY")}
                        </div>
                      );
                    } else {
                      nextBillingText = moment
                        .utc(simcard.subscription?.periodEnd)
                        // .add(1, "days")
                        .format("MMM D, YYYY");
                    }
                  }

                  console.log(
                    simcard,
                    "terminated",
                    terminated,
                    "paused",
                    paused,
                    "subscribed",
                    subscribed,
                    "canceled",
                    canceled,
                    terminated || (!subscribed && !canceled),
                    nextBillingText
                  );

                  let subscribeEndMarkup: JSX.Element | undefined = undefined;

                  // 심상태가 "Cancelled" 이면서 아직구독기간만료 전이면 구독종료일자  n days left!로 출력
                  if (
                    subscribed &&
                    canceled &&
                    moment().isBefore(simcard.subscription?.periodEnd)
                  ) {
                    const serviceEndDay =
                      Math.abs(
                        moment().diff(
                          moment(simcard.subscription?.periodEnd),
                          "days"
                        )
                      ) + 1;
                    subscribeEndMarkup = (
                      <div>
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={LightColors.secondary["11"]}
                        >
                          {serviceEndDay < 8
                            ? t("Days left_", {
                                a: serviceEndDay,
                              })
                            : `${t("Service end")} : ${moment(
                                simcard.subscription?.periodEnd
                              ).format("MMM D, YYYY")}`}
                        </Typography>
                      </div>
                    );
                  }

                  if (
                    !subscribed &&
                    paused &&
                    moment().isBefore(simcard.terminatedAt)
                  ) {
                    subscribeEndMarkup = (
                      <div>
                        <Typography
                          category="Default"
                          variant="Caption"
                          htmlColor={LightColors.secondary["11"]}
                        >
                          <Trans
                            t={t}
                            components={{
                              a: moment(simcard.terminatedAt).format(
                                "MMM D, YYYY"
                              ),
                            }}
                          >
                            Resubscribe until_
                          </Trans>
                        </Typography>
                      </div>
                    );
                  }

                  let statusText = "Subscribed";

                  if (simcard.blackvueOne) {
                    statusText = "One Package";
                  }

                  if (terminated) {
                    statusText = "Terminated";
                  } else if (canceled) {
                    statusText = "Cancelled";
                  }

                  return (
                    <MuiTableRow
                      hover
                      key={simcard.iccid}
                      classes={{
                        hover: classes.rowHover,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        onClick?.(simcard.id);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell>
                        <div className={classes.flex} style={{ maxWidth: 216 }}>
                          <SimCardIcon
                            className={classes.simIconStyle}
                            style={{
                              color: terminated
                                ? `${LightColors.primary["1"]}40`
                                : canceled
                                ? LightColors.secondary["11"]
                                : LightColors.secondary["15"],
                            }}
                          />
                          <div>
                            <Typography category="Default" variant="Body">
                              {simcard.iccid}
                            </Typography>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        className={classes.tdName}
                        // mantis - 8808, 띄어쓰기 출력안되는 이슈 수정 (Leehj)
                        style={{ whiteSpace: "pre" }}
                      >
                        {/* 1. 심카드 이름이 없으면 "–" 2. 10자넘으면 ...표시*/}
                        {simcard.name
                          ? `${simcard.name.slice(0, 10)}${
                              simcard.name.length > 10 ? `...` : ""
                            }`
                          : "–"}
                      </TableCell>
                      <TableCell className={classes.tdPlan}>
                        <div>
                          <div className={classes.row}>
                            {/* 상태가 Terminated이면 "–" 아니면 planName출력*/}
                            {!terminated && simcard.subscription ? (
                              <Typography
                                category="Default"
                                variant="BodyBold"
                                htmlColor={LightColors.primary["1"]}
                                className={classes.dateTimeText}
                              >
                                {simcard.subscription?.planName}
                              </Typography>
                            ) : (
                              "–"
                            )}
                          </div>
                          {/* planName이 5GB가 아니고 canceled/paused/terminated/one plane이 아니면 upgrade버튼 출력 */}
                          {!(
                            canceled ||
                            paused ||
                            terminated ||
                            simcard.blackvueOne
                          ) &&
                            simcard.subscription?.planName !== "5GB" && (
                              <div
                                className={clsx(
                                  classes.row,
                                  classes.upgradeBtn
                                )}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  onUpgrade?.(simcard.iccid);
                                }}
                              >
                                <Typography
                                  category="Default"
                                  variant="Caption"
                                  htmlColor={LightColors.primary["7"]}
                                >
                                  {t("Upgrades")}
                                </Typography>
                              </div>
                            )}
                        </div>
                      </TableCell>
                      <TableCell className={classes.tdDataUsage}>
                        <DataUsage
                          t={t}
                          //Terminated 상태이거나 planName이 undefined인 경우 0
                          plan={
                            terminated ||
                            simcard.subscription?.planName === null
                              ? undefined
                              : simcard.subscription?.planName
                          }
                          used={
                            terminated ||
                            simcard.subscription?.planName === null
                              ? 0
                              : simcard.trigger_usage?.usage ?? 0
                          }
                          chartWidth={8}
                        />
                      </TableCell>
                      <TableCell className={classes.tdNextBill}>
                        {/* 심상태가 "Cancelled" 이면서 아직구독기간만료 전이면 구독종료일자 만약 7일 전이면n days left!로 출력*/}
                        {nextBillingText}
                      </TableCell>
                      <TableCell className={classes.tdStatus}>
                        <Typography
                          category="Default"
                          variant="Body"
                          htmlColor={
                            terminated
                              ? LightColors.secondary["11"]
                              : LightColors.primary["1"]
                          }
                        >
                          {t(statusText)}
                        </Typography>

                        {subscribeEndMarkup}
                      </TableCell>
                      <TableCell className={classes.tdDetail}>
                        <IconButton
                          className={classes.moreBtn}
                          color="secondary"
                        >
                          <ChevronRightIcon />
                        </IconButton>
                      </TableCell>
                    </MuiTableRow>
                  );
                })}
              </MuiTableBody>
            )}
          </MuiTable>
        </MuiTableContainer>
      </ScrollBar>
    );
  }, [
    classes.bodyHeight,
    classes.simcardsTablewrap,
    classes.simcardsTable,
    classes.rowHover,
    classes.flex,
    classes.simIconStyle,
    classes.tdName,
    classes.tdPlan,
    classes.row,
    classes.dateTimeText,
    classes.upgradeBtn,
    classes.tdDataUsage,
    classes.tdNextBill,
    classes.tdStatus,
    classes.tdDetail,
    classes.moreBtn,
    classes.btnStyle,
    simHeaderMarkup,
    noResult,
    simcards,
    t,
    onEndResubscribed,
    onResubscribed,
    onUpgrade,
    onClick,
  ]);

  return tableMarkup;
};
