import React, { useEffect, useMemo, useState } from "react";
import { LightColors, Typography } from "@thingsw/pitta-design-system";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Hidden,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  useMediaQuery,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { MinimalFooter } from "../components/MinimalFooter";
import { MainSimHeader } from "../components/MainSimHeader";
import { NavigationDrawer } from "../components/NavigationDrawer";
import { ScrollBar } from "../components/ScrollBar";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#EEEEF3",
    transition: theme.transitions.create("margin"),
    paddingTop: 56,

    [theme.breakpoints.up("md")]: {
      minHeight: "calc(var(--vh, 1vh) * 100)",
      // maxHeight: "calc(var(--vh, 1vh) * 100 - 56px)",
    },
  },
  whiteBackRoot: {
    backgroundColor: LightColors.primary["0"],
  },
  withStepper: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "calc(var(--vh, 1vh) * 100 )",
      // maxHeight: "calc(var(--vh, 1vh) * 100 - 56px)",
    },
  },
  stepperDiv: {
    display: "flex",
    padding: theme.spacing(0, 2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      justifyContent: "center",
      alignItems: "center",
      padding: 0,
      marginTop: 0,
    },
  },
  footer: {
    padding: theme.spacing(2.625, 0),
    margin: 0,
    bottom: 0,
    [theme.breakpoints.up("sm")]: {
      padding: theme.spacing(2.625, 4),
    },
  },
}));

//stepper
const Steps = ["SIM information", "Subscription plan", "Billing information"];

const useFailedStepperConnectStyles = makeStyles((theme: Theme) => ({
  alternativeLabel: {
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
    top: 14,
  },
  active: {
    "& $line": {
      borderColor: `${LightColors.primary["5"]}`,
    },
  },
  completed: {
    "& $line": {
      borderColor: `${LightColors.primary["7"]}`,
    },
  },
  line: {
    borderColor: `${LightColors.primary["5"]}`,
    borderLeftWidth: 2,
    [theme.breakpoints.up("sm")]: {
      borderTopWidth: 2,
    },
  },
  vertical: {
    padding: 0,
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(1.125) }
      : { marginLeft: theme.spacing(1.125) }),
  },
  lineVertical: {
    minHeight: 11,
  },
}));

const useStepperConnectStyles = makeStyles((theme: Theme) => ({
  alternativeLabel: {
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
    top: 14,
  },
  active: {
    "& $line": {
      borderColor: `${LightColors.primary["7"]}`,
    },
  },
  completed: {
    "& $line": {
      borderColor: `${LightColors.primary["7"]}`,
    },
  },
  line: {
    borderColor: `${LightColors.primary["5"]}`,
    borderLeftWidth: 2,
    [theme.breakpoints.up("sm")]: {
      borderTopWidth: 2,
    },
  },
  vertical: {
    padding: 0,
    ...(theme.direction === "rtl"
      ? { marginRight: theme.spacing(1.125) }
      : { marginLeft: theme.spacing(1.125) }),
  },
  lineVertical: {
    minHeight: 11,
  },
}));

const useStepperStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 0,
    [theme.breakpoints.up("sm")]: {
      width: 502,
      marginTop: theme.spacing(6) + 1,
    },
  },
}));

const useStepIconStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: 20,
    color: `${LightColors.primary["5"]}`,
    [theme.breakpoints.up("sm")]: {
      fontSize: 30,
    },
  },
  active: {
    color: `${LightColors.primary["7"]}`,
  },
  completed: {
    color: `${LightColors.primary["7"]}`,
  },
}));
const usestepLabelStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
    },
  },
  label: {
    color: `${LightColors.primary["3"]}`,
    "&$active": {
      color: `${LightColors.primary["7"]}`,
    },
    "&$completed": {
      color: `${LightColors.primary["7"]}`,
    },
    "&$alternativeLabel": {
      marginTop: theme.spacing(1),
    },
  },
  //active, completed, alternativeLabel css변경에 필요
  active: {},
  completed: {},
  alternativeLabel: {},
}));
const usestepFailedLabelStyles = makeStyles((theme: Theme) => ({
  root: {
    [theme.breakpoints.up("sm")]: {
      flexDirection: "column",
    },
  },
  label: {
    color: `${LightColors.primary["3"]}`,
    "&$active": {
      color: `${LightColors.primary["3"]}`,
    },
    "&$completed": {
      color: `${LightColors.primary["3"]}`,
    },
    "&$alternativeLabel": {
      marginTop: theme.spacing(1),
    },
  },
  //active, completed, alternativeLabel css변경에 필요
  active: {},
  completed: {},
  alternativeLabel: {},
}));

const withSimTemplate = (WrappedComponent: React.FC<any>) => (props: any) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const stepperClasses = useStepperStyles();
  const stepperConnectClasses = useStepperConnectStyles();
  const failedStepperConnectClasses = useFailedStepperConnectStyles();
  const stepLabelClasses = usestepLabelStyles();
  const stepFailedLabelClasses = usestepFailedLabelStyles();
  const stepIconClasses = useStepIconStyles();

  const mobile = useMediaQuery(theme.breakpoints.down("xs"));
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [failed, setFailed] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [value, setValue] = useState<number>(0);

  useEffect(() => {
    setFailed(false);
    if (location.pathname.indexOf("/subscription") > -1) {
      setActiveStep(1);
      document.getElementById("scrollTop")?.scrollIntoView(true);
    } else if (location.pathname.indexOf("/billing") > -1) {
      setActiveStep(2);
      document.getElementById("scrollTop")?.scrollIntoView(true);
    } else if (location.pathname.indexOf("/subscribed") > -1) {
      setActiveStep(3);
      document.getElementById("scrollTop")?.scrollIntoView(true);
    } else if (location.pathname.indexOf("/failed") > -1) {
      setActiveStep(2);
      setFailed(true);
      document.getElementById("scrollTop")?.scrollIntoView(true);
    } else {
      setActiveStep(0);
      document.getElementById("scrollTop")?.scrollIntoView(true);
    }
  }, [location.pathname]);

  // path에 따라 value값 변경하여 해당탭 활성화 표시
  useEffect(() => {
    if (location.pathname.indexOf("/pricing") > -1) {
      setValue(1);
    } else {
      setValue(0);
    }
  }, [location.pathname]);

  // path값으로 stepper와 헤더 탭 유무 결정
  const addSim = useMemo(
    () =>
      _.includes(
        ["/add-sim", "/subscription", "/billing", "/subscribed", "/failed"],
        location.pathname
      ),
    [location.pathname]
  );

  const pricingPath = useMemo(
    () => _.includes(["/pricing"], location.pathname),
    [location.pathname]
  );

  return (
    <div style={{ backgroundColor: LightColors.primary["0"] }} id="scrollTop">
      <ScrollBar
        className={clsx(classes.root, {
          [classes.withStepper]: addSim,
          [classes.whiteBackRoot]: addSim || pricingPath,
        })}
      >
        <MainSimHeader
          tabMenu={!addSim}
          value={value}
          onClickMenu={() => setOpenDrawer((open) => !open)}
        />
        {addSim && (
          <div className={classes.stepperDiv}>
            <Stepper
              connector={
                <StepConnector
                  classes={
                    failed ? failedStepperConnectClasses : stepperConnectClasses
                  }
                />
              }
              classes={stepperClasses}
              activeStep={activeStep}
              orientation={mobile ? "vertical" : "horizontal"}
              alternativeLabel={!mobile}
            >
              {_.map(Steps, (step, i) => {
                if (i === 2 && failed) {
                  return (
                    <Step>
                      <StepLabel
                        classes={stepFailedLabelClasses}
                        StepIconProps={{
                          classes: stepIconClasses,
                        }}
                        icon={
                          <svg
                            width="30"
                            height="30"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="16" cy="16" r="16" fill="#D81A26" />
                            <path
                              d="M21.2381 11.7053L17.0545 15.8889L21.2381 20.0725L20.1834 21.1272L15.9998 16.9436L11.8162 21.1272L10.7615 20.0725L14.9451 15.8889L10.7615 11.7053L11.8162 10.6506L15.9998 14.8342L20.1834 10.6506L21.2381 11.7053Z"
                              fill="white"
                            />
                          </svg>
                        }
                      >
                        <Typography
                          category="Default"
                          variant={mobile ? "Small" : "Caption"}
                        >
                          {`${t(step)}`}
                        </Typography>
                      </StepLabel>
                    </Step>
                  );
                }
                return (
                  <Step>
                    <StepLabel
                      classes={stepLabelClasses}
                      StepIconProps={{
                        classes: stepIconClasses,
                      }}
                    >
                      <Typography
                        category="Default"
                        variant={mobile ? "Small" : "Caption"}
                      >
                        {`${t(step)}`}
                      </Typography>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </div>
        )}
        <WrappedComponent {...props} />
        <Hidden mdUp>
          <NavigationDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
          />
        </Hidden>
        <MinimalFooter
          grayColor={!addSim && !pricingPath}
          bodyClassName={classes.footer}
        />
      </ScrollBar>
    </div>
  );
};

export default withSimTemplate;
