import React, { useEffect, useMemo } from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { LightColors, Typography } from "@thingsw/pitta-design-system";
import { Trans, useTranslation } from "react-i18next";

import { Doughnut } from "react-chartjs-2";
import _ from "lodash";
import moment from "moment";
import { CSTeamEmail } from "../contants/Links";

export interface DataProps {
  planName?: string;
  dataUsage?: number;
  estimatedDate?: moment.Moment;
  terminated?: boolean;
  cancelled?: boolean;
  paused?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  centering: {
    display: "flex",
    justifyContent: "center",
    position: "relative",
    marginBottom: 20,
    [theme.breakpoints.up("sm")]: {
      marginRight: 50,
      marginBottom: 0,
    },

    [theme.breakpoints.up("md")]: {
      marginRight: 2,
      marginBottom: 0,
    },
  },

  drivingChartDiv: {
    maxWidth: 322,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  drivingChartTextDiv: {
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    top: "calc(50% + 3px)",
    transform: "translateY(-50%)",
  },

  graphDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: `1px solid ${LightColors.primary["5"]}`,
    borderRadius: 4,
    flex: 1,
    backgroundColor: "#fff",

    padding: "24px",
    [theme.breakpoints.up("sm")]: {
      maxHeight: 296,
      padding: "24px 24px 24px 31px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "24px 32px",
    },
  },

  titleGroup: {
    display: "flex",
    justifyContent: "space-between",
    // marginBottom: 10,
    marginBottom: 32,
    [theme.breakpoints.up("sm")]: {
      // paddingLeft: 16,
      marginBottom: 10,
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: 0,
      marginBottom: 10,
    },
  },

  contentGroup: {
    display: "revert",
    marginBottom: 20,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "center",
      marginRight: 8,
      marginBottom: 6,
    },
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "space-around",
      marginBottom: 6,
    },
    // [theme.breakpoints.up("sm")]: {
    //   marginRight: 0,
    //   marginBottom: 5,
    // },
  },

  line: {
    border: `1px solid ${LightColors.primary["6"]}`,
    margin: "21px 0 16px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 217,
    },
    [theme.breakpoints.up("md")]: {
      margin: "21px 0 14px",
    },
  },

  chartSize: {
    width: 218,
    [theme.breakpoints.up("sm")]: {
      width: 182,
    },
  },
  csTeamEmail: {
    textDecoration: "none",
    color: LightColors.primary["7"],
  },
  terminatedTextDiv: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
}));
const AVAILABLE_COLOR = "rgba(138, 63, 252, 1)";
const USED_COLOR = "rgba(126, 126, 131, 1)";

export const DataUsage = (props: DataProps) => {
  const { planName, dataUsage, estimatedDate, terminated, cancelled, paused } =
    props;
  const classes = useStyles();
  const { t } = useTranslation();
  const today = moment();
  const startDay = moment(today).startOf("month");
  const endDay = moment(today).endOf("month");

  const planData = useMemo(() => {
    if (cancelled) {
      return 0;
    } else if (planName === "1GB") {
      return 1024;
    } else if (planName === "3GB") {
      return 3072;
    } else if (planName === "5GB") {
      return 5120;
    } else {
      return 0;
    }
  }, [cancelled, planName]);

  const usedData = useMemo(() => {
    if (dataUsage !== undefined) {
      return dataUsage;
    } else {
      return 0;
    }
  }, [dataUsage]);

  const getLeftDate = useMemo(() => {
    return Math.abs(today.diff(endDay, "days")) + 1;
  }, [endDay, today]);

  const estimation = useMemo(() => {
    if (estimatedDate !== undefined) {
      return moment(estimatedDate).format("h:mm:ss a");
    } else {
      return moment().format("h:mm a");
    }
  }, [estimatedDate]);

  const empty = useMemo(() => {
    return planData === 0;
  }, [planData]);

  const availableData = useMemo(() => {
    return Math.max(planData - usedData, 0);
  }, [planData, usedData]);
  const dataPer = useMemo(() => {
    return Math.min(100 - ((planData - usedData) / planData) * 100, 100);
  }, [planData, usedData]);

  useEffect(() => {
    return () => {
      var tooltipEl = document.getElementById("chartjs-tooltip");
      if (tooltipEl) {
        tooltipEl.remove();
      }
    };
  }, []);

  const DataChart = useMemo(() => {
    return (
      <div>
        <div
          className={classes.chartSize}
          style={{ display: empty ? "none" : "flex" }}
        >
          <Doughnut
            data={{
              labels: empty ? [] : [t("Used"), t("Available")],
              datasets: [
                {
                  data: empty ? [100] : [usedData, availableData],
                  backgroundColor: empty
                    ? [LightColors.primary["6"]]
                    : [USED_COLOR, AVAILABLE_COLOR],
                },
              ],
            }}
            options={{
              // cutout 쓰니까 날씬해 짐
              cutout: "67.4%",
              responsive: true,
              plugins: {
                // plugins-legend-display: false 쓰니까 라벨 사라짐
                legend: {
                  display: false,
                },
                // ToolTip //
                tooltip: {
                  // Disable the on-canvas tooltip
                  enabled: false,
                  external: (context: any) => {
                    // Tooltip Element
                    var tooltipEl = document.getElementById("chartjs-tooltip");

                    // Create element on first render
                    if (!tooltipEl) {
                      tooltipEl = document.createElement("div");
                      tooltipEl.id = "chartjs-tooltip";
                      document.body.appendChild(tooltipEl);
                    }

                    // Hide if no tooltip
                    var tooltipModel = context.tooltip;
                    if (tooltipModel.opacity === 0) {
                      tooltipEl.style.opacity = "0";
                      return;
                    }

                    function getBody(bodyItem: any) {
                      return bodyItem.lines;
                    }

                    // Set Text
                    if (tooltipModel.body) {
                      var bodyLines = _.flattenDeep(
                        tooltipModel.body.map(getBody)
                      );

                      const bodies = _.map(
                        bodyLines,
                        (body: string, i: number) => {
                          var colors = tooltipModel.labelColors[i];
                          var style = `background-color:${colors.backgroundColor};width:8px;height:8px;border: 1px solid ${colors.borderColor};border-radius: 50%;margin-right: 4px;`;
                          var span = '<div style="' + style + '"></div>';
                          return `<div style="display:flex;align-items:center;">${span}${body}MB</div>`;
                        }
                      );

                      tooltipEl.innerHTML = `<div>${_.join(bodies, "")}</div>`;
                    }

                    var position = context.chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = "1";
                    tooltipEl.style.backgroundColor = "rgba(19, 19, 28, 1)";
                    tooltipEl.style.color = "#fff";
                    tooltipEl.style.position = "absolute";
                    tooltipEl.style.left =
                      position.left +
                      window.pageXOffset +
                      tooltipModel.caretX +
                      "px";
                    tooltipEl.style.top =
                      position.top +
                      window.pageYOffset +
                      tooltipModel.caretY / 2 +
                      "px";
                    tooltipEl.style.padding = "4px 8px";
                    tooltipEl.style.borderRadius = "4px";
                    tooltipEl.style.boxShadow =
                      "0px 0px 1px rgba(0, 0, 0, 0.14), 0px 1px 1px rgba(0, 0, 0, 0.12), 0px 0px 3px rgba(0, 0, 0, 0.2)";
                    tooltipEl.style.pointerEvents = "none";
                    tooltipEl.style.fontFamily = "Roboto";
                    tooltipEl.style.fontSize = "12px";
                    tooltipEl.style.lineHeight = "16px";
                  },
                },
                // TooTip 여기까지
              },
            }}
          />
        </div>
        <div
          style={{
            width: 180,
            height: 180,
            display: empty ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: empty ? `${[LightColors.primary["6"]]}` : "",
            borderRadius: 181,
          }}
        >
          <Typography
            category="Default"
            variant="BodyBold"
            htmlColor={LightColors.primary["2"]}
          >
            {t("No data")}
          </Typography>
        </div>
      </div>
    );
  }, [availableData, classes.chartSize, empty, t, usedData]);

  const dataUsageMarkup = useMemo(() => {
    if (terminated) {
      return (
        <div className={classes.terminatedTextDiv}>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
            style={{ marginBottom: 32 }}
          >
            {t("This sim card is_")}
          </Typography>
          <Typography
            category="Default"
            variant="Body"
            htmlColor={LightColors.primary["2"]}
          >
            <Trans
              t={t}
              components={{
                a: (
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={`mailto:${CSTeamEmail}`}
                    className={classes.csTeamEmail}
                    target="_blank"
                    rel="noreferrer"
                  />
                ),
              }}
            >
              To use SIM card_
            </Trans>
          </Typography>
        </div>
      );
    }
    return (
      <>
        <div className={classes.contentGroup}>
          <div className={classes.centering}>
            <div className={classes.drivingChartDiv}>{DataChart}</div>
            <div
              style={{ display: empty ? "none" : "flex" }}
              className={classes.drivingChartTextDiv}
            >
              <Typography category="Large" variant="H3">
                {t(`${dataPer.toFixed(0)}%`)}
              </Typography>
              <Typography
                category="Default"
                variant="Caption"
                htmlColor={LightColors.primary["2"]}
              >
                {t("Data used")}
              </Typography>
            </div>
          </div>
          {paused ? (
            <div style={{ alignSelf: "center", marginTop: 4 }}>
              <Typography
                variant="Body"
                category="Default"
                htmlColor={LightColors.primary["3"]}
              >
                {t("Cancelled")}
              </Typography>
            </div>
          ) : (
            <div style={{ alignSelf: "center", marginTop: 4 }}>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignSelf: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 16,
                        height: 16,
                        backgroundColor: `${LightColors.primary["3"]}`,
                        borderRadius: 181,
                        paddingTop: 1,
                        marginRight: 7,
                      }}
                    />
                    <div>
                      <Typography category="Default" variant="Small">
                        {t("Used")}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Typography
                      category="Default"
                      variant="H4"
                      htmlColor={LightColors.primary["3"]}
                    >
                      {t(`${_.round(usedData, 0)} MB`)}
                    </Typography>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: 12,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        width: 16,
                        height: 16,
                        backgroundColor: "#8A3FFC",
                        borderRadius: 181,
                        paddingTop: 1,
                        marginRight: 7,
                      }}
                    />
                    <div>
                      <Typography category="Default" variant="Small">
                        {t("Available")}
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <Typography
                      category="Default"
                      variant="H4"
                      htmlColor="#8A3FFC"
                    >
                      {t(`${_.round(availableData, 0)} MB`)}
                    </Typography>
                  </div>
                </div>
              </div>
              <div className={classes.line} />
              <div style={{ textAlign: "center" }}>
                <Typography
                  category="Default"
                  variant="H5"
                  htmlColor={empty ? LightColors.primary["2"] : ""}
                  dangerouslySetInnerHTML={{
                    __html: t(empty ? "No Data" : "Days left_", {
                      a: `${getLeftDate}`,
                    }),
                  }}
                ></Typography>
              </div>
            </div>
          )}
        </div>
        <div style={{ alignSelf: "end" }}>
          <Typography
            category="Default"
            variant="Caption"
            htmlColor={LightColors.primary["3"]}
            dangerouslySetInnerHTML={{
              __html: t("Usage is estimated_", {
                a: `${estimation}`,
              }),
            }}
          ></Typography>
        </div>
      </>
    );
  }, [
    DataChart,
    availableData,
    classes.centering,
    classes.contentGroup,
    classes.csTeamEmail,
    classes.drivingChartDiv,
    classes.drivingChartTextDiv,
    classes.line,
    classes.terminatedTextDiv,
    dataPer,
    empty,
    estimation,
    getLeftDate,
    paused,
    t,
    terminated,
    usedData,
  ]);

  return (
    <div className={classes.graphDiv}>
      <div className={classes.titleGroup}>
        <div>
          <Typography category="Default" variant="H6">
            {t("Data usage")}
          </Typography>
        </div>
        {!terminated && (
          <div style={{ display: "grid", justifyItems: "flex-end" }}>
            <Typography
              category="Default"
              variant="CaptionBold"
              htmlColor={LightColors.primary["3"]}
            >
              {t("Data usage cycle")}
            </Typography>
            <Typography
              category="Default"
              variant="Caption"
              htmlColor={LightColors.primary["3"]}
            >
              {`${startDay.format("MMM D, YYYY")} ~ ${endDay.format(
                "MMM D, YYYY"
              )}`}
            </Typography>
          </div>
        )}
      </div>
      {dataUsageMarkup}
    </div>
  );
};
