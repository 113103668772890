import React, { useCallback } from "react";
import { Button, LightColors, Typography } from "@thingsw/pitta-design-system";

import { makeStyles, Theme, useMediaQuery, useTheme } from "@material-ui/core";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: LightColors.primary["0"],
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1.25, 2, 1),
    flex: 1,
    minHeight: "calc(var(--vh, 1vh) * 100 - 58px)",
    [theme.breakpoints.up("sm")]: {
      alignItems: "center",
      padding: theme.spacing(2, 2, 1.875, 2),
      minHeight: "calc(var(--vh, 1vh) * 100 - 107px - 56px - 76px )",
    },
  },
  body: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 14,
    [theme.breakpoints.up("sm")]: {
      marginTop: 54,
      padding: 0,
    },
  },

  content: {
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      marginBottom: theme.spacing(4),
      width: 502,
      textAlign: "revert",
      margin: 0,
    },
  },

  btnGroup: {
    display: "grid",
    width: "100%",
    marginTop: 32,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      marginTop: 0,
    },
  },

  okBtn: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
      marginBottom: 0,
    },
  },
  divider: {
    width: "90%",
  },
}));

const SubscriptionFailedScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme() as Theme;
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleTryAgain = useCallback(() => {
    history.replace("/");
  }, [history]);

  return (
    <div className={classes.root}>
      <div className={classes.body}>
        <Typography
          category="Default"
          variant={mobile ? "H2" : "H1"}
          style={{ whiteSpace: "nowrap", marginBottom: 32 }}
        >
          {t("Subscription failed")}
        </Typography>

        <Typography
          category="Default"
          variant="Body"
          className={classes.content}
        >
          {t("An error occurred_sim")}
        </Typography>
        <Typography
          category="Default"
          variant="Body"
          className={classes.content}
        >
          {t("Click the ADD SIM")}
        </Typography>
        <div>
          <div className={classes.btnGroup}>
            <Button
              className={classes.okBtn}
              variant="contained"
              color="primary"
              onClick={handleTryAgain}
            >
              {t("Try again")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionFailedScreen;
